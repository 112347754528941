const cacheKey = 'compareProducts';

/** 
const setInLocalStorage = productIds => {
  if (window && window.localStorage) {
    window.localStorage.setItem(cacheKey, JSON.stringify(productIds));
  }
};

const removeFromLocalStorage = productIds => {
  if (window && window.localStorage) {
    window.localStorage.removeItem(cacheKey, JSON.stringify(productIds));
  }
};

export function getProductIds() {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(cacheKey) || '[]');
  }
}

export const toggleCompareProduct = productId => {
  const productIds = getProductIds();

  if (!productIds.includes(productId)) {
    if (productIds.length < 4) {
      addProduct(productId);
    } else {
      alert('Only 4 listings are allowed to compare');
    }
  } else {
    removeProduct(productId);
  }
};

export function addProduct(productId) {
  const productIds = getProductIds();
  if (!productIds.includes(productId)) {
    productIds.push(productId);
    setInLocalStorage(productIds);
    return true;
  }
  return false;
}

export function removeProduct(productId) {
  let productIds = getProductIds();
  if (productIds.includes(productId)) {
    productIds = productIds.filter(a => a !== productId);
    setInLocalStorage(productIds);
    return true;
  }
  return false;
}

export function removeAllProducts() {
  setInLocalStorage([]);
  removeFromLocalStorage([]);
}
*/

const getFavoriteCompareProductIds = () => {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(cacheKey) || '{}');
  }
};

export const toggleFavoriteCompareProduct = (eventR, idR) => {
  const favCompareProdIds = getFavoriteCompareProductIds();
  const { name, items } = favCompareProdIds;
  // if event is not identified as same as previous
  // remove privious data and set new event and selected product id.
  if (name !== eventR) {
    setFavCompareToLS({ name: eventR, items: [idR] });
  } else {
    const indexOfId = items.indexOf(idR);
    let tempArr = [...items];
    // if event is same as parameter,
    // check if the given id is present in the items list or not.
    if (indexOfId !== -1) {
      // if present in the list, remove it (toggle it)
      tempArr.splice(indexOfId, 1);
    } else {
      // else we have to add it to list,
      // but before that, check the limit, if limit is exceeded, alert the user.
      // and return
      if (tempArr.length >= 4) {
        alert('Only 4 listings are allowed to compare');
        return;
      }
      // if it is in limited list,
      // add this id in list
      tempArr = [...tempArr, idR];
    }
    // and finally,
    // push the data to localstorage
    setFavCompareToLS({ name, items: tempArr });
    return true;
  }
};

export const getOnlyListingIds = () => {
  const listingObj = getFavoriteCompareProductIds();
  return listingObj.items ?? [];
};

const setFavCompareToLS = data => {
  if (window && window.localStorage) {
    window.localStorage.setItem(cacheKey, JSON.stringify(data));
  }
};

export function removeFavCompareFromLS() {
  setFavCompareToLS([]);
}
export const getSelectedEventFromLS = () => {
  const listingObj = getFavoriteCompareProductIds();
  return listingObj.name;
};

export const isListingEmpty = () => {
  const listingObj = getFavoriteCompareProductIds();
  if (listingObj.items && listingObj.items.length !== 0) {
    return false;
  } else {
    return true;
  }
};
