import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingDetailsForm } from '../../forms';
import { ListingLink } from '..';
import config from '../../config';

import css from './EditListingDetailsPanel.module.css';

const DETAILS_NAME = 'details';

const EditListingDetailsPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onImageUpload,
    onRemoveImage,
    images,
    onUpdateImageOrder,
    errors,
    intl,
    updateDetailsTabPublicData,
    detailsTabData,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  useEffect(() => {
    const sqFt = publicData && publicData.sqFt ? publicData.sqFt : null;
    const maximumcapacity =
      publicData && publicData.maximumcapacity ? publicData.maximumcapacity : null;
    const minimumcapacity =
      publicData && publicData.minimumcapacity ? publicData.minimumcapacity : null;
    const events = publicData && publicData.events;
    const walkthroughYT = publicData && publicData.walkthroughYT;
    const minhours = publicData && publicData.minhours;
    const maxhours = publicData && publicData.maxhours;
    const additionalTags = publicData && publicData.additionalTags;
    updateDetailsTabPublicData({
      sqFt,
      maximumcapacity,
      minimumcapacity,
      events,
      walkthroughYT: walkthroughYT ? walkthroughYT : [{ ytURl: '' }],
      minhours,
      maxhours,
      additionalTags: additionalTags || [],
    });
  }, [publicData]);

  const sqFt = detailsTabData && detailsTabData.sqFt ? detailsTabData.sqFt : null;
  const maximumcapacity =
    detailsTabData && detailsTabData.maximumcapacity ? detailsTabData.maximumcapacity : null;
  const minimumcapacity =
    detailsTabData && detailsTabData.minimumcapacity ? detailsTabData.minimumcapacity : null;
  const eventStyles = detailsTabData && detailsTabData.events;
  const walkthroughYT = detailsTabData && detailsTabData.walkthroughYT;
  const minhours = detailsTabData && detailsTabData.minhours;
  const maxhours = detailsTabData && detailsTabData.maxhours;
  const additionalTags = detailsTabData && detailsTabData.additionalTags;

  const HoursOptions = findOptionsForSelectFilter('hoursOptions', config.custom.filters);
  const MaxHoursOptions = findOptionsForSelectFilter('maxHoursOptions', config.custom.filters);

  let imgIdsArray = images
    ? images.map((val, i) => {
        let imgId = val.imageId ? val.imageId.uuid : val.id.uuid;
        return imgId;
      })
    : [];
  const photoImages = [];
  const { floorImagesData = [] } = currentListing.attributes.publicData;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const currentId = currentListing?.id?.uuid;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDetailsPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDetailsPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDetailsPanel.createListingTitle" />
  );

  const getFloorImages = () => {
    let duplicateImages = [];
    if (images) {
      for (let i = 0; i < images.length; i++) {
        let imgId = images[i].id.uuid;
        if (
          (floorImagesData &&
            floorImagesData.length > 0 &&
            floorImagesData.indexOf(imgId) !== -1 &&
            imgIdsArray.indexOf(imgId) !== -1) ||
          (images[i].id &&
            typeof images[i].id === 'string' &&
            images[i].id.startsWith('FLOOR_IMG_'))
        ) {
          duplicateImages.push(images[i]);
        } else {
          photoImages.push(images[i]);
        }
      }
    }

    return duplicateImages;
  };
  // const detailsData = publicData && publicData.details;
  const filteredFloorImages = getFloorImages();
  const getSessionValues = sessionStorage.getItem('details');
  let convertJsontoParse = JSON.parse(getSessionValues);
  convertJsontoParse = convertJsontoParse?.id == currentId ? convertJsontoParse : 'null';

  // console.log(convertJsontoParse)
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDetailsForm
        className={css.form}
        name={DETAILS_NAME}
        images={filteredFloorImages}
        onRemoveImage={onRemoveImage}
        currentId={currentId}
        initialValues={{
          // details: (convertJsontoParse  && convertJsontoParse.detials) ? convertJsontoParse.detials : detailsData,
          events: eventStyles,
          sqFt: sqFt,
          walkthroughYT: walkthroughYT,
          minhours: minhours,
          maxhours: maxhours,
          floorImages: filteredFloorImages,
          maximumcapacity: maximumcapacity,
          minimumcapacity: minimumcapacity,
          additionalTags: additionalTags || [],
        }}
        onSubmit={values => {
          const {
            events,
            sqFt,
            walkthroughYT,
            minhours,
            maxhours,
            floorImages,
            maximumcapacity,
            minimumcapacity,
            additionalTags,
          } = values;

          let tagKeyword = additionalTags ? additionalTags.join(",") : "";
          
          let floorImgIdsArray =
            filteredFloorImages && filteredFloorImages.length > 0
              ? filteredFloorImages.map((val, i) => {
                  let imgId = val.imageId ? val.imageId.uuid : val.id.uuid;
                  return imgId;
                })
              : [];
          const updatedValues = {
            images: [...photoImages, ...filteredFloorImages],
            publicData: {
              events,
              sqFt,
              walkthroughYT,
              minhours,
              maxhours,
              floorImagesData: floorImgIdsArray,
              maximumcapacity: Number(maximumcapacity),
              minimumcapacity: Number(minimumcapacity),
              additionalTags,
              tagKeyword
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        onUpdateImageOrder={onUpdateImageOrder}
        fetchErrors={errors}
        onImageUpload={onImageUpload}
        HoursOptions={HoursOptions}
        MaxHoursOptions={MaxHoursOptions}
        updateDetailsTabPublicData={updateDetailsTabPublicData}
        detailsTabData={detailsTabData}
      />
    </div>
  );
};

EditListingDetailsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingDetailsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  onImageUpload: func.isRequired,
};

export default EditListingDetailsPanel;
