import React, { useRef, useState } from 'react';
import { array, arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, FormattedMessage, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { FieldTextInput } from '../../components';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';
import { useHistory } from 'react-router-dom';
import { GrTooltip } from 'react-icons/gr';
import {
  maxLength,
  maxLengthNumber,
  required,
  checkNegativeValue,
  composeValidators,
  checkMaxIsGreatThenMin,
  checkMaxHourIsGreatThenMinHOur,
  nonEmptyArray,
  checkMinIsLessThenMax,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
} from '../../util/validators';
import EditListingFloorPlanForm from './EditListingFloorPlanForm';
import EditListingVirtualWalkThrough from './EditListingVirutalWalkThrough';
import CheckBoxFieldDescription from './CheckBoxFieldDescription';
import HoursAvailable from './HoursAvailable';
import TagsInput from 'react-tagsinput';
import css from './EditListingDetailsForm.module.css';
import { values } from 'lodash';
const SQFT_MAX_LENGTH = 10;

const EditListingDetailsForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      // validate={values => {
      //   const errors = {};
      //   if (!(values.maximumcapacity > values.minimumcapacity)) {
      //     console.log(values, 'Validates', !(values.maximumcapacity > values.minimumcapacity));
      //     errors.maximumcapacity = 'Value must be greater then minumum';
      //   }
      // }}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          HoursOptions,
          MaxHoursOptions,
          className,
          name,
          handleSubmit,
          form,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          filterConfig,
          invalid,
          currentId,
          images,
          onImageUpload,
          onRemoveImage,
          onUpdateImageOrder,
          updateDetailsTabPublicData,
          detailsTabData,
          intl,
          values,
          onChange,
        } = formRenderProps;

        const {
          mutators: { push, pop },
        } = form;

        const [maxCapcityFlag, setMaxCapcityFlag] = useState(false);
        const [minCapcityFlag, setMinCapcityFlag] = useState(false);

        const history = useHistory();
        let allValues = { ...values, ...{ id: currentId } };
        const jsonValues = JSON.stringify(allValues);
        sessionStorage.setItem('details', jsonValues);

        const { minimumcapacity, minhours, events, maximumcapacity, additionalTags } = values;

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || maxCapcityFlag;

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
          </p>
        ) : null;

        const maxLengthMessage = intl.formatMessage(
          {
            id: 'EditListingDescriptionForm.maxLengthSqft',
          },
          { maxLength: SQFT_MAX_LENGTH }
        );

        const maxLengthMessageNumber = intl.formatMessage(
          {
            id: 'EditListingDescriptionForm.maxLength',
          },
          { maxLength: SQFT_MAX_LENGTH }
        );

        const maxValueMessage = intl.formatMessage(
          { id: 'EditListingDestailsForm.maxValueThanMin' },
          { value: minimumcapacity ? minimumcapacity : 0 }
        );

        const minValueMessage = intl.formatMessage(
          { id: 'EditListingDestailsForm.minValueThanMax' },
          { value: maximumcapacity ? maximumcapacity : 0 }
        );

        const maxHourseMessage = intl.formatMessage(
          { id: 'EditListingDestailsForm.maxValueThanMin' },
          { value: minhours ? minhours : 1 }
        );

        const sqFtLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.spaceSqftTitle',
        });

        const negativeValMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.negativeValueMessage',
        });

        const sqftPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.spaceSqftPlaceholder',
        });

        const sqftRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.spaceSqftRequired',
        });

        const minCapacityPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.minCapacityPlaceholder',
        });

        const maxCapacityPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.maxCapacityPlaceholder',
        });

        const minCapacityRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.minCapacityRequired',
        });

        const maxCapacityRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.maxCapacityRequired',
        });

        const eventsLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.eventLabel',
        });

        const tagsLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.tagsLabel',
        });

        const eventsRequired = intl.formatMessage({
          id: 'EditListingDescriptionForm.eventRequired',
        });

        const previousLabel = intl.formatMessage(
          { id: 'EditListingDetailsForm.previousLabel' },
          { previousValue: 'Description' }
        );

        const maxLength10Message = maxLength(maxLengthMessage, SQFT_MAX_LENGTH);

        const maxLength10MessageNumber = maxLengthNumber(maxLengthMessageNumber, SQFT_MAX_LENGTH);

        const negativeValueMessage = checkNegativeValue(negativeValMessage);

        const maxCapacityValueMessage = checkMaxIsGreatThenMin(maxValueMessage, minimumcapacity);
        const minCapacityValueMessage = checkMinIsLessThenMax(minValueMessage, maximumcapacity);
        // console.log(document.querySelector("#minimumcapacity") && document.querySelector("#minimumcapacity").value, minimumcapacity)
        const maxHoursValueMessage = checkMaxHourIsGreatThenMinHOur(maxHourseMessage, minhours);
        const checkBoxEventsFlag = nonEmptyArray(eventsRequired, events);

        const goBack = () => {
          let { pathname } = history.location;
          let splitPathname = pathname.split('/');
          splitPathname[splitPathname.length - 1] = 'description';
          history.push(splitPathname.join('/'));
        };

        const checkMaxCapcityVal = val => {
          if (Number(maximumcapacity) > Number(val)) {
            setMaxCapcityFlag(false);
          } else {
            setMaxCapcityFlag(true);
            // setMinCapcityFlag(false);
          }
        };

        const checkMinCapcityVal = val => {
          if (Number(minimumcapacity) < Number(val)) {
            setMaxCapcityFlag(false);
          } else {
            setMaxCapcityFlag(true);
          }
        };
        const handleKeyDown = event => {
          // console.log('User pressed: ', event.key);

          // console.log(message);

          if (event.key === 'Backspace') {
            // 👇️ your logic here
            console.log('Backspace key pressed ✅');
          }
        };

        const inputProps = {
          placeholder: 'Add a tag by hitting Enter after each word/phrase',
          id:"additionalTags",
          "aria-label":"additionalTags"
        };
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            <FormSpy onChange={onChange} subscription={{ visited: true }} />
            <div>
              <FieldTextInput
                className={css.title}
                name="sqFt"
                id="sqFt"
                type="number"
                label={sqFtLabel}
                impfield={"true"}
                placeholder={sqftPlaceholder}
                maxLength={SQFT_MAX_LENGTH}
                validate={composeValidators(
                  required(sqftRequiredMessage),
                  maxLength10Message,
                  negativeValueMessage
                )}
                onChange={e => {
                  updateDetailsTabPublicData({ sqFt: e.target.value });
                }}
              />
            </div>
            <div>
              <h3 className={`${css.title} ${css.capacityHeading}`}>
                Capacity<span className={css.impFieldCss}>*</span>
              </h3>
              <div className={css.capacityInputField}>
                <div className={css.maxFieldWrap}>
                  <FieldTextInput
                    className={css.title}
                    name="minimumcapacity"
                    id="minimumcapacity"
                    type="number"
                    label="minimumcapacity"
                    impfield={"true"}
                    placeholder={minCapacityPlaceholder}
                    maxLength={SQFT_MAX_LENGTH}
                    validate={composeValidators(
                      required(minCapacityRequiredMessage),
                      maxLength10MessageNumber,
                      negativeValueMessage
                    )}
                    onChange={e => {
                      updateDetailsTabPublicData({ minimumcapacity: e.target.value });
                      checkMaxCapcityVal(e.target.value);
                    }}
                  />
                  {/* {minCapcityFlag ? <p className={css.maxError}>Must be Less than {maximumcapacity}</p> : ''} */}
                </div>

                <div className={css.maxFieldWrap}>
                  <FieldTextInput
                    className={
                      maxCapcityFlag ? `${css.title} ${css.maxCapacityError}` : `${css.title}`
                    }
                    label="maximumcapacity"
                    name="maximumcapacity"
                    id="maximumcapacity"
                    type="number"
                    impfield={"true"}
                    placeholder={maxCapacityPlaceholder}
                    maxLength={SQFT_MAX_LENGTH}
                    validate={composeValidators(
                      required(maxCapacityRequiredMessage),
                      maxLength10MessageNumber,
                      negativeValueMessage
                      // checkMaxIsGreatThenMin(maxValueMessage, minimumcapacity)
                    )}
                    onChange={e => {
                      updateDetailsTabPublicData({ maximumcapacity: e.target.value });
                      checkMinCapcityVal(e.target.value);
                    }}
                  />
                  {maximumcapacity && maxCapcityFlag ? (
                    <p className={css.maxError}>Must be greater than {minimumcapacity}</p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div>
              <h3>Floorplan</h3>
              <div>
                <EditListingFloorPlanForm
                  images={images}
                  onImageUpload={onImageUpload}
                  onRemoveImage={onRemoveImage}
                  // onSubmit={values => {
                  //   onCompleteEditListingWizardTab(tab, values);
                  // }}
                  onUpdateImageOrder={onUpdateImageOrder}
                  className={className}
                  fetchErrors={fetchErrors}
                  form={form}
                  updateDetailsTabPublicData={updateDetailsTabPublicData}
                />
              </div>
              <div className={css.videoWalkThroughCss}>
                <EditListingVirtualWalkThrough
                  intl={intl}
                  composeValidators={composeValidators}
                  maxLength={maxLength}
                  required={required}
                  push={push}
                  walkthroughYT={detailsTabData.walkthroughYT}
                  updateDetailsTabPublicData={updateDetailsTabPublicData}
                />
              </div>
              <div className={css.eventsAllowed}>
                <CheckBoxFieldDescription
                  id="events"
                  name="events"
                  optionName="events"
                  intl={intl}
                  filterConfig={filterConfig}
                  labelName={eventsLabel}
                  composeValidators={composeValidators}
                  events={events}
                  required={required}
                  requiredName={eventsRequired}
                  impfield={"true"}
                  updateDetailsTabPublicData={updateDetailsTabPublicData}
                  eventsData={detailsTabData.events}
                />
              </div>
              <div>
                <HoursAvailable
                  intl={intl}
                  composeValidators={composeValidators}
                  maxLength={maxLength}
                  required={required}
                  maxHoursValueMessage={maxHoursValueMessage}
                  HoursOptions={HoursOptions}
                  MaxHoursOptions={MaxHoursOptions}
                  minhours={minhours}
                  updateDetailsTabPublicData={updateDetailsTabPublicData}
                />
              </div>
              <div className={css.tagsInput}>
                <h3 className={css.tagsWithToolTip}>
                  Tags: &nbsp;
                  <span className={css.tagsTooltip}>
                    <GrTooltip className={css.tooltipOnHover}/>
                    <div className={css.tooltip}>
                      Descriptive keywords or phrases that uniquely describe your space to help
                      venue seekers during their search
                    </div>
                  </span>
                </h3>

                <TagsInput
                  id="additionalTags"
                  name="additionalTags"
                  value={additionalTags}
                  onChange={val => {
                    updateDetailsTabPublicData({ additionalTags: val });
                  }}
                  removeKeys="0"
                  inputProps={inputProps}
                />
              </div>
            </div>

            <div className={css.buttonWrapper}>
              <Button className={css.previousButton} type="button" onClick={goBack}>
                {previousLabel}
              </Button>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingDetailsForm.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDetailsForm.propTypes = {
  rootClassName: string,
  intl: intlShape.isRequired,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  onImageUpload: func.isRequired,
  images: array.isRequired,
  onRemoveImage: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  HoursOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  MaxHoursOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  // propertyTypeOptions: arrayOf({
  //   attributes: shape({
  //     key: string.isRequired,
  //     label: string.isRequired,
  //   }),
  //   images: array,
  // }),
  // filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingDetailsForm);
