import React, { Component, useEffect, useState } from 'react';
import { array, bool, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ListingCard.module.css';
import SectionLikes from '../../containers/ListingPage/SectionLikes';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateLikes } from '../../containers/ListingPage/ListingPage.duck';
import {
  addProduct,
  toggleCompareProduct,
  getProductIds,
  toggleFavoriteCompareProduct,
  getOnlyListingIds,
} from '../../util/productCompareService';
import { BsArrowLeftRight } from 'react-icons/bs';
import { isEmpty } from 'lodash';
import Compare from '../../assets/compare.png';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const MyWishlistListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    params: rawParams,
    onUpdateLikes,
    updateLikesInProgress,
    currentUser,
    isCompared,
    myListings,
    updateCompareProductRequest,
    compareIds,
    event,
    ...rest
  } = props;

  const [compareItems, setCompareItems] = useState([]);

  useEffect(() => {
    if (isEmpty(compareIds)) {
      setCompareItems(getOnlyListingIds());
    } else {
      setCompareItems(compareIds);
    }
  }, [compareIds]);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const listingId = new UUID(currentListing.id.uuid);
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const hasImages = currentListing.images && currentListing.images.length > 0;

  const bannerImages =
    currentListing &&
    currentListing.attributes &&
    currentListing.attributes.publicData &&
    currentListing.attributes.publicData.bannerImagesData &&
    currentListing.attributes.publicData.bannerImagesData;

  const fisrtImageFlag = currentListing.images.filter((val, i) => {
    if (bannerImages && bannerImages.length > 0) {
      return val.id.uuid === bannerImages[0];
    }
  });
  const firstImage = hasImages
    ? fisrtImageFlag.length > 0
      ? fisrtImageFlag[0]
      : currentListing.images[0]
    : null;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const handleCompareToggled = () => {
    toggleFavoriteCompareProduct(event, id);
    updateCompareProductRequest(getOnlyListingIds());
  };

  const { displayName } = listing?.author?.attributes?.profile;
  const fullName = displayName ? `Hosted by ${displayName}` : '';
  return (
    <div className={css.listingCardDetails}>
      <div className={css.listingCardInfo}>

        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <div
            className={css.threeToTwoWrapper}
          >
            <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
          </div>
          <div className={css.info}>
            <div className={css.price}>
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div>
                <span className={css.favHostName}>{fullName}</span>
              </div>
              <div className={css.certificateInfo}>
                {certificate && !certificate.hideFromListingInfo ? (
                  <span>{certificate.label}</span>
                ) : null}
              </div>
            </div>
          </div>
        </NamedLink>
      </div>
      <div className={css.favOnPlp}>
        {event && event !== 'all_events' && (
          <AddToCompareButton
            onCompareClick={handleCompareToggled}
            isAddedToCompare={compareItems.indexOf(listingId.uuid) > -1}
          />
        )}
        <SectionLikes
          {...rest}
          // publicData={publicData}
          onUpdateLikes={onUpdateLikes}
          updateLikesInProgress={updateLikesInProgress}
          listingId={listingId.uuid}
          currentUser={currentUser}
          currentListing={currentListing}
          intl = {intl}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { currentUser } = state.user;
  const { myListings } = state.MyWishListPage;
  const { compareIds = getOnlyListingIds() } = state.CompareProduct;
  const isCompared = compareIds.indexOf(ownProps.listing.id.uuid) > -1;

  return {
    currentUser,
    myListings,
    isCompared,
    compareIds,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateLikes: (listingId,currentListing,priceTitle) => dispatch(updateLikes(listingId,currentListing,priceTitle)),
});

MyWishlistListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  event: null,
};

MyWishlistListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  currentUser: propTypes.currentUser,

  // Responsive image sizes hint
  renderSizes: string,

  event: string,
  // updateLikesInProgress: bool.isRequired,
};

const MyWishlistListingCardPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MyWishlistListingCardComponent);

export default MyWishlistListingCardPage;

/**
 * Compare Button Function.
 */
export const AddToCompareButton = ({ onCompareClick, isAddedToCompare }) => {
  const classes = classNames(
    isAddedToCompare ? css.addedToCompare : css.notAddedToCompare,
    css.compareIcon
  );
  return (
    <span className={classes} onClick={onCompareClick}>
      <img src={Compare} width="27" height="27" />
    </span>
  );
};
