import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { required, composeValidators, nonEmptyArray } from '../../util/validators';
import config from '../../config';
import { compose } from 'redux';
import { Button, FieldCheckboxGroup, Form, FieldTextInput } from '../../components';
import { useHistory } from 'react-router-dom';

import css from './EditListingLocationRulesForm.module.css';

const EditListingLocationRulesForm = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
        invalid,
        values,
        currentId,
        onChange,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const history = useHistory();

      let allValues = { ...values, ...{ id: currentId } };
      const jsonValues = JSON.stringify(allValues);
      sessionStorage.setItem('locationrules', jsonValues);

      const { locationrules } = values;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const locationRulesRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.locationRulesRequired',
      });

      const otherRulesPlaceholder = intl.formatMessage({
        id: 'EditListingVenueRulesForm.otherRulesPlaceholder',
      });

      const otherRulesLabel = intl.formatMessage({
        id: 'EditListingVenueRulesForm.otherRulesTitle',
      });

      const locationRulesOptions = findOptionsForSelectFilter('locationrules', filterConfig);

      const noneAdditionalServicesObject = [
        {
          key: 'none_location_rules',
          label: 'None',
        },
      ];

      const options = [...locationRulesOptions, ...noneAdditionalServicesObject];

      const previousLabel = intl.formatMessage(
        { id: 'EditListingDetailsForm.previousLabel' },
        { previousValue: 'Location' }
      );

      const goBack = () => {
        let { pathname } = history.location;
        let splitPathname = pathname.split('/');
        splitPathname[splitPathname.length - 1] = 'location';
        history.push(splitPathname.join('/'));
      };

      const checkBoxFlag = nonEmptyArray(locationRulesRequired, locationrules);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FormSpy onChange={onChange} subscription={{ visited: true }} />
          <FieldCheckboxGroup
            className={css.features}
            id={name}
            name={name}
            intl={intl}
            filterConfig={filterConfig}
            options={options}
            validate={composeValidators(required(locationRulesRequired), checkBoxFlag)}
          />

          <FieldTextInput
            className={css.otherRules}
            name="otherrules"
            id="otherrules"
            type="textarea"
            label={otherRulesLabel}
            placeholder={otherRulesPlaceholder}
          />

          <div className={css.buttonWrapper}>
            <Button className={css.previousButton} type="button" onClick={goBack}>
              {previousLabel}
            </Button>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingLocationRulesForm.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingLocationRulesForm.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  intl: intlShape.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingLocationRulesForm);
