import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './ListingPage.module.css';
import { BsStarFill, BsStar } from 'react-icons/bs';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { formatMessage } from '@formatjs/intl';

const SectionLikes = props => {
  const {
    onUpdateLikes,
    listingId,
    currentUser,
    updateLikesInProgress,
    currentListing,
    intl,
  } = props;

  const priceData = (price, intlShape) => {
    if (price && price.currency === config.currency) {
      const formattedPrice = formatMoney(intlShape, price);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: intlShape.formatMessage(
          { id: 'ListingCard.unsupportedPrice' },
          { currency: price.currency }
        ),
        priceTitle: intlShape.formatMessage(
          { id: 'ListingCard.unsupportedPriceTitle' },
          { currency: price.currency }
        ),
      };
    }
    return {};
  };

  const price = currentListing.attributes.price;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const currentLikes = currentUser?.attributes?.profile?.privateData?.likedListings;
  const alreadyLiked = currentLikes?.includes(listingId);
  const classes = classNames(
    currentUser ? css.heartIcon : css.heartDisabled,
    alreadyLiked ? css.iconLiked : null
  );

  const FavIcon = () => {
    return alreadyLiked ? <BsStarFill size={27} /> : <BsStar size={27} />;
  };

  return (
    <span
      className={classes}
      title="Save To Favourites"
      onClick={e => {
        if (!updateLikesInProgress && currentUser) {
          e.preventDefault();
          e.stopPropagation();
          onUpdateLikes(listingId, currentListing, priceTitle);
        }
      }}
    >
      <FavIcon />
    </span>
  );
};

export default SectionLikes;
