import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate, timestampToDateFromLocalToTimeZone } from '../../util/dates';
import { LINE_ITEM_DAY, propTypes } from '../../util/types';
import config from '../../config';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldRadioButton,
  ModalInMobile,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureListing } from '../../util/data';

import css from './BookingTimeForm.module.css';
import { priceDisplay } from '../../util/dynamicPricingHelpers';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

let bookingTypeHourlyFlag = true;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state={
      isBreakdownOpen:false
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onScheduleChange = this.onScheduleChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const {
      bookingStartTime,
      bookingEndTime,
      DefaultBookinStartTime,
      DefaultBookingEndTime,
      currentBookingEndTime,
      currentBookingStartTime,
      customBookinStartTime,
      customBookingEndTime,
      dailyEndTime,
      bookingChange,
      isSlotLessThenMinhours,
      isSlotLessThenMaxhours,
    } = formValues.values;

    const { timeZone } = this.props;
    const bookingEndTimeVal = bookingEndTime;
    const bookingStartTimeVal = bookingStartTime;
    let differneceHours =
      (new Date(Number(bookingEndTimeVal)).getTime() -
        new Date(Number(bookingStartTimeVal)).getTime()) /
      1000;
    differneceHours /= 60 * 60;
    differneceHours = Math.abs(Math.round(differneceHours));

    const startDate = bookingStartTime
      ? timestampToDateFromLocalToTimeZone(bookingStartTime, timeZone)
      : null;
    const endDate = bookingEndTime
      ? timestampToDateFromLocalToTimeZone(bookingEndTime, timeZone)
      : null;
    const DefaultBookinStartDate = DefaultBookinStartTime
      ? timestampToDateFromLocalToTimeZone(DefaultBookinStartTime, timeZone)
      : null;
    const DefaultBookingEndDate = DefaultBookingEndTime
      ? timestampToDateFromLocalToTimeZone(DefaultBookingEndTime, timeZone)
      : null;

    const isSlotLessThenMinhoursDate = isSlotLessThenMinhours
      ? timestampToDateFromLocalToTimeZone(isSlotLessThenMinhours, timeZone)
      : null;
    const isSlotLessThenMaxhoursDate = isSlotLessThenMaxhours
      ? timestampToDateFromLocalToTimeZone(isSlotLessThenMaxhours, timeZone)
      : null;
    const currentBookingEndDate = currentBookingEndTime
      ? timestampToDateFromLocalToTimeZone(currentBookingEndTime, timeZone)
      : null;
    const currentBookingStartDate = currentBookingStartTime
      ? timestampToDateFromLocalToTimeZone(currentBookingStartTime, timeZone)
      : null;

    const customBookingStartdate = customBookinStartTime
      ? timestampToDateFromLocalToTimeZone(customBookinStartTime, timeZone)
      : null;
    const customBookingEndDate = customBookingEndTime
      ? timestampToDateFromLocalToTimeZone(customBookingEndTime, timeZone)
      : null;

    const dailyEndDate = dailyEndTime
      ? timestampToDateFromLocalToTimeZone(dailyEndTime, timeZone)
      : null;

    const bookingType = formValues.values.bookingType;
    const listingId = this.props.listingId;

    const listofPublicData = this.props ? this.props.getListing(listingId) : null;
    const currentListing = this.props
      ? listofPublicData && ensureListing(listofPublicData).attributes.publicData
      : 'null';

    const isOwnListing = this.props.isOwnListing;
    const minHoursvalue =
      currentListing && differneceHours >= Number(currentListing.minhours)
        ? Number(currentListing.minhours)
        : 1;
    // ensureListing

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;
    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: {
          startDate,
          endDate,
          bookingType,
          DefaultBookinStartDate,
          DefaultBookingEndDate,
          currentBookingEndDate,
          currentBookingStartDate,
          customBookingStartdate,
          customBookingEndDate,
          dailyEndDate,
          minHoursvalue,
          bookingChange,
          isSlotLessThenMinhoursDate,
          isSlotLessThenMaxhoursDate,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  onScheduleChange(e, form, values) {
    const bookingChange = 'BookingChange';
    form.batch(() => {
      form.change('bookingStartTime', null);
      form.change('bookingEndDate', { date: null });
      form.change('bookingEndTime', null);
      form.change('DefaultBookinStartTime', null);
      form.change('customBookinStartTime', null);
      form.change('DefaultBookingEndTime', null);
      form.change('customBookingEndTime', null);
      form.change('currentBookingStartTime', null);
      form.change('currentBookingEndTime', null);
      form.change('bookingType', e.target.value);
      form.change('bookingChange', bookingChange);
    });
  }

  render() {
    const {
      rootClassName,
      getListing,
      listingId,
      className,
      price: unitPrice,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const lists = getListing(listingId);
    const currentListing = ensureListing(lists).attributes.publicData;

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        initialValues={{ bookingType: 'hourlyBooking' }}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            onManageDisableScrolling,
            isClosed,
            dailyPriceAsMoney
          } = fieldRenderProps;

          const { bookingType } = values;

          const bookingEndTimeVal = values.bookingEndTime;
          const bookingStartTimeVal = values.bookingStartTime;
          let differneceHours =
            (new Date(Number(bookingEndTimeVal)).getTime() -
              new Date(Number(bookingStartTimeVal)).getTime()) /
            1000;
          differneceHours /= 60 * 60;
          differneceHours = Math.abs(Math.round(differneceHours));
          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const closeBookModal = () => {
            this.setState({isBreakdownOpen:false})
          };

          const openBookModal = (isOwnListing, isClosed) => {
            if (isOwnListing || isClosed) {
              window.scrollTo(0, 0);
            } else {
              this.setState({isBreakdownOpen:true})
            }
          };

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const dailyScheduleLabel = intl.formatMessage({
            id: 'BookingTimeForm.dailyScheduleLabel',
          });
          const hourlyScheduleLabel = intl.formatMessage({
            id: 'BookingTimeForm.hourlyScheduleLabel',
          });

          const scheduleSelection = (
            <div className={css.bookingScheduleContainer}>
              <FieldRadioButton
                id="hourlyBooking"
                name="bookingType"
                label={hourlyScheduleLabel}
                value="hourlyBooking"
                className={
                  bookingType === 'hourlyBooking'
                    ? `${css.hourlyRadioBtn} ${css.radioBtns} ${css.activeRadio}`
                    : `${css.hourlyRadioBtn} ${css.radioBtns}`
                }
                onChange={e => this.onScheduleChange(e, form, values)}
              />
              <FieldRadioButton
                id="dailyBooking"
                name="bookingType"
                label={dailyScheduleLabel}
                value="dailyBooking"
                className={
                  bookingType === 'dailyBooking'
                    ? `${css.dailyRadioBtn} ${css.radioBtns}  ${css.activeRadio}`
                    : `${css.dailyRadioBtn} ${css.radioBtns}`
                }
                onChange={e => this.onScheduleChange(e, form, values)}
              />
            </div>
          );

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType: bookingType === 'dailyBooking' ? LINE_ITEM_DAY : unitType,
                  startDate,
                  endDate,
                  timeZone,
                  bookingType,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              <div className={css.priceRaidoBtnWrap}>
                {bookingType === 'hourlyBooking' ? (
                  <><p className={css.priceValue}><span>From</span>{priceDisplay("hourly", unitPrice, dailyPriceAsMoney, currentListing, intl, bookingData, true, true)}</p></>
                ) : (
                  <>
                    <p className={css.priceValue}><span>From</span>{priceDisplay("daily", unitPrice, dailyPriceAsMoney, currentListing, intl, bookingData, true, true)}</p>
                  </>
                )}
                {scheduleSelection}
              </div>

              {monthlyTimeSlots && timeZone ? (
                <>
                  <FieldDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={this.props.listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    customAvailabilityPlan={currentListing.customAvailabilityPlan}
                    bookingType={bookingType}
                    currentListing={currentListing}
                    lineItems={lineItems}
                    unitType={unitType}
                    bookingTypeHourlyFlag={bookingTypeHourlyFlag}
                  />
                </>
              ) : null}
              <ModalInMobile
                containerClassName={css.modalContainer}
                id="BookingTimeFormInModal"
                isModalOpenOnMobile={this.state.isBreakdownOpen}
                onClose={() => closeBookModal()}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                {bookingType === 'hourlyBooking' ? (
                  <>
                    {currentListing &&
                    differneceHours >= Number(currentListing.minhours) &&
                    differneceHours <= Number(currentListing.maxhours) ? (
                      <>
                        {bookingInfoMaybe}
                        {loadingSpinnerMaybe}
                        {bookingInfoErrorMaybe}

                        <p className={css.smallPrint}>
                          <FormattedMessage
                            id={
                              isOwnListing
                                ? 'BookingTimeForm.ownListing'
                                : 'BookingTimeForm.youWontBeChargedInfo'
                            }
                          />
                        </p>

                        <div className={submitButtonClasses}>
                          <PrimaryButton type="submit">
                            <FormattedMessage id="BookingTimeForm.requestToBook" />
                          </PrimaryButton>
                        </div>
                      </>
                    ) : (
                      ''
                    )}{' '}
                  </>
                ) : (
                  <>
                    {bookingInfoMaybe} {loadingSpinnerMaybe}
                    {bookingInfoErrorMaybe}
                    <p className={css.smallPrint}>
                      <FormattedMessage
                        id={
                          isOwnListing
                            ? 'BookingTimeForm.ownListing'
                            : 'BookingTimeForm.youWontBeChargedInfo'
                        }
                      />
                    </p>
                    <div className={submitButtonClasses}>
                      <PrimaryButton type="submit">
                        <FormattedMessage id="BookingTimeForm.requestToBook" />{' '}
                      </PrimaryButton>{' '}
                    </div>{' '}
                  </>
                )}
              </ModalInMobile>

              <div
                className={
                  bookingType === 'hourlyBooking'
                    ? differneceHours >= Number(currentListing.minhours) &&
                      differneceHours <= Number(currentListing.maxhours)
                      ? `${css.openBookingForm} `
                      : `${css.openBookingForm} ${css.btnDisabled}`
                    : differneceHours >= Number(currentListing.minhours)
                    ? `${css.openBookingForm}`
                    : `${css.openBookingForm} ${css.btnDisabled}`
                }
              >
                <p
                  className={css.bookButton}
                  onClick={() => openBookModal(isOwnListing, isClosed)}
                >
                  <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                </p>
                {isClosed ? (
                  <div className={css.closedListingButton}>
                    <FormattedMessage id="BookingPanel.closedListingButtonText" />
                  </div>
                ) : null}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    getListing,
    getOwnListing,
  };
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(
  connect(mapStateToProps),
  injectIntl
)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
