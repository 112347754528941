import React, { useState } from 'react';
import './EventsForm.css';

const EventsForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!/^[A-Za-z]+$/.test(formData.firstName)) {
      errors.firstName = "Please enter letters only.";
    }
    if (!/^[A-Za-z]+$/.test(formData.lastName)) {
      errors.lastName = "Please enter letters only.";
    }
    if (!/^\d*$/.test(formData.phoneNumber) && formData.phoneNumber) {
      errors.phoneNumber = "Please enter numbers only.";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const response = await fetch('https://www.swivospace.com/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phoneNumber,
          message: formData.message,
          formType: 'coordinators',
        }),
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({ firstName: '', lastName: '', email: '', phoneNumber: '', message: '' });
      } else {
        alert('Form submission failed.');
      }
    } catch (error) {
      console.error('Error during form submission:', error);
    }
  };

  return (
    <div className="events-form-container">
      <div className="events-form-left">
        <h2>Contact Us</h2>
        <p>If you have questions about anything on our site, please use this form to contact us.</p>
      </div>
      <div className="events-form-right">
        <h2>We'd love to hear from you!</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name *</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {formErrors.firstName && <div className="error-message">{formErrors.firstName}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name *</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {formErrors.lastName && <div className="error-message">{formErrors.lastName}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number (Optional)</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {formErrors.phoneNumber && <div className="error-message">{formErrors.phoneNumber}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="message">Your message *</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <input type="submit" value="Submit" className="submit-button" />
        </form>
      </div>
    </div>
  );
};

export default EventsForm;
