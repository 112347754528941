import React, { Component, useState } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FieldCheckbox, FieldCurrencyInput, IconClose, InlineTextButton } from '../../components';
import css from './EditListingPricingForm.module.css';
import { FieldArray } from 'react-final-form-arrays';

const DynamicPricingHourlyDaily = props => {
  const { hourlyPriceLabel, fieldPlaceholder, dailyPriceLabel, config, push, priceValidators, validators, priceRequired,minPriceRequired } = props;
  const weekDaysSort = {
    "Sun": 0, 'Mon': 1, 'Tue' : 2, 'Wed' : 3, 'Thu' : 4, 'Fri' : 5, 'Sat' : 6 
  }

  return (
    <div className={css.dynamicPriceMainWrap}>
      <FieldArray name="dynamicPriceList">
        {({ fields }) => {
          fields?.value && fields.value.sort((a,b)=>{
            return weekDaysSort[a.dayOfWeek] - weekDaysSort[b.dayOfWeek]
          });
          return (
            <>
              <div className={css.weedDaysWrap}>
                {fields.map((name, index) => {
                  return (
                    <div className={css.priceWrapDiv} key={fields.value[index].dayOfWeek}>
                      <p className={css.weekDaysName}>{fields.value[index].dayOfWeek}</p>
                      <FieldCurrencyInput
                        id={`${name}hourly`}
                        name={`${name}hourly`}
                        className={css.HourlyPrice}
                        label={hourlyPriceLabel}
                        placeholder={fieldPlaceholder}
                        currencyConfig={config.currencyConfig}
                        validate={validators.composeValidators(
                          priceRequired,
                          minPriceRequired
                        )}
                        required={true}
                      />

                      <FieldCurrencyInput
                        id={`${name}daily`}
                        name={`${name}daily`}
                        className={css.DailyPrice}
                        label={dailyPriceLabel}
                        placeholder={fieldPlaceholder}
                        currencyConfig={config.currencyConfig}
                        validate={validators.composeValidators(
                          priceRequired,
                          minPriceRequired
                        )}
                        required={true}
                      />

                      <div
                        className={css.fieldArrayRemove}
                        onClick={() => fields.remove(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        <IconClose rootClassName={css.closeIcon} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

const EditListingDynamicPricingForm = props => {
  const { config, intl, values, weekdays, push, pop, remove, priceValidators, validators,priceRequired,minPriceRequired } = props;

  const { isDynamicPricing = null, dynamicPriceList = null } = values;


  const dailyPriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.dailyDynamicPriceLabel',
  });

  const hourlyPriceLabel = intl.formatMessage({
    id: 'EditListingPricingForm.hourlyDynamicPriceLabel',
  });

  const fieldPlaceholder = intl.formatMessage({
    id: 'EditListingPricingForm.dynamicPriceInputPlaceholderMessage',
  });

  const checkboxLabel = intl.formatMessage({
    id: 'EditListingPricingForm.dynamicPriceCheckboxLabel',
  })

  const dynamicSelectingDayFn = (dayOfWeek) =>{   
    let index;
    const dayOfWeekFlag =  dynamicPriceList && dynamicPriceList.some((val, indexVal)=>{
      if( val.dayOfWeek === dayOfWeek){
        index = indexVal;
      }
      return val.dayOfWeek === dayOfWeek;
    });

    if(dayOfWeekFlag){
      remove('dynamicPriceList', index);
    } else {
      push('dynamicPriceList', {
        hourly: {amount:0, currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY},
        daily: {amount:0, currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY},
        dayOfWeek: dayOfWeek,
      });
    };
  };

  return (
    <>
      {/* <p>Dynamic Pricing</p> */}

      <FieldCheckbox
        id="isDynamicPricing"
        name="isDynamicPricing"
        label={checkboxLabel}
        value={"true"}
        className={css.dynamicBox}
      />

      {isDynamicPricing && !!isDynamicPricing.length && (
        <>
          <div className={css.weedDaysWrap}>
            {weekdays.map((val, index) => {
              const activeFlag = dynamicPriceList && dynamicPriceList.some((priceVal)=>{
                return priceVal.dayOfWeek === val;
              });
              return (
                <InlineTextButton
                  type="button"
                  key={`${val}Btn`}
                  className={activeFlag ?  css.activeWeekBtn : null}
                  value={val}
                  onClick={val => dynamicSelectingDayFn(val.target.value)}
                >
                  {val}
                </InlineTextButton>
              )
            })}

            <DynamicPricingHourlyDaily
              hourlyPriceLabel={hourlyPriceLabel}
              dailyPriceLabel={dailyPriceLabel}
              fieldPlaceholder={fieldPlaceholder}
              config={config}
              push={push}
              priceValidators={priceValidators}
              validators={validators}
              priceRequired ={priceRequired}
              minPriceRequired = {minPriceRequired}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EditListingDynamicPricingForm;