import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingLocationRulesForm } from '../../forms';
import { ListingLink } from '..';

import css from './EditListingLocationRulesPanel.module.css';

const LOCATIONRULES_NAME = 'locationrules';

const EditListingLocationRulesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const currentId = currentListing?.id?.uuid;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingLocationRulesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingLocationRulesPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingLocationRulesPanel.createListingTitle" />
  );

  const locationRulesData = publicData && publicData.locationrules;
  const otherRulesData = publicData && publicData.otherrules;

  const getSessionValues = sessionStorage.getItem('locationrules');
  let convertJsontoParse = JSON.parse(getSessionValues);
  convertJsontoParse = convertJsontoParse?.id == currentId ? convertJsontoParse : 'null';

  return (
    <div className={classes}>
      <h1 className={css.title}>
        {panelTitle}
        <span className={css.impField}>*</span>
      </h1>
      <EditListingLocationRulesForm
        className={css.form}
        name={LOCATIONRULES_NAME}
        currentId={currentId}
        initialValues={{
          locationrules:
            convertJsontoParse && convertJsontoParse.locationrules
              ? convertJsontoParse.locationrules
              : locationRulesData,
          otherrules: (convertJsontoParse  && convertJsontoParse.otherrules) ? convertJsontoParse.otherrules : otherRulesData,
        }}
        onSubmit={values => {
          let { locationrules, otherrules } = values;
          const splitOtherRulesData = otherrules ? otherrules.split(',') : [];
          let alllocationrules = locationrules ? [...locationrules, ...splitOtherRulesData] : null;
          
          const updatedValues = {
            publicData: { locationrules, otherrules, alllocationrules},
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingLocationRulesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingLocationRulesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationRulesPanel;
