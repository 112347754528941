import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dailyPrice = publicData && publicData.dailyPrice ? publicData.dailyPrice : null;
  const dynamicPriceList = publicData && publicData.dynamicPriceList ? publicData.dynamicPriceList : null
  const dynamicPricingFlag = publicData && publicData.isDynamicPricing ? [publicData.isDynamicPricing] : null;
  const currentId = currentListing?.id?.uuid;
  const getSessionValues = sessionStorage.getItem('pricing');
  let convertJsontoParse = JSON.parse(getSessionValues);
  convertJsontoParse = convertJsontoParse?.id == currentId ? convertJsontoParse : 'null';

  const dailyPriceAsMoney = dailyPrice ? new Money(dailyPrice.amount, dailyPrice.currency) : null;

  const convertPriceToMoneyFn = (dynamicPriceVal) =>{
    const updateData = dynamicPriceVal && dynamicPriceVal.length > 0 && dynamicPriceVal.map((val)=>{
      const convertMoneyHourly = new Money(val.hourly.amount, val.hourly.currency);
      const convertMoneyDaily = new Money(val.daily.amount, val.daily.currency);
      return{
        hourly: convertMoneyHourly,
        daily: convertMoneyDaily,
        dayOfWeek: val.dayOfWeek
      }
    });
    return updateData;
  }
  const dynamicPriceListAsMoney = dynamicPriceList ? convertPriceToMoneyFn(dynamicPriceList) : null;
 
  const initialValues = {
    price: convertJsontoParse && convertJsontoParse.price ? convertJsontoParse.price : price,
    dailyPrice:
      convertJsontoParse && convertJsontoParse.dailyPrice
        ? convertJsontoParse.dailyPrice
        : dailyPriceAsMoney,
  };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const convertPriceToNum = price => {
    let priceAmount = price ? price.amount + '' : null;
    let splitDailyPrice = priceAmount ? priceAmount.split('') : null;
    splitDailyPrice && splitDailyPrice.splice(-2, 2);
    let originalPriceValue = splitDailyPrice ? splitDailyPrice.join('') : null;
    return originalPriceValue;
  };


  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  const hourlyPriceAsMoneySession = convertJsontoParse.price
    ? new Money(convertJsontoParse.price.amount, convertJsontoParse.price.currency)
    : price;
  const dailyPriceAsMoneySession = convertJsontoParse.dailyPrice
    ? new Money(convertJsontoParse.dailyPrice.amount, convertJsontoParse.dailyPrice.currency)
    : dailyPriceAsMoney;

  let dynammicPricelistMoneySession = convertJsontoParse.dynamicPriceList ? convertPriceToMoneyFn(convertJsontoParse.dynamicPriceList) : dynamicPriceListAsMoney;
  const removeSdkTypePrice = (dynamicPricingLists) =>{
    const updatedPrice  = dynamicPricingLists && dynamicPricingLists.map((val)=>{
      const data = {
        hourly: {amount: val.hourly.amount, currency: val.hourly.currency},
        daily: {amount: val.daily.amount, currency: val.daily.currency},
        dayOfWeek: val.dayOfWeek
      }
      return data; 
    })
    return updatedPrice;
  };

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        price: hourlyPriceAsMoneySession,
        dailyPrice: dailyPriceAsMoneySession,
        isDynamicPricing: convertJsontoParse.isDynamicPricing ? convertJsontoParse.isDynamicPricing : dynamicPricingFlag,
        dynamicPriceList: dynammicPricelistMoneySession
      }}
      currentId={currentId}
      onSubmit={values => {
        // const { price, cleaningFee = null, dailyPrice = null } = values;
        const { price, dailyPrice = null, isDynamicPricing = null, dynamicPriceList = null } = values;
        const publicData = {};
        // if (cleaningFee) {
        //   publicData.cleaningFee = { amount: cleaningFee.amount, currency: cleaningFee.currency };
        // }
        if (dailyPrice) {
          let dailyyPriceVal = convertPriceToNum(dailyPrice);
          publicData.dailyPrice = {
            amount: dailyPrice.amount,
            currency: dailyPrice.currency,
          };
          publicData.dailyprices = Number(dailyyPriceVal);
        }

        publicData.isDynamicPricing = isDynamicPricing ? isDynamicPricing[0] : null;
        publicData.dynamicPriceList = dynamicPriceList ? removeSdkTypePrice(dynamicPriceList): null;
        const updatedValues = {
          price,
          publicData
        };
        onSubmit(updatedValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      weekdays = {WEEKDAYS}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
