import { init } from '@sentry/browser';
import { denormalisedResponseEntities } from '../../util/data';
import { getOnlyListingIds } from '../../util/productCompareService';

//*********Action Types ************************ */
export const UPDATE_COMPARE_PRODUCT = 'app/ListingPage/UPDATE_COMPARE_PRODUCT_REQUEST';
export const FETCH_COMPARE_PRODUCTS_SUCCESS = 'app/ListingPage/FETCH_COMPARE_PRODUCT_SUCCESS';
export const FETCH_COMPARE_PRODUCTS_ERROR = 'app/ListingPage/FETCH_COMPARE_PRODUCT_ERROR';

//*******************Reducer********************* */

const initialState = {
  compareIds: [],
  id: null,
  compareListings: [],
  fetchCompareProductsListError: null,
  fetchCompareProductsListProgress: null,
};

const compareProduct = (state = initialState, action = {}) => {
  //   console.log('initialState', initialState);
  const { type, payload } = action;
  switch (type) {
    // case SET_INITIAL_VALUES:
    //   return { ...initialState, ...payload };
    case UPDATE_COMPARE_PRODUCT:
      return {
        ...state,
        compareIds: payload,
      };
    case FETCH_COMPARE_PRODUCTS_SUCCESS:
      return {
        ...state,
        fetchCompareProductsListProgress: false,
        compareListings: payload,
        fetchCompareProductsListError: null,
      };
    case FETCH_COMPARE_PRODUCTS_ERROR:
      return {
        ...state,
        fetchCompareProductsListProgress: false,
        fetchCompareProductsListError: payload,
      };

    default:
      return state;
  }
};

export default compareProduct;

// ================ Action creators ================ //
// export const setInitialValues = () => ({
//   type: SET_INITIAL_VALUES,
// });

// export const setInitialValues = initialValues => ({
//   type: UPDATE_COMPARE_PRODUCT,
//   payload: pick(initialValues, Object.keys(initialState)),
// });

export const updateCompareProductRequest = productIds => ({
  type: UPDATE_COMPARE_PRODUCT,
  payload: productIds,
});

export const fetchCompareProductListSuccess = compareListings => ({
  type: FETCH_COMPARE_PRODUCTS_SUCCESS,
  payload: compareListings,
});

export const fetchCompareProductListError = error => ({
  type: FETCH_COMPARE_PRODUCTS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchCompareProductListingData = () => (dispatch, getState, sdk) => {
  const currentCompareProdIds = getOnlyListingIds();
  if (!currentCompareProdIds || currentCompareProdIds.length === 0) {
    dispatch(fetchCompareProductListSuccess([]))
    return null;
  }
  return sdk.listings
    .query({
      ids: currentCompareProdIds,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      // 'limit.images': 1,
    })
    .then(response => {
      const compareListings = denormalisedResponseEntities(response);
      dispatch(fetchCompareProductListSuccess(compareListings));
    })
    .catch(e => {
      dispatch(fetchCompareProductListError(storableError(e)));
    });
};
