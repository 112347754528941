import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import {
  isChangeEmailTakenError,
  isChangeEmailWrongPassword,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  FieldPhoneNumberInput,
  Form,
  PrimaryButton,
  FieldTextInput,
  Button,
  FieldSelect,
} from '../../components';

import css from './ContactUsForm.module.css';

export const ContactUsForm = props => {
  const { readyFlag, progressFlag, characterCheckFn, initialFormState } = props;
  const characterID = useRef();

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={initialFormState}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          subjectOptions,
          className,
          name,
          handleSubmit,
          form,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          filterConfig,
          invalid,
          currentId,
          images,
          onImageUpload,
          onRemoveImage,
          onUpdateImageOrder,
          updateDetailsTabPublicData,
          detailsTabData,
          intl,
          values,
          onChange,
        } = formRenderProps;

        const subjectOptionsList = subjectOptions.map((val, index) => {
          return (
            <option key={val.key} value={val.key}>
              {val.label}
            </option>
          );
        });

        const emailLabel = intl.formatMessage({
          id: 'ContactUsPage.emailTitle',
        });

        const subjectLabel = intl.formatMessage({
          id: 'ContactUsPage.subjectTile',
        });

        const messageLabel = intl.formatMessage({
          id: 'ContactUsPage.MessageTitle',
        });

        const messagePlaceholder = intl.formatMessage({
          id: 'ContactUsPage.messagePlaceholder',
        });

        const selectSubjectRequiredMessage = intl.formatMessage({
          id: 'ContactUsPage.selectSubjectRequired',
        });

        const selectSubjectRequired = validators.required(selectSubjectRequiredMessage);

        const messageRequiredMessage = intl.formatMessage({
          id: 'ContactUsPage.messagetRequired',
        });

        const mesageRequired = validators.required(messageRequiredMessage);


        return (
          <Form onSubmit={handleSubmit} className={css.contactUsWrap}>
            <FieldTextInput
              id="userEmail"
              name="userEmail"
              className={css.emailField}
              type="text"
              label={emailLabel}
              value={values.userEmail}
              disabled={true}
            />

            <FieldSelect
              className={css.subjectWrap}
              name="listItemforSubject"
              id="listItemforSubject"
              label={subjectLabel}
              impfield="true"
              validatorClass={css.validation}
              validate={selectSubjectRequired}
              value={values.listItemforSubject}
              // placeholder={subjectPlaceholder}
            >
              <option value="">Select Subject</option>
              {subjectOptionsList}
            </FieldSelect>

            <FieldTextInput
              className={css.messageField}
              name="messageField"
              id="messageField"
              type="textarea"
              label={messageLabel}
              impfield="true"
              placeholder={messagePlaceholder}
              validatorClass={css.validation}
              validate={mesageRequired}
              onKeyUp={(e)=>{characterCheckFn(e,characterID)}}
              value={values.messageField}
              maxLength="2000"
            />
            <p className={css.characterWrap}>
              <span ref={characterID} id="characterMsg">
                2000 
              </span>
              &nbsp;characters left
            </p>

            <div className={css.submitButton}>
              <Button type="submit" inProgress={progressFlag} disabled={invalid} ready={readyFlag}>
                <FormattedMessage id="ContactUsPage.submitBtntext" />
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default ContactUsForm;
