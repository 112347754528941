import { formatMoney, removingDecimalFromAmount } from './currency';
import { daysBetween, getDay, getNextDateDay } from './dates';
import { types as sdkTypes } from './sdkLoader';
const { Money } = sdkTypes;

export const priceDisplay = (
  frequent,
  price,
  dailyPriceAsMoney,
  publicData,
  intl,
  bookingData = null,
  showPriceWithDecimalFlag = false,
  showPriceWithoutUnitFlag = false
) => {
  const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const isDynamicPricingFlag =
    publicData && publicData.isDynamicPricing ? publicData.isDynamicPricing : false;
  const dynamicPriceList =
    publicData && publicData.dynamicPriceList ? publicData.dynamicPriceList : null;
  let displayPrice = price;
  let perUnit = frequent === 'hourly' ? 'hr' : 'day';
  let finalPriceOutput;
  if (frequent === 'daily') {
    displayPrice = dailyPriceAsMoney;
  }

  if (isDynamicPricingFlag && dynamicPriceList) {
    let filterDailyPrice = null,
      allDayPresentFlag = false;

    // If Bookingdata is present then the below code will run for the pdp page.
    if (bookingData) {
      let weekdayList = [];
      const { startDate = null, endDate = null } = bookingData;

      // Get the difference between the dates
      const daysBetweenDay = daysBetween(startDate, endDate);

      // Getting weekday name from the startdate
      let weekDay = getDay(startDate);

      // storing Collection of days selected for booking
      for (let index = 0; index < daysBetweenDay+1; index++) {
        weekdayList.push(getNextDateDay(startDate,index))   
      }

      // Seperating Day from the dynamic pricing list
      const speratingWeekDay = dynamicPriceList.map(val => {
        return val.dayOfWeek;
      });

      // Seperating Day from the dynamic pricing list
      filterDailyPrice = dynamicPriceList.filter((day, index) => {
        return weekdayList.includes(day.dayOfWeek);
      });

      // Checking whether selected days are present in the dynamic pricing list
      allDayPresentFlag = weekdayList.every((day, index) => {
        return speratingWeekDay.includes(day);
      });
    }

    const BookingDynamicPrice = filterDailyPrice ? [...filterDailyPrice] : [...dynamicPriceList];

    
    // Storing the array of dynamic amount in one array
    const listOfDynamicAmt =
      BookingDynamicPrice &&
      BookingDynamicPrice.map((val, index) => {
        return val[frequent]['amount'];
      });

    // Checking whether its is having booking data or not according to that standard price will be pushed on the listOfDynamicAmt array
    bookingData
      ? !allDayPresentFlag && listOfDynamicAmt.push(displayPrice.amount)
      : listOfDynamicAmt.push(displayPrice.amount);

      const sortingPrice = listOfDynamicAmt.sort((a,b)=>{
        return a - b;
      });
    const convertPricetoMoney = new Money(
      sortingPrice[0],
      process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY
      );
    
    if(showPriceWithDecimalFlag){
        finalPriceOutput = formatMoney(intl, convertPricetoMoney);
        if(showPriceWithoutUnitFlag){
          return `${finalPriceOutput}`
        }
        return `${finalPriceOutput} / ${perUnit}`
    } else {
        finalPriceOutput = removingDecimalFromAmount(intl, convertPricetoMoney);
        return `${finalPriceOutput} / ${perUnit}`
    }
  }
  return showPriceWithDecimalFlag ? `${formatMoney(intl, displayPrice)} ${!showPriceWithoutUnitFlag ? `/ ${perUnit}` : ""}` :`${removingDecimalFromAmount(intl, displayPrice)} / ${perUnit}`;
};
