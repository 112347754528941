import React, { useEffect, useState, useCallback, useRef } from 'react';
import { bool, func, node, number, string, array, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { pickSearchParamsOnly } from '../../containers/SearchPage/SearchPage.helpers';
import classNames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';

import css from './FilterEvents.module.css';

import { IoMdArrowDroprightCircle } from 'react-icons/io';
import { IoMdArrowDropleftCircle } from 'react-icons/io';

import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import {
  DotButton,
  NextButton,
  PrevButton,
} from '../../containers/ListingPage/EmblaCarouselArrowDotBtn';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
const FilterEventComponent = props => {
  const eventsOptions = findOptionsForSelectFilter('events', config.custom.filters);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    slidesToScroll: 1,
    align: 0,
    containScroll: 'trimSnaps',
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollTo = useCallback(
    index => {
      emblaApi && emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  useEffect(() => {
    const { searchInURL, EventsList } = props;
    const filterEvents =
      searchInURL && Object.keys(searchInURL).length > 0
        ? Object.keys(searchInURL).filter(val => {
            return val === 'pub_events';
          })
        : [];

    const getValueofEvent =
      filterEvents.length > 0
        ? filterEvents
            .map(values => {
              return searchInURL[values].split('has_all:').slice(1);
            })
            .join(',')
        : [];
    if (getValueofEvent.length === 0) {
      scrollTo(0);
    } else {
      const findIndex = eventsOptions.map(val => {
        return val.key;
      });
      scrollTo(findIndex.indexOf(getValueofEvent));
    }
  }, [emblaApi]);

  const {
    rootClassName,
    className,
    EventsList,
    applyFilters,
    locationParmsValue,
    searchInURL,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  setTimeout(() => {
    // let urlValues = locationParmsValue ? locationParmsValue.split('%3A') : '';

    const filterEvents =
      searchInURL && Object.keys(searchInURL).length > 0
        ? Object.keys(searchInURL).filter(val => {
            return val === 'pub_events';
          })
        : [];

    const getValueofEvent =
      filterEvents.length > 0
        ? filterEvents
            .map(values => {
              return searchInURL[values].split('has_all:').slice(1);
            })
            .join(',')
        : [];

    // let FullurlValues = locationParmsValue ? locationParmsValue.split('&') : '';
    // const FullurlFilter =
    //   FullurlValues.length > 0
    //     ? FullurlValues.filter(element => {
    //         return element.includes('pub_events');
    //       })
    //         .join()
    //         .split('%3A')
    //     : ' ';

    if (typeof document !== 'undefined') {
      const eventItemList = document.querySelectorAll('.eventChild')
          ? Array.from(document.querySelectorAll('.eventChild'))
          : null;

      eventItemList.forEach((val, i) => {
        if (val.className.includes(`${css.eventActive}`)) {
          val.classList.remove(`${css.eventActive}`);
        }
      });
    }

    // console.log(eventItemList, 'eventItemList');
    if (getValueofEvent.length > 1 && typeof document !== 'undefined' && document.querySelector(`#${getValueofEvent}`)) {
      const fullAllEvents = document.querySelectorAll(`#${getValueofEvent}`);
      fullAllEvents.forEach(val => {
        val.classList.add(`${css.eventActive}`);
      });
    }

    if (getValueofEvent.length > 1 && typeof document !== 'undefined' && document.querySelector(`#${getValueofEvent}`)) {
      const allEvents = Array.from(document.querySelectorAll(`#${getValueofEvent}`));
        allEvents.forEach(val => {
        val.classList.add(`${css.eventActive}`);
      });
    }

    if (
      getValueofEvent.length === 0 &&
      typeof document !== 'undefined' &&
      document.querySelector(`#all_events`)
    ) {
      document.querySelector(`#all_events`).classList.add(`${css.eventActive}`);
    }

    // urlValues.length > 1 && document.querySelector(`#${urlValues[urlValues.length-1]}`) ? document.querySelector(`#${urlValues[urlValues.length-1]}`).classList.add(`${css.eventActive}`) : " ";
    // FullurlFilter.length > 1 && document.querySelector(`#${FullurlFilter[FullurlFilter.length-1]}`) ? document.querySelector(`#${FullurlFilter[FullurlFilter.length-1]}`).classList.add(`${css.eventActive}`) : " ";
  }, 0);

  const filterEventFn = (e, key, id) => {
    const eventItemList = typeof document !== 'undefined' && document.querySelectorAll('.eventChild')
      ? Array.from(document.querySelectorAll('.eventChild'))
      : null;
    const selectedEvent = e.target.closest('.eventChild');
    eventItemList && eventItemList.forEach((val, i) => {
      if (val.className.includes(`${css.eventActive}`)) {
        val.classList.remove(`${css.eventActive}`);
      }
    });
    selectedEvent.classList.add(`${css.eventActive}`);

    applyFilters(`has_all:${key}`, id);
  };

  const EventListShow = EventsList.map((val, i) => {
    return (
      <div
        id={val.key}
        key={val.key}
        className={css.embla__slide + ' eventChild'}
        title={val.label}
        onClick={e => {
          filterEventFn(e, val.key, val.idName);
        }}
      >
        <img src={`/static/icons/${val.imgSrc}`} alt={val.label} width={41} height={41}/>
        <p className={css.iconImgName}>{val.label}</p>
      </div>
    );
  });

  return (
    <>
      <div className={`${css.embla} ${css.eventsCarousel}`}>
        <div className={css.embla__viewport} ref={emblaRef}>
          <div className={css.embla__container}>{EventListShow}</div>
        </div>

        <PrevButton onClick={scrollPrev} className={css.prevBtnEvent} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} className={css.nextBtnEvent} enabled={nextBtnEnabled} />
        {/* <MdArrowBackIos
          className={css.prevBtn}
          size={28}
          onClick={scrollPrev}
          disabled={!prevBtnEnabled}
        />
        <MdArrowForwardIos
          className={css.nextBtn}
          size={28}
          onClick={scrollNext}
          disabled={!nextBtnEnabled}
        /> */}

        <div className={css.eventsDots}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

FilterEventComponent.defaultProps = {
  EventsList: null,
  className: null,
  rootClassName: null,
};

FilterEventComponent.propTypes = {
  EventsList: array,
  className: string,
  rootClassName: string,
};

const FilterEvent = FilterEventComponent;
export default FilterEvent;
