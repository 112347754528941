import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing, ensureListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onImageUpload,
    images,
    onRemoveImage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const currentId = currentListing?.id?.uuid;
  const { floorImage = [] } = currentListing.attributes.publicData;
  let photosImg;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  // const propertyTypeOptions = findOptionsForSelectFilter('propertyType', config.custom.filters);

  // let floorImaarray = images
  //   ? images.map((val, i) => {
  //       let imgId = val.imageId ? val.imageId.uuid : val.id.uuid;
  //       return imgId;
  //     })
  //   : [];

  // const filterimagess = () => {
  //   let duplicateImages = [];
  //   photosImg = [];
  //   for (let i = 0; i < images.length; i++) {
  //     let imgId = images[i].id.uuid;
  //     if (
  //       floorImage.length === 0 ||
  //       (floorImage.indexOf(imgId) !== -1 && floorImaarray.indexOf(imgId) !== -1)
  //     ) {
  //       duplicateImages.push(images[i]);
  //     } else {
  //       photosImg.push(images[i]);
  //     }
  //   }
  //   return duplicateImages;
  // };

  // const removePhotosImages = floorImage ? filterimagess() : images;

  // console.log(floorImaarray);
  // console.log('Floor Images=>', filterimagess(), 'Phots Img=>', photosImg);
  // console.log('All Images', removePhotosImages);

  const categoryData = publicData && publicData.category;
  // const propertyTypeData = publicData && publicData.propertyType;
  const getSessionValues = sessionStorage.getItem('description');
  let convertJsontoParse = JSON.parse(getSessionValues);
  convertJsontoParse = convertJsontoParse?.id == currentId ? convertJsontoParse : 'null';
  // console.log(currentListing.id.uuid)

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        currentId={currentId}
        initialValues={{
          title: convertJsontoParse && convertJsontoParse.title ? convertJsontoParse.title : title,
          description:
            convertJsontoParse && convertJsontoParse.description
              ? convertJsontoParse.description
              : description,
          category:
            convertJsontoParse && convertJsontoParse.category
              ? convertJsontoParse.category
              : categoryData,
          // propertyType:
          //   convertJsontoParse && convertJsontoParse.propertyType
          //     ? convertJsontoParse.propertyType
          //     : propertyTypeData,
        }}
        onSubmit={values => {
          const { title, description, category } = values;
          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              category,
              // propertyType,
              ...(!publicData.walkthroughYT && { walkthroughYT: [{ ytURl: '' }] }),
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categoryOptions={categoryOptions}
        // propertyTypeOptions={propertyTypeOptions}
        // onImageUpload={onImageUpload}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
