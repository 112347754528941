import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { ResponsiveImage, NamedLink } from '../../components';
import { removeFavCompareFromLS } from '../../util/productCompareService';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { parse } from '../../util/urlHelpers';

import css from './CompareNavigationWrapper.module.css';

const CompareNavigationWrapperComponent = props => {
  const {
    children,
    className,
    rootClassName,
    compareListings,
    fetchCompareProductListingData,
    threeColumnFlag = null,
    events,
    ...rest
  } = props;

  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });


  const clearSelectionFn = () => {
    removeFavCompareFromLS();
    fetchCompareProductListingData();
  };

  const compareProductBtn = () => {
    localStorage.setItem('eventsname', JSON.stringify(searchInURL));
  };

  const CompareUI = () => {
    const renderUI = compareListings.map(listing => {
      const { attributes, images } = listing;
      const { title } = attributes;
      const hasImages = images && images.length > 0;
  
      const bannerImages =
        listing &&
        listing.attributes &&
        listing.attributes.publicData &&
        listing.attributes.publicData.bannerImagesData &&
        listing.attributes.publicData.bannerImagesData;
  
      const fisrtImageFlag = images.filter((val, i) => {
        if (bannerImages && bannerImages.length > 0) {
          return val.id.uuid === bannerImages[0];
        }
      });
  
      const firstImage = hasImages
        ? fisrtImageFlag.length > 0
          ? fisrtImageFlag[0]
          : images[0]
        : null;
  
      return (
        <div className={css.products}>
          <div className={css.listingImage}>
            <ResponsiveImage
              rootClassName={classNames(css.rootForImage, css.borderRadiusInheritTop)}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes="250px"
            />
          </div>
          <div className={css.listingDetail}>
            <p>{title}</p>
          </div>
        </div>
      );
    });
    return renderUI;
  };

  return (
    <>
      {compareListings.length ? (
        <div className={`${threeColumnFlag ? css.extendCSSWidth : ''} ${css.mainWrapper}`}>
          <div className={css.wrapperSection}>
            <div className={css.compareBtnWrap}>
              <div
                className={css.selectBtn}
                aria-label="Compare Button"
                title="Compare"
                onClick={compareProductBtn}
              >
                <NamedLink className={css.compareButton} name="ProductComparePage">
                  Compare
                </NamedLink>
              </div>

              <button
                className={css.clearBtn}
                aria-label="Clear Button"
                onClick={clearSelectionFn}
                title=" Clear"
              >
                Clear
              </button>
            </div>

            <p className={css.scrollMsg}>For more listing scroll the below section</p>
          </div>
          <div className={css.compareWrap}><CompareUI /></div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { compareListings } = state.CompareProduct;
  return {
    compareListings,
  };
};

CompareNavigationWrapperComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
};

CompareNavigationWrapperComponent.propTypes = {
  className: string,
  rootClassName: string,
  children: node,
};

const CompareNavigationWrapper = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(CompareNavigationWrapperComponent);

export default CompareNavigationWrapper;
