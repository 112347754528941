import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';

import css from './EditListingPhotosPanel.module.css';
import { consoleSandbox } from '@sentry/utils';

class EditListingPhotosPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const floorPlanImgs = [];
    const bannerPlanImgs = [];
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

    let imgIdsArray = images
      ? images.map((val, i) => {
          let imgId = val.imageId ? val.imageId.uuid : val.id.uuid;
          return imgId;
        })
      : [];

    const { floorImagesData = [] } = currentListing.attributes.publicData;
    const { bannerImagesData = [] } = currentListing.attributes.publicData;

    const filterPhotoImages = () => {
      let imgs = [];
      for (let i = 0; i < images.length; i++) {
        let imgId = images[i].id.uuid;

        if (floorImagesData.length === 0 || floorImagesData.indexOf(imgId) === -1) {
          imgs.push(images[i]);
        } else {
          floorPlanImgs.push(images[i]);
        }
      }
      return imgs;
    };

    // const filterBannerImages = () => {
    //   let imgs = [];
    //   for (let i = 0; i < images.length; i++) {
    //     let imgId = images[i].id.uuid;
    //     if (bannerImagesData.length === 0 || bannerImagesData.indexOf(imgId) === -1) {
    //       imgs.push(images[i]);
    //     } else {
    //       bannerPlanImgs.push(images[i]);
    //     }
    //   }
    //   return imgs;
    // };

    const photoImages = [];
    const getBannerImages = () => {
      let duplicateImages = [];
      if (images) {
        for (let i = 0; i < images.length; i++) {
          let imgId = images[i].id.uuid;
          if (
            (bannerImagesData &&
              bannerImagesData.length > 0 &&
              bannerImagesData.indexOf(imgId) !== -1 &&
              imgIdsArray.indexOf(imgId) !== -1) ||
            (images[i].id &&
              typeof images[i].id === 'string' &&
              images[i].id.startsWith('BANNER_IMG_'))
          ) {
            duplicateImages.push(images[i]);
          } else {
            photoImages.push(images[i]);
          }
        }
      }

      return duplicateImages;
    };

    const filteredBannerImages = getBannerImages();

    let removeFloorImages =
      floorImagesData && floorImagesData.length > 0 ? filterPhotoImages() : images;

    if (filteredBannerImages && filteredBannerImages.length > 0) {
      removeFloorImages = removeFloorImages.filter(val => {
        if (val.imageId && filteredBannerImages[0].imageId) {
          return filteredBannerImages[0].imageId.uuid !== val.imageId.uuid;
        } else if (val.id) {
          return filteredBannerImages[0].imageId
            ? filteredBannerImages[0].imageId.uuid !== val.id.uuid
            : filteredBannerImages[0].id.uuid !== val.id.uuid;
        }
      });
    }

    // let removeBannerImages =
    //   bannerImagesData && bannerImagesData.length > 0 ? filteredBannerImages() : images;

    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingPhotosPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images: removeFloorImages, bannerImages: filteredBannerImages }}
          images={removeFloorImages}
          bannerImages={filteredBannerImages}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, ...updateValues } = values;

            let bannerImgIdsArray =
              filteredBannerImages && filteredBannerImages.length > 0
                ? filteredBannerImages.map((val, i) => {
                    let imgId = val.imageId ? val.imageId.uuid : val.id.uuid;
                    return imgId;
                  })
                : [];

            const upatingImage = [
              ...updateValues.images,
              ...floorPlanImgs,
              ...bannerPlanImgs,
              ...filteredBannerImages,
            ];

            const updatedValues = {
              images: upatingImage,
              publicData: {
                bannerImagesData: bannerImgIdsArray,
              },
            };

            onSubmit(updatedValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
