import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingPoliciesPanel.module.css';

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const cancellationPolicies = publicData && publicData.cancellationPolicies;
  const checkInCheckOutRules = publicData && publicData.checkInCheckOutRules;
  const minMaxBooking = publicData && publicData.minMaxBooking;
  const minimumDeposit = publicData && publicData.minimumDeposit;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const currentId = currentListing?.id?.uuid;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingLocationRulesPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );

  const getSessionValues = sessionStorage.getItem('policies');
  let convertJsontoParse = JSON.parse(getSessionValues);
  convertJsontoParse = convertJsontoParse?.id == currentId ? convertJsontoParse : 'null';

  const cancellationPolicyOptions = findOptionsForSelectFilter(
    'cancellationPolicyOptions',
    config.custom.filters
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        className={css.form}
        publicData={publicData}
        currentId={currentId}
        cancellationPolicyOptions={cancellationPolicyOptions}
        initialValues={{
          cancellationPolicies:
            convertJsontoParse && convertJsontoParse.cancellationPolicies
              ? convertJsontoParse.cancellationPolicies
              : cancellationPolicies,
          checkInCheckOutRules:
            convertJsontoParse && convertJsontoParse.checkInCheckOutRules
              ? convertJsontoParse.checkInCheckOutRules
              : checkInCheckOutRules,
          minMaxBooking:
            convertJsontoParse && convertJsontoParse.minMaxBooking
              ? convertJsontoParse.minMaxBooking
              : minMaxBooking,
          minimumDeposit:
            convertJsontoParse && convertJsontoParse.minimumDeposit
              ? convertJsontoParse.minimumDeposit
              : minimumDeposit,
        }}
        onSubmit={values => {
          const {
            cancellationPolicies,
            checkInCheckOutRules,
            minMaxBooking,
            minimumDeposit,
          } = values;
          const updateValues = {
            publicData: {
              cancellationPolicies,
              checkInCheckOutRules,
              minMaxBooking,
              minimumDeposit,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
