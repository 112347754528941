/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as AuthenticationPageLoader } from './AuthenticationPage/AuthenticationPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as MyWishListPageLoader } from './MyWishListPage/MyWishListPage.duck';
import { loadData as RequestCancellationPageLoader } from './RequestCancellationPage/RequestCancellationPage.duck';
import { loadData as ProductComparePageLoader } from './ProductComparePage/ProductComparePage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import { loadData as TrustAndSafetyPageLoader } from './TrustAndSafetyPage/TrustAndSafetyPage.duck';
import { loadData as PartnershipPageLoader } from './PartnershipPage/PartnershipPage.duck';
import { loadData as LocationsPageLoader } from './LocationsPage/LocationsPage.duck';
import { loadData as CommunityPageLoader } from './CommunityPage/CommunityPage.duck';
import { loadData as HelpCenterPageLoader } from './HelpCenterPage/HelpCenterPage.duck';
import { loadData as WelcomeToSwivoPageLoader } from './WelcomeToSwivoPage/WelcomeToSwivoPage.duck';
import { loadData as GetToKnowUsPageLoader } from './GetToKnowUsPage/GetToKnowUsPage.duck';
import { loadData as BlogPageLoader } from './BlogPage/BlogPage.duck';
import {loadData as SitemapPageLoader} from './SitemapPage/SitemapPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    BlogPage: {
      loadData: BlogPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    CommunityPage: {
      loadData: CommunityPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    HelpCenterPage: {
      loadData: HelpCenterPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    LocationsPage: {
      loadData: LocationsPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PartnershipPage: {
      loadData: PartnershipPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TermsOfServicePage: {
      loadData: TermsOfServicePageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    TrustAndSafetyPage: {
      loadData: TrustAndSafetyPageLoader,
    },
    MyWishListPage: {
      loadData: MyWishListPageLoader,
    },
    ProductComparePage: {
      loadData: ProductComparePageLoader,
    },
    RequestCancellationPage: {
      loadData: RequestCancellationPageLoader,
    },
    WelcomeToSwivoPage: {
      loadData: WelcomeToSwivoPageLoader,
    },
    GetToKnowUsPage:{
      loadData: GetToKnowUsPageLoader,
    },
    SitemapPage:{
      loadData:SitemapPageLoader,
    }
  };
};

export default getPageDataLoadingAPI;
