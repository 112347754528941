import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { compose } from 'redux';
import Collapsible from 'react-collapsible';
import { FiChevronDown } from 'react-icons/fi';
import { Button, FieldCheckboxGroup, Form } from '../../components';
import CheckBoxFieldDescription from '../EditListingDetailsForm/CheckBoxFieldDescription';
import { useHistory } from 'react-router-dom';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  nonEmptyArray,
  nonEmptyAllCheckBoxArray,
} from '../../util/validators';

import css from './EditListingAmenitiesForm.module.css';

const EditListingAmenitiesForm = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
        values,
        publicData,
        currentId,
        onChange,
      } = formRenderProps;

      const events = publicData && publicData.events ? publicData.events : [];
      let allValues = { ...values, ...{ id: currentId } };
      const jsonValues = JSON.stringify(allValues);
      sessionStorage.setItem('amenities', jsonValues);

      const [
        meetings,
        corporate_events,
        cooking_food_events,
        video_shoots,
        photo_shoots,
        parties_celebration,
        weddings,
        performances_shows,
        Cocktail_Hours,
        workshops_classes,
        retail_popups,
        audio_recordings,
      ] = events;
      const history = useHistory();

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const additionalServiceLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.additionalServiceLabel',
      });

      const additionalServiceRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.additionalServiceRequired',
      });

      const meetingAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.meetingAmenitiesLabel',
      });

      const corporateAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.corporateAmenitiesLabel',
      });

      const cookingAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.cookingAmenitiesLabel',
      });

      const videoAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.videoAmenitiesLabel',
      });

      const photoAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.photoAmenitiesLabel',
      });

      const partiesAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.partiesAmenitiesLabel',
      });

      const weddingAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.weddingAmenitiesLabel',
      });

      const performanceAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.performanceAmenitiesLabel',
      });

      const cocktailAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.cocktailAmenitiesLabel',
      });

      const workshopsAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.workshopsAmenitiesLabel',
      });

      const reatailAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.reatailAmenitiesLabel',
      });

      const audioAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.audioAmenitiesLabel',
      });

      const generalAmenitiesLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.generalAmenitiesLabel',
      });

      const generalAmenitiesRequired = intl.formatMessage({
        id: 'EditListingDescriptionForm.generalAmenitiesRequired',
      });

      const amenitiesRequired = intl.formatMessage({
        id: 'EditListingAmenitiesForm.AmenitiesRequired',
      });

      const options = findOptionsForSelectFilter('amenities', filterConfig);

      const sortAmenitiesByName = (a, b) => {
        var checkA = a.label.toUpperCase();
        var checkB = b.label.toUpperCase();
        return checkA < checkB ? -1 : checkA > checkB ? 1 : 0;
      };

      const labelToRemove = 'None';
      const noneObject = [
        {
          key: 'none_general_amenitiies',
          label: 'None',
          eventName: ['general'],
        },
      ];

      const noneAdditionalServicesObject = [
        {
          key: 'none_additional_services',
          label: 'None',
        },
      ];

      const generalOptions = findOptionsForSelectFilter('generalamenities', filterConfig).sort(
        sortAmenitiesByName
      );
      const additionalservices = findOptionsForSelectFilter('additionalservice', filterConfig).sort(
        sortAmenitiesByName
      );

      const filteredAdditionalServices = additionalservices.filter(
        item => item.label !== labelToRemove
      );

      const filteredGeneralOptions = generalOptions.filter(item => item.label !== labelToRemove);

      const updatedAdditionalServices = [
        ...filteredAdditionalServices,
        ...noneAdditionalServicesObject,
      ];
      const updatedGeneralOptions = [...filteredGeneralOptions, ...noneObject];
      const meetingOptions = findOptionsForSelectFilter('meetingamenities', filterConfig);
      const coporateOptions = findOptionsForSelectFilter('corporateamenities', filterConfig);
      const partiesOptions = findOptionsForSelectFilter('partiesamenities', filterConfig);
      const cookingOptions = findOptionsForSelectFilter('cookingamenities', filterConfig);
      const videoOptions = findOptionsForSelectFilter('videoamenities', filterConfig);
      const photoOptions = findOptionsForSelectFilter('photoamenities', filterConfig);
      const weddingOptions = findOptionsForSelectFilter('weddingamenities', filterConfig);
      const performanceOptions = findOptionsForSelectFilter('performanceamenities', filterConfig);
      const cocktailOptions = findOptionsForSelectFilter('cocktailamenities', filterConfig);
      const workshopOptions = findOptionsForSelectFilter('workshopamenities', filterConfig);
      const reatilOptions = findOptionsForSelectFilter('retailamenities', filterConfig);
      const audioOptions = findOptionsForSelectFilter('audioamenities', filterConfig);

      const additionaloptions = findOptionsForSelectFilter('additionalamenities', filterConfig);
      const allAmenitiesList = [
        ...meetingOptions,
        ...coporateOptions,
        ...partiesOptions,
        ...cookingOptions,
        ...videoOptions,
        ...photoOptions,
        ...weddingOptions,
        ...performanceOptions,
        ...cocktailOptions,
        ...workshopOptions,
        ...reatilOptions,
        ...audioOptions,
      ];

      const filterAmenitiesList = allAmenitiesList
        .filter((val, i) => {
          return events.indexOf(val.id) != -1 ? val.amenitiesList : null;
        })
        .flatMap(val => {
          return val.amenitiesList;
        });

      const filterAdditionalAmenities = events.flatMap((eventsname, index) => {
        const tests = additionaloptions.filter((options, ind) => {
          if (options.eventName.includes(eventsname)) {
            return options;
          }
        });
        return tests;
      });

      const removeDuplicates = function(arr, property) {
        return arr.filter((val, index, array) => {
          return (
            index ===
            array.findIndex(el => {
              return el[property] === val[property];
            })
          );
        });
      };

      const filterDuplicatesLabel = removeDuplicates(filterAdditionalAmenities, 'label').sort(
        sortAmenitiesByName
      );

      // const FilterAmenities = function() {
      //   return (
      //     <div className={css.accordin}>
      //       <Collapsible trigger={"Additional Amenities"}>
      //         <FieldCheckboxGroup
      //           className={css.features}
      //           // label={values.label}
      //           id={"additionalamenities"}
      //           name={"additionalamenities"}
      //           options={filterDuplicatesLabel}
      //         />
      //       </Collapsible>
      //     </div>
      //   );
      // };

      const FilterAmenities = allAmenitiesList
        .filter((val, i) => {
          return events.indexOf(val.id) != -1 ? val.amenitiesList : null;
        })
        .map((values, i) => {
          return (
            <div className={css.accordin}>
              <Collapsible trigger={values.label}>
                <FieldCheckboxGroup
                  className={css.features}
                  // label={values.label}
                  id={values.idName}
                  name={values.idName}
                  options={values.amenitiesList}
                />
              </Collapsible>
            </div>
          );
        });

      const previousLabel = intl.formatMessage(
        { id: 'EditListingDetailsForm.previousLabel' },
        { previousValue: 'Details' }
      );

      const goBack = () => {
        let { pathname } = history.location;
        let splitPathname = pathname.split('/');
        splitPathname[splitPathname.length - 1] = 'details';
        history.push(splitPathname.join('/'));
      };

      const { additionalservice, amenities, ...restamenities } = values;
      const allAmentiesValues = [...Object.values(restamenities)]
        .filter(element => {
          return element !== undefined;
        })
        .flat();

      const checkBoxAdditionserviceMsg = nonEmptyArray(
        additionalServiceRequired,
        additionalservice
      );
      const checkBoxAmenities = nonEmptyArray(generalAmenitiesRequired, allAmentiesValues);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FormSpy onChange={onChange} subscription={{ visited: true }} />
          <div className={css.generalWrap}>
            <FieldCheckboxGroup
              id={'generalamenities'}
              name={'generalamenities'}
              className={css.features}
              label={generalAmenitiesLabel}
              options={updatedGeneralOptions}
              // composeValidators={composeValidators}
              // required={required}
              impField={true}
              validate={composeValidators(required(generalAmenitiesRequired), checkBoxAmenities)}
              // requiredName={generalAmenitiesRequired}
            />
          </div>

          {/* {FilterAmenities} */}

          <div className={css.accordin}>
            <Collapsible trigger={'Additional Amenities'}>
              <FieldCheckboxGroup
                className={css.features}
                // label={values.label}
                id={'additionalamenities'}
                name={'additionalamenities'}
                options={filterDuplicatesLabel}
              />
            </Collapsible>
          </div>

          {/* <FieldCheckboxGroup className={css.features} id={name} name={name} options={options} /> */}

          <div className={css.serviceWrap}>
            <FieldCheckboxGroup
              id="additionalservice"
              name="additionalservice"
              optionName="additionalservice"
              intl={intl}
              // impField={true}
              options={updatedAdditionalServices}
              filterConfig={filterConfig}
              label={additionalServiceLabel}
              // composeValidators={composeValidators}
              // required={required}
              // validate={composeValidators(required(additionalServiceRequired),checkBoxAdditionserviceMsg)}
            />
          </div>

          <div className={css.buttonWrapper}>
            <Button className={css.previousButton} type="button" onClick={goBack}>
              {previousLabel}
            </Button>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingAmenitiesForm.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingAmenitiesForm.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  // filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingAmenitiesForm);
