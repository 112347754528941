import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import footerLogo from './swivo_logo.svg';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    // siteTwitterHandle
  } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  // const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={`${css.icons} ${css.facebook}`}
      title={goToFb}
    >
      <FaFacebook />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <IconSocialMediaTwitter />
  //   </ExternalLink>
  // ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={`${css.icons} ${css.instagram}`}
      title={goToInsta}
    >
      <FaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              
                <h3 className={css.connectUs}>
                  <FormattedMessage id="Footer.connectwWith" />
                </h3>
              <div className={css.organizationInfo}>
                {/* <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p> */}
                <div className={css.social_icons_btn}>{socialMediaLinks}</div>
              </div>
            </div>
            <div className={css.infoLinks}>
              <div>
                <h3 className={css.footerLinkMain}>Company</h3>
                <ul className={css.list}>
                  {/* <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </NamedLink>
                  </li> */}
                  <li className={css.listItem}>
                    <NamedLink name="WelcomeToSwivoPage" className={css.link}>
                      <FormattedMessage id="Footer.toAboutPage" />
                    </NamedLink>
                  </li>

                  {/* <li className={css.listItem}>
                    <NamedLink name="RequestCancellationPage" className={css.link}>
                      <FormattedMessage id="Footer.requestCancellationPage" />
                    </NamedLink>
                  </li> */}

                  {/* <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toPressPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toCareersPage" />
                    </NamedLink>
                  </li> */}
                  <li className={css.listItem}>
                    <NamedLink name="TermsOfServicePage" className={css.link}>
                      <FormattedMessage id="Footer.toTermsPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.link}>
                      <FormattedMessage id="Footer.toPrivacyPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="SitemapPage" className={css.link}>
                      <FormattedMessage id="Footer.toSitemapPage" />
                    </NamedLink>
                  </li>
                  {/* <li className={css.listItem}>
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: 'about' }}
                      to={{ hash: '#contact' }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.toContactPage" />
                    </NamedLink>
                  </li> */}
                </ul>
              </div>
              <div>
                <h3 className={css.footerLinkMain}>Explore</h3>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="SearchPage" className={css.link}>
                      <FormattedMessage id="Footer.toSpacesPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.toAddYourSpacePage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PartnershipPage" className={css.link}>
                      <FormattedMessage id="Footer.toPartnerShipPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CommunityPage" className={css.link}>
                      <FormattedMessage id="Footer.toCommunityPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LocationsPage" className={css.link}>
                      <FormattedMessage id="Footer.toLocationsPage" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              {/* <div>
                <h3 className={css.footerLinkMain}>Host</h3>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.toListPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CMSPage" params={{ pageId: 'about' }} className={css.link}>
                      <FormattedMessage id="Footer.toCommunityPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toSwivoProPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toHelpPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: 'about' }}
                      to={{ hash: '#contact' }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.toContactPage" />
                    </NamedLink>
                  </li>
                </ul>
              </div> */}
              <div>
                <h3 className={css.footerLinkMain}>Support</h3>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="HelpCenterPage" className={css.link}>
                      <FormattedMessage id="Footer.toHelpCenterPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="TrustAndSafetyPage" className={css.link}>
                      <FormattedMessage id="Footer.toTrustAndSafetyPage" />
                    </NamedLink>
                  </li>
                  {/* <li className={css.listItem}>
                    <NamedLink name="TrustAndSafetyPage" className={css.link}>
                      <FormattedMessage id="Footer.toTrustAndSafetyPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toReportPage" />
                    </NamedLink>
                  </li> */}
                  {/* <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toHelpPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: 'about' }}
                      to={{ hash: '#contact' }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.toContactPage" />
                    </NamedLink>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={css.copyRight}>
          <h4>All rights reserved by Swivo 2023</h4>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
