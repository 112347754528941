import React, { useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });
  return (
    <div className={css.review}>
      <Avatar
        className={`${css.avatar} ${css.reviewAvatar}`}
        user={review.author}
        disableProfileLink
      />
      <div>
        <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />

        <p className={css.reviewInfo}>
          <UserDisplayName user={review.author} intl={intl} />.
          {/* <span className={css.separator}>•</span>
          {dateString}
          <span className={css.desktopSeparator}>•</span> */}
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
        <p className={css.reviewContent}>{review.attributes.content}</p>
        <h3 className={css.separator}>{dateString}</h3>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [showList, setShowList] = useState(false);
  const totalReviews = reviews.length;

  const showMoreLessFn = () => {
    if (showList) {
      document.querySelector(`#ratingReviewID`).style.maxHeight = '270px';
      document.querySelector(`.showMoreLessBtn`).innerHTML = `Show all ${totalReviews} reviews`;
    } else {
      const scrollHeight = document.querySelector(`#ratingReviewID`).scrollHeight;
      document.querySelector(`#ratingReviewID`).style.maxHeight = `${scrollHeight}px`;
      document.querySelector(`.showMoreLessBtn`).innerHTML = 'Show Less Reviews';
    }
    setShowList(!showList);
  };
  return (
    <>
      <ul className={classes} id={'ratingReviewID'}>
        {reviews.map(r => {
          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review review={r} intl={intl} />
            </li>
          );
        })}
      </ul>
      {props?.plpPage && totalReviews > 3 ? (
        <p className={`${css.showListBtn} showMoreLessBtn`} onClick={showMoreLessFn}>
          {' '}
          {totalReviews > 0 ? `Show all ${totalReviews} reviews` : ''}{' '}
        </p>
      ) : (
        ''
      )}
    </>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
