import { getSitemapStructure } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_SITEMAPLIST_SUCCESS = 'app/ListingPage/FETCH_SITEMAPLIST_SUCCESS';
export const FETCH_SITEMAPLIST_ERROR = 'app/ListingPage/FETCH_SITEMAPLIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  myListings: [],
  fetchSitemapListError: null,
  fetchSitemapListInProgress: false,
};

const sitemapPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SITEMAPLIST_SUCCESS:
      return { ...state, fetchSitemapListInProgress: false, myListings: payload };
    case FETCH_SITEMAPLIST_ERROR:
      return { ...state, fetchSitemapListInProgress: false, fetchSitemapListError: payload };
    default:
      return state;
  }
};

export default sitemapPageReducer;

// ================ Action creators ================ //

export const fetchMySitemapListSuccess = myListings => ({
  type: FETCH_SITEMAPLIST_SUCCESS,
  payload: myListings,
});
export const fetchSitemapListError = error => ({
  type: FETCH_SITEMAPLIST_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //


export const loadData = () => async (dispatch) => {
  return await getSitemapStructure().then((res)=>{
    dispatch(fetchMySitemapListSuccess(res));
  }).catch(error=>{
    dispatch(fetchSitemapListError(storableError(error)));
  })
};
