import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';
import { useHistory } from 'react-router-dom';
import { composeValidators, required } from '../../util/validators';
import css from './EditListingPoliciesForm.module.css';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        currentId,
        onChange,
        cancellationPolicyOptions,
      } = formRenderProps;

      const history = useHistory();

      let allValues = { ...values, ...{ id: currentId } };
      const jsonValues = JSON.stringify(allValues);
      sessionStorage.setItem('policies', jsonValues);

      const cancellationLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.cancellationPolicyLabel',
      });
      const cancellationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.cancellationPolicyPlaceholder',
      });

      const minDepositLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.minDepositLabel',
      });

      const minDepositPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.minDepositPlaceholder',
      });

      const minMaxBookingLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.minMaxBookingLabel',
      });

      const minMaxBookingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.minMaxBookingPlaceholder',
      });

      const checkInCheckOutLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.checkInCheckOutLabel',
      });

      const checkInCheckOutPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.checkInCheckOutPlaceholder',
      });

      const cancellationPolicyRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.cancellationPolicyRequiredMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const previousLabel = intl.formatMessage(
        { id: 'EditListingDetailsForm.previousLabel' },
        { previousValue: 'Venue Rules' }
      );

      const goBack = () => {
        let { pathname } = history.location;
        let splitPathname = pathname.split('/');
        splitPathname[splitPathname.length - 1] = 'VenueRules';
        history.push(splitPathname.join('/'));
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FormSpy onChange={onChange} subscription={{ visited: true }} />
          <FieldTextInput
            id="minimumDeposit"
            name="minimumDeposit"
            className={css.policy}
            type="textarea"
            label={minDepositLabelMessage}
            placeholder={minDepositPlaceholderMessage}
          />
          <FieldSelect
            className={css.policy}
            name="cancellationPolicies"
            id="cancellationPolicies"
            label={cancellationLabelMessage}
            impfield={'true'}
            validate={composeValidators(required(cancellationPolicyRequiredMessage))}
          >
            <option className={css.cancellationPoliciesPlaceholder} value="">
              Select Cancellation Policy
            </option>
            {cancellationPolicyOptions.map((c, k) =>
              'subcategory' in cancellationPolicyOptions[k] ? (
                <optgroup label={c.label} key={c.key} className={css.cancellationPolicyOptions}>
                  {c.subcategory.map(subCat => (
                    <option key={subCat.key} value={subCat.key}>
                      {subCat.label}
                    </option>
                  ))}
                </optgroup>
              ) : (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              )
            )}
          </FieldSelect>

          <FieldTextInput
            id="checkInCheckOutRules"
            name="checkInCheckOutRules"
            className={css.policy}
            type="textarea"
            label={checkInCheckOutLabelMessage}
            placeholder={checkInCheckOutPlaceholderMessage}
          />

          <FieldTextInput
            id="minMaxBooking"
            name="minMaxBooking"
            className={css.policy}
            type="textarea"
            label={minMaxBookingLabelMessage}
            placeholder={minMaxBookingPlaceholderMessage}
          />

          <div className={css.buttonWrapper}>
            <Button className={css.previousButton} type="button" onClick={goBack}>
              {previousLabel}
            </Button>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
