import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, Button, Form, ValidationError } from '../../components';

import css from './EditListingFloorPlanForm.module.css';

const ACCEPT_IMAGES = 'image/*';

export class EditListingFloorPlanFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(files, form) {
    if (files && files.length > 0) {
      this.setState({ imageUploadRequested: true });
      const filesToUpload = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        filesToUpload.push({ id: `FLOOR_IMG_${file.name}_${Date.now()}`, file: file });
      }

      const values = { ...form.getState().values };
      this.props
        .onImageUpload(filesToUpload)
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  render() {
    const {
      intl,
      fetchErrors,
      images,
      className,
      onRemoveImage,
      imageUploadRequested,
      form,
    } = this.props;
    const chooseImageText = (
      <span className={css.chooseImageText}>
        <span className={css.chooseImage}>
          <FormattedMessage id="EditListingPhotosForm.chooseImage" />
        </span>
        <span className={css.imageTypes}>
          <FormattedMessage id="EditListingPhotosForm.imageTypes" />
        </span>
      </span>
    );

    const imageRequiredMessage = intl.formatMessage({
      id: 'EditListingPhotosForm.imageRequired',
    });

    const { publishListingError, showListingsError, updateListingError, uploadImageError } =
      fetchErrors || {};
    const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

    let uploadImageFailed = null;

    if (uploadOverLimit) {
      uploadImageFailed = (
        <p className={css.error}>
          <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
        </p>
      );
    } else if (uploadImageError) {
      uploadImageFailed = (
        <p className={css.error}>
          <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
        </p>
      );
    }

    // NOTE: These error messages are here since Photos panel is the last visible panel
    // before creating a new listing. If that order is changed, these should be changed too.
    // Create and show listing errors are shown above submit button
    const publishListingFailed = publishListingError ? (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
      </p>
    ) : null;
    const showListingFailed = showListingsError ? (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
      </p>
    ) : null;

    const submittedOnce = this.submittedImages.length > 0;
    // imgs can contain added images (with temp ids) and submitted images with uniq ids.
    const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
    const imageIdsFromProps = arrayOfImgIds(images);
    const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
    const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
    const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

    // const submitReady = (updated && pristineSinceLastSubmit) || ready;
    // const submitInProgress = updateInProgress;
    // const submitDisabled = invalid || disabled || submitInProgress || imageUploadRequested || ready;

    const classes = classNames(css.root, className);

    return (
      <>
        {updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPhotosForm.updateFailed" />
          </p>
        ) : null}
        <AddImages
          className={css.imagesField}
          images={images}
          thumbnailClassName={css.thumbnail}
          savedImageAltText={intl.formatMessage({
            id: 'EditListingPhotosForm.savedImageAltText',
          })}
          onRemoveImage={onRemoveImage}
        >
          <Field
            id="floorImages"
            name="floorImages"
            accept={ACCEPT_IMAGES}
            form={null}
            label={chooseImageText}
            type="file"
            disabled={imageUploadRequested}
            multiple
          >
            {fieldprops => {
              const { accept, input, label, disabled: fieldDisabled, multiple } = fieldprops;
              const { name, type } = input;
              const onChange = e => {
                const file = e.target.files;
                // console.log('john', file);
                form.change(`floorImages`, file);
                form.blur(`floorImages`);
                this.onImageUploadHandler(file, form);
              };
              const inputProps = { accept, id: name, name, onChange, type, multiple };
              return (
                <div className={css.addImageWrapper}>
                  <div className={css.aspectRatioWrapper}>
                    {fieldDisabled ? null : (
                      <input {...inputProps} className={css.addImageInput} multiple />
                    )}
                    <label htmlFor={name} className={css.addImage}>
                      {label}
                    </label>
                  </div>
                </div>
              );
            }}
          </Field>

          <Field
            component={props => {
              const { input, meta } = props;
              return (
                <div className={css.imageRequiredWrapper}>
                  <input {...input} />
                  {/* <ValidationError fieldMeta={meta} /> */}
                </div>
              );
            }}
            name="images"
            type="hidden"
            // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
          />
        </AddImages>
        {uploadImageFailed}

        {/* <p className={css.tip}>
          <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
        </p> */}
        {publishListingFailed}
        {showListingFailed}
      </>
    );
  }
}

EditListingFloorPlanFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingFloorPlanFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  // onSubmit: func.isRequired,
  // saveActionMsg: string.isRequired,
  // disabled: bool.isRequired,
  // ready: bool.isRequired,
  // updated: bool.isRequired,
  // updateInProgress: bool,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingFloorPlanFormComponent);
