import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import {
  isChangeEmailTakenError,
  isChangeEmailWrongPassword,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  FieldPhoneNumberInput,
  Form,
  PrimaryButton,
  FieldTextInput,
  Button,
  FieldSelect,
} from '../../components';
import ReCAPTCHA from 'react-google-recaptcha';

import css from './GetToKnowUsForm.module.css';

export const GetToKnowUsForm = props => {
  const { readyFlag, progressFlag, initialFormState } = props;
  const characterID = useRef();
  const captchaSiteKey = process.env.REACT_APP_CAPTACHA_SITE_KEY;

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={initialFormState}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          GetKonwIamOptions,
          className,
          name,
          handleSubmit,
          form,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          filterConfig,
          invalid,
          currentId,
          images,
          onImageUpload,
          onRemoveImage,
          onUpdateImageOrder,
          updateDetailsTabPublicData,
          detailsTabData,
          intl,
          values,
          onChange,
          captachaRef,
        } = formRenderProps;

        const GetKonwIamOptionsList = GetKonwIamOptions.map((val, index) => {
          return (
            <option key={val.key} value={val.key}>
              {val.label}
            </option>
          );
        });

        const userLabel = intl.formatMessage({
          id: 'GetToknowUsPage.nameTitle',
        });

        const emailLabel = intl.formatMessage({
          id: 'GetToknowUsPage.emailTitle',
        });

        const phoneLabel = intl.formatMessage({
          id: 'GetToknowUsPage.phoneTitle',
        });

        const amILabel = intl.formatMessage({
          id: 'GetToknowUsPage.amITitle',
        });

        const messageLabel = intl.formatMessage({
          id: 'GetToknowUsPage.MessageTitle',
        });

        const namePlaceholder = intl.formatMessage({
          id: 'GetToknowUsPage.namePlaceholder',
        });

        const emailPlaceholder = intl.formatMessage({
          id: 'GetToknowUsPage.emailPlaceholder',
        });

        const phonePlaceholder = intl.formatMessage({
          id: 'GetToknowUsPage.phonePlaceholder',
        });

        const messagePlaceholder = intl.formatMessage({
          id: 'GetToknowUsPage.messagePlaceholder',
        });

        const selectamIRequiredMessage = intl.formatMessage({
          id: 'GetToknowUsPage.selectamIRequired',
        });

        const nameRequiredMessage = intl.formatMessage({
          id: 'GetToknowUsPage.nameRequired',
        });

        const selectOPtion = intl.formatMessage({
          id: 'GetToknowUsPage.selectOption',
        });

        const nameRequired = validators.required(nameRequiredMessage);

        const selectSubjectRequired = validators.required(selectamIRequiredMessage);

        const messageRequiredMessage = intl.formatMessage({
          id: 'GetToknowUsPage.messagetRequired',
        });

        const mesageRequired = validators.required(messageRequiredMessage);

        const emailRequiredMessage = intl.formatMessage({
          id: 'GetToknowUsPage.emailRequired',
        });

        const emailRequired = validators.emailFormatValid(emailRequiredMessage, values.userEmail);

        const phoneErrorMessage = intl.formatMessage({
          id: 'GetToknowUsPage.phoneErrorMsg',
        });

        const phoneValidator = validators.phoneFormatValid(phoneErrorMessage, values.userPhone);

        return (
          <Form onSubmit={handleSubmit} className={css.contactUsWrap}>
            <FieldTextInput
              id="userName"
              name="userName"
              className={css.emailField}
              type="text"
              label={userLabel}
              value={values.userName}
              validate={nameRequired}
              placeholder={namePlaceholder}
              impfield="true"
            />

            <div className={css.mailWrap}>
              <FieldTextInput
                id="userEmail"
                name="userEmail"
                className={css.emailField}
                type="text"
                label={emailLabel}
                value={values.userEmail}
                validate={emailRequired}
                placeholder={emailPlaceholder}
                impfield="true"
              />
              <FieldTextInput
                id="userPhone"
                name="userPhone"
                className={css.emailField}
                type="text"
                label={phoneLabel}
                value={values.userPhone}
                placeholder={phonePlaceholder}
                validate={phoneValidator}
              />
            </div>

            <FieldSelect
              className={css.subjectWrap}
              name="listItemforSubject"
              id="listItemforSubject"
              label={amILabel}
              impfield="true"
              validatorClass={css.validation}
              validate={selectSubjectRequired}
              value={values.listItemforSubject}
              // placeholder={subjectPlaceholder}
            >
              <option disabled value="">
                {selectOPtion}
              </option>
              {GetKonwIamOptionsList}
            </FieldSelect>

            <FieldTextInput
              className={css.messageField}
              name="messageField"
              id="messageField"
              type="textarea"
              label={messageLabel}
              placeholder={messagePlaceholder}
              value={values.messageField}
            />

            <ReCAPTCHA
              sitekey={captchaSiteKey}
              className={css.captacha}
              ref={captachaRef}
              name="recaptacha"
              theme='dark'
            />

            <div className={css.submitButton}>
              <Button type="submit" inProgress={progressFlag} disabled={invalid} ready={readyFlag}>
                <FormattedMessage id="GetToknowUsPage.sendBtnTitle" />
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default GetToKnowUsForm;
