import React from 'react';
import { bool } from 'prop-types';
import CapacityFilterPlain from './CapacityFilterPlain';
import CapacityFilterPopup from './CapacityFilterPopup';

const CapacityFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <CapacityFilterPopup {...rest} /> : <CapacityFilterPlain {...rest} />;
};

CapacityFilter.defaultProps = {
  showAsPopup: false,
};

CapacityFilter.propTypes = {
  showAsPopup: bool,
};

export default CapacityFilter;
