import React, { Component, useState } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import omit from 'lodash/omit';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify, getListingType, createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes, LISTING_STATE_DRAFT } from '../../util/types';
import { isAnyFilterActive } from '../../util/search';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
  ModalInMobile,
  FilterEvent,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { FilterComponent } from '../../containers';

import { ensureOwnListing } from '../../util/data';
import { SlMenu } from 'react-icons/sl';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import css from './Topbar.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const listingTab = (listing, selectedPageName) => {
  if (!listing) {
    return {
      text: <FormattedMessage id="UserNav.newListing" />,
      selected: selectedPageName === 'NewListingPage',
      linkProps: {
        name: 'NewListingPage',
      },
    };
  }
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', state } = currentListing.attributes;
  const slug = createSlug(title);
  const isDraft = state === LISTING_STATE_DRAFT;

  return {
    text: <FormattedMessage id="UserNav.editListing" />,
    // selected: selectedPageName === 'EditListingPage',
    selected: selectedPageName === 'ManageListingsPage',
    linkProps: {
      name: 'ManageListingsPage',
      params: {
        id,
        slug,
        type: getListingType(isDraft),
        tab: 'photos',
      },
    },
  };
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersOpenOnMobile: false,
      initialQueryParams: null,
      currentQueryParams: props.urlQueryParams,
      menuToggle: false,
      moounted: false,
    };
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);

    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    this.applyFilters = this.applyFilters.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.menuToggle = this.menuToggle.bind(this);
  }

  componentDidMount() {
    this.setState({ moounted: true });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters(pub_events = null) {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    let searchParams;
    if (!pub_events) {
      searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    } else {
      searchParams = { pub_events };
    }

    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal } = this.props;

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };
        //  console.log(this.props, prevState, updatedURLParams)
        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: { ...mergedQueryParams, ...updatedURLParams, address, bounds },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          let searchParams = this.state.currentQueryParams;
          const keysList = Object.keys(searchParams);
          keysList.forEach((val, index) => {
            if (val.indexOf('capacity') > -1) {
              let capacityVal = searchParams[val] ? searchParams[val].split(',') : null;
              let minVal = searchParams[val] ? capacityVal[0] : null;
              let maxVal = searchParams[val] ? capacityVal[1] : null;
              let pub_maximumcapacity = searchParams[val] ? minVal + ',' + maxVal : null;
              // console.log(pub_maximumcapacity);
              searchParams = { ...searchParams, pub_maximumcapacity };
            }
          });

          const searchParamsKey = Object.keys(searchParams);

          if (
            searchParams.bookingPrice === 'hourly' &&
            searchParamsKey.includes('pub_dailyprices')
          ) {
            if (searchParams.pub_dailyprices) {
              searchParams['price'] = searchParams['pub_dailyprices'];
              delete searchParams['pub_dailyprices'];
            }
          } else if (searchParams.bookingPrice === 'daily' && searchParamsKey.includes('price')) {
            if (searchParams.price) {
              searchParams['pub_dailyprices'] = searchParams['price'];
              delete searchParams['price'];
            }
          }

          if (searchParamsKey.includes('bookingPrice')) {
            delete searchParams['bookingPrice'];
          }

          // const maxParamns = { pub_minimumcapacity: '0,45', pub_maximumcapacity: '0,45' };
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  // handleSubmit(values) {
  //   const { currentSearchParams } = this.props;
  //   const { search, selectedPlace } = values.location;
  //   const { history } = this.props;
  //   const { origin, bounds } = selectedPlace;
  //   const originMaybe = config.sortSearchByDistance ? { origin } : {};
  //   const searchParams = {
  //     ...currentSearchParams,
  //     ...originMaybe,
  //     address: search,
  //     bounds,
  //   };
  //   history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  // }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const keywords = values.keywords;
    const { history } = this.props;
    const searchParams = {
      ...currentSearchParams,
      keywords,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
    // console.log(searchParams.keywords)
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'view_search_results',
        search_term: searchParams.keywords,
      });
    }
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  menuToggle() {
    let menuId = document.querySelector('#menuList');
    if (!this.state.menuToggle) {
      menuId.classList.add(`${css.activeMenu}`);
      this.setState({ menuToggle: !this.state.menuToggle });
    } else {
      menuId.classList.remove(`${css.activeMenu}`);
      this.setState({ menuToggle: !this.state.menuToggle });
    }
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserListing,
      currentUserListingFetched,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      selectedPageName,
      listing,
      showAsModalMaxWidth,
      filterConfig,
      urlQueryParams,
      searchParamsAreInSync,
      pagination,
      listings,
    } = this.props;

    const isHomePage = location.pathname === '/';

    const isAuthenticatedOrJustHydrated = isAuthenticated || !this.state.moounted;
    const authenticatedOnClientSide = this.state.moounted && isAuthenticated;
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    let resultsCount = 0;

    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: totalItems }
    );

    const tabs = listingTab(listing, selectedPageName);
    const searchPageBoolean =
      location.pathname.includes('/spaces');
    const nameLink = tabs.linkProps.name;
    const paramnsLink = tabs.linkProps.params;
    const { abbreviatedName } =
      currentUser && currentUser.attributes && currentUser.attributes.profile
        ? currentUser.attributes.profile
        : [];

    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
      />
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const classes = classNames(rootClassName || css.root, className);

    const spacesLink = (
      <li>
        <NamedLink name="SearchPage">
          <span className={css.inbox}>
            <FormattedMessage id="TopbarDesktop.showListing" />
          </span>
        </NamedLink>
      </li>
    );

    const signupLink = isAuthenticatedOrJustHydrated ? null : (
      <li>
        <NamedLink name="SignupPage" className={css.signupLink}>
          <span className={css.signup}>
            <FormattedMessage id="TopbarDesktop.signup" />
          </span>
        </NamedLink>
      </li>
    );

    const loginLink = isAuthenticatedOrJustHydrated ? null : (
      <li>
        <NamedLink name="LoginPage" className={css.inboxLink}>
          <span className={css.inbox}>
            <FormattedMessage id="TopbarDesktop.login" />
          </span>
        </NamedLink>
      </li>
    );

    const createListingLink = (
      <li>
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
      </li>
    );

    const favoritesLink = authenticatedOnClientSide ? (
      <li>
        <NamedLink className={css.inboxLink} name="MyWishListPage">
          <span className={css.inbox}>
            <FormattedMessage id="TopbarDesktop.favoritesLink" />
          </span>
        </NamedLink>
      </li>
    ) : null;

    const inboxLink = authenticatedOnClientSide ? (
      <li>
        <NamedLink
          className={css.inboxLink}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <span className={css.inbox}>
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationDot}
          </span>
        </NamedLink>
      </li>
    ) : null;
    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          {/* <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button> */}
          <NamedLink
            className={css.home}
            name="LandingPage"
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
          >
            <Logo format="mobile"  alt="Swivospace"/>
          </NamedLink>

          {isHomePage && (
            <div className={css.humburgerWrap}>
              <SlMenu size={30} className={css.menuHumberger} onClick={this.menuToggle} />

              <div className={css.menuListWrap} id="menuList">
                <ul>
                  {spacesLink}
                  {createListingLink}
                  {favoritesLink}
                  {inboxLink}
                  {loginLink}
                  {signupLink}
                </ul>
              </div>
            </div>
          )}
          {!isHomePage && (
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              {/* <SearchIcon className={css.searchMenuIcon} /> */}
              <img src={'/static/icons/search-dark.png'} className={css.searchMenuIcon} alt={intl.formatMessage({ id: 'Topbar.searchIcon' })} width={16} height={15}/>
            </Button>
          )}

          {/* {!listing ? ( */}

          {paramnsLink ? (
            <>
              <NamedLink className={css.addHrefIcon} name={nameLink} params={paramnsLink}>
                <Button
                  className={css.addSpace}
                  // onClick={this.handleMobileSearchOpen}
                  title={intl.formatMessage({ id: 'Topbar.addIcon' })}
                >
                  {/* <SearchIcon className={css.searchMenuIcon} /> */}
                  <img src={'/static/icons/plus_icon.svg'} className={css.addMenuIcon} alt={intl.formatMessage({ id: 'Topbar.addIcon' })} width={16} height={16}/>
                </Button>
              </NamedLink>
            </>
          ) : (
            <>
              {!isHomePage && (
                <NamedLink className={css.addHrefIcon} name={nameLink}>
                  <Button
                    className={css.addSpace}
                    // onClick={this.handleMobileSearchOpen}
                    title={intl.formatMessage({ id: 'Topbar.addIcon' })}
                  >
                    {/* <SearchIcon className={css.searchMenuIcon} /> */}
                    <img src={'/static/icons/plus_icon.svg'} className={css.addMenuIcon} alt={intl.formatMessage({ id: 'Topbar.addIcon' })} width={16} height={16}/>
                  </Button>
                </NamedLink>
              )}
            </>
          )}

          {searchPageBoolean ? (
            <>
              <Button
                className={`${css.addSpace} ${css.filterIcon}`}
                title={intl.formatMessage({ id: 'Topbar.addIcon' })}
                onClick={this.openFilters}
              >
                <img src={'/static/icons/filtericon.png'} className={css.addMenuIcon} alt={intl.formatMessage({ id: 'Topbar.addIcon' })} width={16} height={16}/>
              </Button>

              <ModalInMobile
                id="SearchFiltersMobile.filters"
                isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
                onClose={this.cancelFilters}
                showAsModalMaxWidth={showAsModalMaxWidth}
                onManageDisableScrolling={onManageDisableScrolling}
                containerClassName={css.modalContainer}
                closeButtonMessage={modalCloseButtonMessage}
              >
                <div className={`${css.textCenter} ${css.modalHeadingWrapper}`}>
                  <span className={`${css.modalHeading}`}>{filtersHeading}</span>
                  <button className={css.resetAllButton} onClick={e => this.resetAll(e)}>
                    <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                  </button>
                </div>

                {this.state.isFiltersOpenOnMobile ? (
                  <div className={css.filtersWrapper}>
                    {filterConfig.map(config => {
                      return (
                        <FilterComponent
                          key={`SearchFiltersMobile.${config.id}`}
                          idPrefix="SearchFiltersMobile"
                          filterConfig={config}
                          urlQueryParams={urlQueryParams}
                          initialValues={this.initialValues}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          liveEdit
                          showAsPopup={false}
                          locationParmsValue={location.search}
                          resetAll={this.resetAll}
                        />
                      );
                    })}
                  </div>
                ) : null}

                <div className={css.showListingsContainer}>
                  <Button className={css.showListingsButton} onClick={this.closeFilters}>
                    {showListingsLabel}
                  </Button>
                </div>
              </ModalInMobile>
            </>
          ) : (
            ''
          )}

          {abbreviatedName ? (
            <>
              <Button
                className={css.userMenu}
                onClick={this.handleMobileMenuOpen}
                title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
              >
                <p className={css.userIcon}>{abbreviatedName}</p>
              </Button>
            </>
          ) : (
            ''
          )}

          {/* {tabs} */}

          {/* <NamedLink name="NewListingPage">test</NamedLink> */}
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUserListing={currentUserListing}
            currentUserListingFetched={currentUserListingFetched}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              id="keywordsearchmobile"
              isMobile
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
