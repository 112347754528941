/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 1,
      max: 1000,
      step: 1,
    },
  },

  {
    id: 'dailyprices',
    label: 'dailyprices',

    type: 'dailyfilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_dailyprices'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      schemaType: 'long',
      min: 1,
      max: 25000,
      step: 1,
    },
  },

  {
    id: 'minimumcapacity',
    label: 'Capacity',
    type: 'CapacityFilter',
    group: 'primary',
    queryParamNames: ['pub_minimumcapacity'],
    config: {
      schemaType: 'long',
      min: 1,
      max: 1000,
      step: 1,
    },
  },

  {
    id: 'maximumcapacity',
    label: 'Capcity',
    // type: 'CapacityFilter',
    // group: 'primary',
    queryParamNames: ['pub_maximumcapacity'],
    config: {
      schemaType: 'long',
      min: 1,
      max: 1000,
      step: 1,
    },
  },

  {
    id: 'averagerating',
    label: 'Rating',
    type: 'RatingFilter',
    group: 'primary',
    queryParamNames: ['keywords'],
    config: {
      schemaType: 'text',
      searchMode: 'has_all',
      options: [
        { key: '1', label: 1 },
        { key: '2', label: 2 },
        { key: '3', label: 3 },
        { key: '4', label: 4 },
        { key: '5', label: 5 },
      ],
    },
  },

  // {
  //   id: 'ratings',
  //   label: 'ratings',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['pub_averageRating'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: { schemaType: 'text', searchMode: 'has_all'},
  // },

  {
    id: 'keyword',
    label: 'Keyword',
    // type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_all',
    },
  },

  {
    id: 'tagKeyword',
    label: 'tagKeyword',
    // type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: { schemaType: 'text', searchMode: 'has_all' },
  },
  {
    id: 'cancellationPolicyOptions',
    label: 'Cancellation Policy',
    group: 'secondary',
    queryParamNames: ['pub_cancellationpolicyoptions'],
    config: {
      options: [
        {
          key: '24_hours_after_booking',
          label: '24 hours after booking',
        },
        {
          key: '48_hours_after_booking',
          label: '48 hours after booking',
        },
        {
          key: '1_day_before_start_of_event',
          label: '1 day before start of event',
        },
        {
          key: '3_days_before_start_of_event',
          label: '3 days before start of event',
        },
        {
          key: '5_days_before_start_of_event',
          label: '5 days before start of event',
        },
        {
          key: '7_days_before_start_of_event',
          label: '7 days before start of event',
        },
      ],
    },
  },
  {
    id: 'neighbourhood',
    label: 'Neighborhood',
    group: 'secondary',
    queryParamNames: ['pub_neighbourhood'],
    config: {
      options: [
        {
          key: 'all_downtown',
          label: 'ALL DOWNTOWN',
          subcategory: [
            { key: 'battery_park_city', label: 'Battery Park City' },
            { key: 'chelsea', label: 'Chelsea' },
            { key: 'west_chelsea', label: 'West Chelsea' },
            { key: 'chinatown', label: 'Chinatown' },
            { key: 'civic_center', label: 'Civic Center' },
            { key: 'east_village', label: 'East Village' },
            { key: 'financial_district', label: 'Financial District' },
            { key: 'fulton_seaport', label: 'Fulton/Seaport' },
            { key: 'flatiron', label: 'Flatiron' },
            { key: 'noMad', label: 'NoMad' },
            { key: 'gramercy_park', label: 'Gramercy Park' },
            { key: 'greenwich_village', label: 'Greenwich Village' },
            { key: 'noho', label: 'Noho' },
            { key: 'little_italy', label: 'Little Italy' },
            { key: 'lower_east_side', label: 'Lower East Side' },
            { key: 'two_bridges', label: 'Two Bridges' },
            { key: 'soho', label: 'Soho' },
            { key: 'hudson_square', label: 'Hudson Square' },
            { key: 'stuyvesant_town_pcv', label: 'Stuyvesant Town/PCV' },
            { key: 'tribeca', label: 'Tribeca' },
            { key: 'west_village', label: 'West Village' },
          ],
        },
        {
          key: 'all_midtown',
          label: 'ALL MIDTOWN',
          subcategory: [
            { key: 'central_park_south', label: 'Central Park South' },
            { key: 'midtown', label: 'Midtown' },
            { key: 'midtown_east', label: 'Midtown East' },
            { key: 'kips_bay', label: 'Kips Bay' },
            { key: 'murray_hill', label: 'Murray Hill' },
            { key: 'sutton_place', label: 'Sutton Place' },
            { key: 'turtle_bay', label: 'Turtle Bay' },
            { key: 'beekman', label: 'Beekman' },
            { key: 'midtown_south', label: 'Midtown South' },
            { key: 'midtown_west', label: 'Midtown West' },
            { key: 'hells_kitchen', label: 'Hells Kitchen' },
            { key: 'hudson_yards', label: 'Hudson Yards' },
          ],
        },
        {
          key: 'all_upper_east_side',
          label: 'ALL UPPER EAST SIDE',
          subcategory: [
            { key: 'upper_east_side', label: 'Upper East Side' },
            { key: 'carnegie_hill', label: 'Carnegie Hill' },
            { key: 'lenox_hill', label: 'Lenox Hill' },
            { key: 'upper_carnegie_hill', label: 'Upper Carnegie Hill' },
            { key: 'Yorkville', label: 'Yorkville' },
          ],
        },
        {
          key: 'all_upper_manhattan',
          label: 'ALL UPPER MANHATTAN',
          subcategory: [
            { key: 'central_harlem', label: 'Central Harlem' },
            { key: 'south_harlem', label: 'South Harlem' },
            { key: 'east_harlem', label: 'East Harlem' },
            { key: 'hamilton_heights', label: 'Hamilton Heights' },
            { key: 'inwood', label: 'Inwood' },
            { key: 'marble_hill', label: 'Marble Hill' },
            { key: 'morningside_heights', label: 'Morningside Heights' },
            { key: 'washington_heights', label: 'Washington Heights' },
            { key: 'fort_george', label: 'Fort George' },
            { key: 'hudson_heights', label: 'Hudson Heights' },
            { key: 'west_harlem', label: 'West Harlem' },
            { key: 'manhattanville', label: 'Manhattanville' },
          ],
        },
        {
          key: 'all_upper_west_side',
          label: 'ALL UPPER WEST SIDE',
          subcategory: [
            { key: 'upper_west_side', label: 'Upper West Side' },
            { key: 'lincoln_square', label: 'Lincoln Square' },
            { key: 'manhattan_valley', label: 'Manhattan Valley' },
          ],
        },
        {
          key: 'other_neighbourhood',
          label: 'OTHER',
          subcategory: [
            { key: 'bronx', label: 'Bronx' },
            { key: 'brooklyn', label: 'Brooklyn' },
            { key: 'long_island', label: 'Long Island' },
            { key: 'queens', label: 'Queens' },
            { key: 'staten_island', label: 'Staten Island' },
            { key: 'west_new_york_nj', label: 'West New York/NJ' },
            { key: 'westchester', label: 'Westchester' },
            { key: 'other', label: 'Other' },
          ],
        },
      ],
    },
  },

  {
    id: 'additionalTags',
    label: 'additionalTags',
    // type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: { schemaType: 'text', searchMode: 'has_all' },
  },

  {
    // id: 'certificate',
    // label: 'Certificate',
    // type: 'SelectSingleFilter',
    id: 'category',
    label: 'Category',
    group: 'secondary',
    // queryParamNames: ['pub_certificate'],
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // {
        //   key: 'none',
        //   label: 'Choose the type of venue...',
        //   hideFromFilters: true,
        //   hideFromListingInfo: true,
        // },
        // { key: '200h', label: 'Registered yoga teacher 200h' },
        // { key: '500h', label: 'Registered yoga teacher 500h' },
        {
          key: 'Residential',
          label: 'Residential',
          subcategory: [
            { key: 'apartment', label: 'Apartment' },
            { key: 'house', label: 'House' },
            { key: 'mobile_home', label: 'Mobile Home' },
          ],
        },
        { key: 'Office_space', label: 'Office Space' },
        { key: 'Retail_space', label: 'Retail Space' },
        { key: 'Bar_Lounge_Restaurant', label: 'Bar/Lounge/ Restaurant' },
        { key: 'Garden', label: 'Garden' },
        { key: 'Amphitheatre', label: 'Amphitheatre' },
        { key: 'Gym', label: 'Gym' },
        { key: 'Other', label: 'Other' },
      ],
    },
  },

  {
    id: 'hoursOptions',
    label: 'hoursOptions',
    group: 'secondary',
    queryParamNames: ['pub_hoursOptions'],
    config: {
      options: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6', label: '6' },
        { key: '7', label: '7' },
        { key: '8', label: '8' },
        { key: '9', label: '9' },
        { key: '10', label: '10' },
        { key: '11', label: '11' },
        { key: '12', label: '12' },
        { key: '13', label: '13' },
        { key: '14', label: '14' },
        { key: '15', label: '15' },
        { key: '16', label: '16' },
        { key: '17', label: '17' },
        { key: '18', label: '18' },
        { key: '19', label: '19' },
        { key: '20', label: '20' },
        { key: '21', label: '21' },
        { key: '22', label: '22' },
        { key: '23', label: '23' },
        { key: '24', label: '24' },
      ],
    },
  },

  {
    id: 'subjectOptions',
    label: 'subjectOptions',
    // group: 'secondary',
    queryParamNames: ['pub_subjectOptions'],
    config: {
      options: [
        { key: 'question_listing', label: 'Questions about listing my space' },
        { key: 'question_space', label: 'Questions about booking a space' },
        { key: 'question_upEvent', label: 'Questions about an upcoming event' },
        { key: 'question_pastEvent', label: 'Questions about a past event' },
        { key: 'issues_account', label: 'Issues with my account' },
        { key: 'issues_payment', label: 'Issues with sending/receiving payment' },
        { key: 'issues_other', label: 'Other' },
      ],
    },
  },

  {
    id: 'GetKonwIamOptions',
    label: 'GetKonwIamOptions',
    // group: 'secondary',
    queryParamNames: ['pub_GetKonwIamOptions'],
    config: {
      options: [
        { key: 'venue_provider', label: 'Venue Provider' },
        { key: 'event_organizer', label: 'Event Organizer' },
        { key: 'creator', label: 'Creator (Photoshoots, Videos, etc)' },
        { key: 'serviece_provider', label: 'Service Provider (Photographer, DJ, etc)' },
      ],
    },
  },

  {
    id: 'maxHoursOptions',
    label: 'maxHoursOptions',
    group: 'secondary',
    queryParamNames: ['pub_maxHoursOptions'],
    config: {
      options: [
        { key: '1', label: '1' },
        { key: '2', label: '2' },
        { key: '3', label: '3' },
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6', label: '6' },
        { key: '7', label: '7' },
        { key: '8', label: '8' },
        { key: '9', label: '9' },
        { key: '10', label: '10' },
        { key: '11', label: '11' },
        { key: '12', label: '12' },
        { key: '13', label: '13' },
        { key: '14', label: '14' },
        { key: '15', label: '15' },
        { key: '16', label: '16' },
        { key: '17', label: '17' },
        { key: '18', label: '18' },
        { key: '19', label: '19' },
        { key: '20', label: '20' },
        { key: '21', label: '21' },
        { key: '22', label: '22' },
        { key: '23', label: '23' },
        { key: '24', label: '24' },
      ],
    },
  },

  // {
  //   // id: 'certificate',
  //   // label: 'Certificate',
  //   // type: 'SelectSingleFilter',
  //   id: 'propertyType',
  //   label: 'propertyType',
  //   group: 'secondary',
  //   // queryParamNames: ['pub_certificate'],
  //   queryParamNames: ['pub_propertyType'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'Indoor_space', label: 'Indoor' },
  //       { key: 'Outdoor_space', label: 'Outdoor' },
  //     ],
  //   },
  // },
  {
    id: 'events',
    label: 'Events',
    // group: 'secondary',
    // type: 'SelectMultipleFilter',
    queryParamNames: ['pub_events'],
    config: {
      schemaType: 'enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // type: 'multi-enum'
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'all_events',
          label: 'All \n Events',
          imgSrc: 'all_icon.svg',
          idName: 'allamenities',
          metaTitle: 'SearchPage.allEventTitle',
          metaDescription: 'SearchPage.allEventsDescription',
        },
        {
          key: 'meeting',
          label: 'Meetings',
          imgSrc: 'Meetings.png',
          idName: 'meetingamenities',
          metaTitle: 'SearchPage.meetingTitle',
          metaDescription: 'SearchPage.meetingDescription',
        },
        {
          key: 'corporate_events',
          label: 'Corporate \n Events',
          imgSrc: 'Corporate-Events.png',
          idName: 'corporateamenities',
          metaTitle: 'SearchPage.corporateTitle',
          metaDescription: 'SearchPage.corporateDescription',
        },
        {
          key: 'cooking_food_events',
          label: 'Cooking/Food\n Events',
          imgSrc: 'Cooking-Food-Events.png',
          idName: 'cookingamenities',
          metaTitle: 'SearchPage.cookingTitle',
          metaDescription: 'SearchPage.cookingDescription',
        },
        {
          key: 'video_shoots',
          label: 'Video \n Shoots',
          imgSrc: 'Video-Shoots.png',
          idName: 'videoamenities',
          metaTitle: 'SearchPage.videoTitle',
          metaDescription: 'SearchPage.videoDescription',
        },
        {
          key: 'photo_shoots',
          label: 'Photo \n Shoots',
          imgSrc: 'Photo-Shoots.png',
          idName: 'photoamenities',
          metaTitle: 'SearchPage.photoTitle',
          metaDescription: 'SearchPage.photoDescription',
        },
        {
          key: 'parties_celebration',
          label: 'Parties / \n Celebration',
          imgSrc: 'Parties-Celebrations.png',
          idName: 'partiesamenities',
          metaTitle: 'SearchPage.partiesTitle',
          metaDescription: 'SearchPage.partiesDescription',
        },
        {
          key: 'wedding',
          label: 'Weddings',
          imgSrc: 'Weddings.png',
          idName: 'weddingamenities',
          metaTitle: 'SearchPage.weddingTitle',
          metaDescription: 'SearchPage.weddingDescription',
        },
        {
          key: 'performance',
          label: 'Performances/ \n Shows',
          imgSrc: 'Performances-Shows.png',
          idName: 'performanceamenities',
          metaTitle: 'SearchPage.performanceTitle',
          metaDescription: 'SearchPage.performanceDescription',
        },
        {
          key: 'cocktail',
          label: 'Cocktail \n Hours',
          imgSrc: 'Cocktail-Hour.png',
          idName: 'cocktailamenities',
          metaTitle: 'SearchPage.cocktailTitle',
          metaDescription: 'SearchPage.cocktailDescription',
        },
        {
          key: 'workshop',
          label: 'Workshops / \n Classes',
          imgSrc: 'Workshops-Classes.png',
          idName: 'workshopamenities',
          metaTitle: 'SearchPage.workshopTitle',
          metaDescription: 'SearchPage.workshopDescription',
        },
        {
          key: 'retail_popups',
          label: 'Retail \n Popups',
          imgSrc: 'Retail-Popups.png',
          idName: 'retailamenities',
          metaTitle: 'SearchPage.retailTitle',
          metaDescription: 'SearchPage.retailDescription',
        },
        {
          key: 'audio_recordings',
          label: 'Audio \n Recordings',
          imgSrc: 'Audio-Recordings.png',
          idName: 'audioamenities',
          metaTitle: 'SearchPage.recordingTitle',
          metaDescription: 'SearchPage.recordingDescription',
        },

        // { key: 'birthday_parties', label: 'Birthday parties', imgSrc: 'birthday_parties.png' },
        // { key: 'cooking_events', label: 'Cooking events', imgSrc: 'cooking_events.png' },
        // { key: 'video_shoots', label: 'Video Shoots', imgSrc: 'video_shoots.png' },
        // { key: 'photo_shoots', label: 'Photo Shoots', imgSrc: 'photoshoot.png' },
        // { key: 'adult_filming', label: 'Adult filming', imgSrc: 'adult_fliming.png' },
        // { key: 'kids_parties', label: 'Kids parties', imgSrc: 'kids_parties.png' },
        // { key: 'pet_parties', label: 'Pet parties', imgSrc: 'pet_parties.png' },
      ],
    },
  },

  {
    id: 'generalamenities',
    label: 'General Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_generalamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'wifi',
          label: 'Wifi',
          eventName: ['general'],
        },
        {
          key: 'restrooms',
          label: 'Restrooms',
          eventName: ['general'],
        },
        {
          key: 'parking',
          label: 'Parking',
          eventName: ['general'],
        },
        {
          key: 'heating_and_air_conditioning',
          label: 'Heating and Air Conditioning',
          eventName: ['general'],
        },
        {
          key: 'elevators',
          label: 'Elevators',
          eventName: ['general'],
        },
        {
          key: 'wheelchair_accessibility',
          label: 'Wheelchair Accessibility',
          eventName: ['general'],
        },
        {
          key: 'proximity_to_public_transportation',
          label: 'Proximity to Public Transportation',
          eventName: ['general'],
        },
        {
          key: 'allows_alcohol',
          label: 'Allows Alcohol',
          eventName: ['general'],
        },
        {
          key: 'outdoor_space',
          label: 'Outdoor Space',
          eventName: ['general'],
        },
        {
          key: 'views',
          label: 'Views',
          eventName: ['general'],
        },
        {
          key: 'Open_flames_allowed',
          label: 'Open Flames Allowed',
          eventName: ['general'],
        },
        {
          key: 'none_generalAmenities',
          label: 'None',
          eventName: ['general'],
        },
      ],
    },
  },

  {
    id: 'additionalamenities',
    label: 'Additional Amenities',
    group: 'secondary',
    queryParamNames: ['pub_additionalamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'conference_table',
          label: 'Conference Table',
          eventName: ['meeting', 'corporate_events', 'workshop'],
        },
        {
          key: 'chairs',
          label: 'Chairs',
          eventName: [
            'meeting',
            'corporate_events',
            'parties_celebration',
            'cooking_food_events',
            'wedding',
            'performance',
            'cocktail',
            'workshop',
          ],
        },
        {
          key: 'pantry',
          label: 'Pantry',
          eventName: ['meeting', 'corporate_events', 'workshop'],
        },

        {
          key: 'breakout_rooms',
          label: 'Breakout Rooms',
          eventName: ['meeting', 'corporate_events', 'workshop'],
        },

        {
          key: 'projectors',
          label: 'Projectors',
          eventName: [
            'meeting',
            'corporate_events',
            'parties_celebration',
            'cooking_food_events',
            'wedding',
            'performance',
            'cocktail',
            'workshop',
          ],
        },

        {
          key: 'sound_system',
          label: 'Sound System',
          eventName: [
            'meeting',
            'corporate_events',
            'parties_celebration',
            'wedding',
            'performance',
            'cocktail',
            'workshop',
            'retail_popups',
          ],
        },

        {
          key: 'microphone',
          label: 'Microphone',
          eventName: [
            'meeting',
            'corporate_events',
            'parties_celebration',
            'wedding',
            'performance',
            'cocktail',
            'workshop',
            'audio_recordings',
          ],
        },

        {
          key: 'podium',
          label: 'Podium',
          eventName: [
            'meeting',
            'corporate_events',
            'parties_celebration',
            'wedding',
            'performance',
            'workshop',
          ],
        },

        {
          key: 'stage',
          label: 'Stage',
          eventName: [
            'meeting',
            'corporate_events',
            'parties_celebration',
            'cooking_food_events',
            'wedding',
            'performance',
            'workshop',
          ],
        },

        {
          key: 'lighting_system',
          label: 'Lighting System',
          eventName: [
            'meeting',
            'corporate_events',
            'parties_celebration',
            'cooking_food_events',
            'wedding',
            'performance',
            'cocktail',
            'workshop',
            'retail_popups',
          ],
        },

        {
          key: 'whiteboards',
          label: 'Whiteboards',
          eventName: ['meeting', 'corporate_events', 'workshop'],
        },

        {
          key: 'televisions',
          label: 'Televisions',
          eventName: ['meeting', 'corporate_events, parties_celebration'],
        },

        {
          key: 'video_conference_system',
          label: 'Video conference system',
          eventName: ['meeting', 'corporate_events', 'workshop'],
        },

        {
          key: 'printers',
          label: 'Printers',
          eventName: ['meeting', 'corporate_events', 'workshop'],
        },

        {
          key: 'catering_services',
          label: 'Catering Services',
          eventName: ['meeting', 'corporate_events', 'parties_celebration', 'workshop'],
        },
        {
          key: 'tables',
          label: 'Tables',
          eventName: [
            'parties_celebration',
            'cooking_food_events',
            'wedding',
            'cocktail',
            'workshop',
            'retail_popups',
          ],
        },
        {
          key: 'dishware',
          label: 'Dishware',
          eventName: ['parties_celebration', 'cooking_food_events', 'wedding'],
        },
        {
          key: 'reception_area',
          label: 'Reception area',
          eventName: ['parties_celebration', 'cocktail', 'wedding'],
        },

        {
          key: 'valet',
          label: 'Valet',
          eventName: ['parties_celebration', 'wedding', 'cocktail'],
        },

        {
          key: 'dance_floor',
          label: 'Dance floor',
          eventName: ['parties_celebration', 'wedding', 'cocktail'],
        },

        {
          key: 'gas_stoves',
          label: 'Gas Stoves',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'electric_stoves',
          label: 'Electric Stoves',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'refrigerators',
          label: 'Refrigerators',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'freezers',
          label: 'Freezers',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'warmers',
          label: 'Warmers',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'cookware',
          label: 'Cookware',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'ventilation_system',
          label: 'Ventilation System',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'trash_bins',
          label: 'Trash bins',
          eventName: ['cooking_food_events'],
        },

        {
          key: 'umbrella_lighting',
          label: 'Umbrella Lighting',
          eventName: ['video_shoots', 'photo_shoots'],
        },

        {
          key: 'ring_lighting',
          label: 'Ring Lighting',
          eventName: ['video_shoots', 'photo_shoots'],
        },

        {
          key: 'dressing_rooms',
          label: 'Dressing Rooms',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'green_rooms',
          label: 'Green Rooms',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'makeup_area',
          label: 'Makeup Area',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'boom_microphones',
          label: 'Boom Microphones',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'cyc_wall',
          label: 'Cyc Wall',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'backdrops',
          label: 'Backdrops',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'soundproof',
          label: 'Soundproof',
          eventName: ['video_shoots', 'photo_shoots', 'performance', 'audio_recordings'],
        },

        {
          key: 'fog_machine',
          label: 'Fog Machine',
          eventName: ['video_shoots'],
        },

        {
          key: 'natural_lighting',
          label: 'Natural Lighting',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'tripods',
          label: 'Tripods',
          eventName: ['video_shoots', 'photo_shoots', 'performance'],
        },

        {
          key: 'bar',
          label: 'Bar',
          eventName: ['wedding', 'cocktail'],
        },

        {
          key: 'established_liquor_license',
          label: 'Established Liquor License',
          eventName: ['wedding', 'cocktail'],
        },

        {
          key: 'table_linens',
          label: 'Table Linens',
          eventName: ['wedding'],
        },

        {
          key: 'bridal_suite',
          label: 'Bridal Suite',
          eventName: ['wedding'],
        },

        {
          key: 'groom_suite',
          label: 'Groom suite',
          eventName: ['wedding'],
        },

        {
          key: 'photobooth',
          label: 'Photobooth',
          eventName: ['wedding'],
        },

        {
          key: 'kitchen_facilities',
          label: 'Kitchen Facilities',
          eventName: ['wedding', 'cocktail'],
        },

        {
          key: 'TV_screens',
          label: 'TV Screens',
          eventName: ['wedding', 'cocktail'],
        },

        {
          key: 'catering_services',
          label: 'Catering Services',
          eventName: ['wedding'],
        },

        {
          key: 'cocktail_area',
          label: 'Cocktail area',
          eventName: ['wedding'],
        },

        {
          key: 'tents',
          label: 'Tents',
          eventName: ['wedding', 'cocktail'],
        },

        {
          key: 'auditorium_seating',
          label: 'Auditorium Seating',
          eventName: ['performance'],
        },

        {
          key: 'stage_curtain',
          label: 'Stage Curtain',
          eventName: ['performance'],
        },

        {
          key: 'car_accessible',
          label: 'Car Accessible',
          eventName: ['performance'],
        },

        {
          key: 'DJ_booth',
          label: 'DJ Booth',
          eventName: ['performance'],
        },

        {
          key: 'box_office',
          label: 'Box Office',
          eventName: ['performance'],
        },

        {
          key: 'high_tops',
          label: 'High Tops',
          eventName: ['cocktail'],
        },

        {
          key: 'barware',
          label: 'Barware',
          eventName: ['cocktail'],
        },

        {
          key: 'individual_power_outlets',
          label: 'Individual Power Outlets',
          eventName: ['workshop'],
        },

        {
          key: 'television_screens',
          label: 'Television Screens',
          eventName: ['workshop'],
        },

        {
          key: 'display_cases',
          label: 'Display Cases',
          eventName: ['retail_popups'],
        },

        {
          key: 'clothing_racks',
          label: 'Clothing Racks',
          eventName: ['retail_popups'],
        },

        {
          key: 'signage_area',
          label: 'Signage Area',
          eventName: ['retail_popups'],
        },

        {
          key: 'display_windows_frontage',
          label: 'Display windows/Frontage',
          eventName: ['retail_popups'],
        },

        {
          key: 'ADA_accessible',
          label: 'ADA Accessible',
          eventName: ['retail_popups'],
        },

        {
          key: 'frontage_lineal',
          label: 'Frontage (20+ lineal feet)',
          eventName: ['retail_popups'],
        },

        {
          key: 'storage',
          label: 'Storage',
          eventName: ['retail_popups'],
        },

        {
          key: 'fitting_rooms',
          label: 'Fitting rooms',
          eventName: ['retail_popups'],
        },

        {
          key: 'mirrors',
          label: 'Mirrors',
          eventName: ['retail_popups'],
        },

        {
          key: 'shelving',
          label: 'Shelving',
          eventName: ['retail_popups'],
        },

        {
          key: 'sound_booth',
          label: 'Sound Booth',
          eventName: ['audio_recordings'],
        },

        {
          key: 'mixing_equipment',
          label: 'Mixing Equipment',
          eventName: ['audio_recordings'],
        },

        {
          key: 'studio_monitors',
          label: 'Studio Monitors',
          eventName: ['audio_recordings'],
        },

        {
          key: 'headphones',
          label: 'Headphones',
          eventName: ['audio_recordings'],
        },

        {
          key: 'furnished',
          label: 'furnished',
          eventName: ['audio_recordings'],
        },
      ],
    },
  },

  {
    id: 'meetingamenities',
    label: 'Meeting Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_meetingamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'meeting',
          label: 'Meetings',
          idName: 'meetingamenities',
          amenitiesList: [
            {
              key: 'meetings_conference_table',
              label: 'Conference Table',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_chairs',
              label: 'Chairs',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_pantry',
              label: 'Pantry',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_breakout_rooms',
              label: 'Breakout Rooms',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_projectors',
              label: 'Projectors',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_sound_system',
              label: 'Sound System',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_microphone',
              label: 'Microphone',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_podium',
              label: 'Podium',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_stage',
              label: 'Stage',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_lighting_system',
              label: 'Lighting System',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_whiteboards',
              label: 'Whiteboards',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_televisions',
              label: 'Televisions',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_video_conference_system',
              label: 'Video conference system',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_printers',
              label: 'Printers',
              eventName: ['meetings'],
            },

            {
              key: 'meetings_catering_services',
              label: 'Catering Services',
              eventName: ['meetings'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'corporateamenities',
    label: 'Corporate Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_corporateamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'corporate_events',
          label: 'Corporate Events',
          idName: 'corporateamenities',
          amenitiesList: [
            {
              key: 'corporate_conference_table',
              label: 'Conference Table',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_chairs',
              label: 'Chairs',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_pantry',
              label: 'Pantry',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_breakout_rooms',
              label: 'Breakout Rooms',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_projectors',
              label: 'Projectors',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_sound_system',
              label: 'Sound System',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_microphone',
              label: 'Microphone',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_podium',
              label: 'Podium',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_stage',
              label: 'Stage',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_lighting_system',
              label: 'Lighting System',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_whiteboards',
              label: 'Whiteboards',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_televisions',
              label: 'Televisions',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_video_conference_system',
              label: 'Video conference system',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_printers',
              label: 'Printers',
              eventName: ['corporate_events'],
            },

            {
              key: 'corporate_catering_services',
              label: 'Catering Services',
              eventName: ['corporate_events'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'partiesamenities',
    label: 'Parties Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_partiesamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'parties_celebration',
          label: 'Parties / Celebrations',
          idName: 'partiesamenities',
          amenitiesList: [
            {
              key: 'parties_tables',
              label: 'Tables',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_chairs',
              label: 'Chairs',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_kitchen_facilities',
              label: 'Kitchen Facilities',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_dishware',
              label: 'Dishware',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_projectors',
              label: 'Projectors',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_sound_system',
              label: 'Sound System',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_microphone',
              label: 'Microphone',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_podium',
              label: 'Podium',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_stage',
              label: 'Stage',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_lighting_system',
              label: 'Lighting System',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_televisions',
              label: 'Televisions',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_catering_services',
              label: 'Catering Services',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_reception_area',
              label: 'Reception area',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_valet',
              label: 'Valet',
              eventName: ['parties_celebration'],
            },

            {
              key: 'parties_dance_floor',
              label: 'Dance floor',
              eventName: ['parties_celebration'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'cookingamenities',
    label: 'Cooking Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_cookingamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'cooking_food_events',
          label: 'Cooking/Food Events',
          idName: 'cookingamenities',
          amenitiesList: [
            {
              key: 'cooking_tables',
              label: 'Tables',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_chairs',
              label: 'Chairs',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_gas_stoves',
              label: 'Gas Stoves',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_electric_stoves',
              label: 'Electric Stoves',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_refrigerators',
              label: 'Refrigerators',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_freezers',
              label: 'Freezers',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_warmers',
              label: 'Warmers',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_dishware',
              label: 'Dishware',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_cookware',
              label: 'Cookware',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_ventilation_system',
              label: 'Ventilation System',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_trash_bins',
              label: 'Trash bins',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_projectors',
              label: 'Projectors',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_stage',
              label: 'Stage',
              eventName: ['cooking_food_events'],
            },

            {
              key: 'cooking_lighting_system',
              label: 'Lighting System',
              eventName: ['cooking_food_events'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'videoamenities',
    label: 'Video Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_videoamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'video_shoots',
          label: 'Video Shoots',
          idName: 'videoamenities',
          amenitiesList: [
            {
              key: 'video_umbrella_lighting',
              label: 'Umbrella Lighting',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_ring_lighting',
              label: 'Ring Lighting',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_dressing_rooms',
              label: 'Dressing Rooms',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_green_rooms',
              label: 'Green Rooms',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_makeup_area',
              label: 'Makeup Area',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_boom_microphones',
              label: 'Boom Microphones',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_cyc_wall',
              label: 'Cyc Wall',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_tripods',
              label: 'Tripods',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_backdrops',
              label: 'Backdrops',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_soundproof',
              label: 'Soundproof',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_fog_machine',
              label: 'Fog Machine',
              eventName: ['video_shoots'],
            },

            {
              key: 'video_natural_lighting',
              label: 'Natural Lighting',
              eventName: ['video_shoots'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'photoamenities',
    label: 'Photo Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_photoamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'photo_shoots',
          label: 'Photo Shoots',
          idName: 'photoamenities',
          amenitiesList: [
            {
              key: 'photo_umbrella_lighting',
              label: 'Umbrella Lighting',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_ring_lighting',
              label: 'Ring Lighting',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_dressing_rooms',
              label: 'Dressing Rooms',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_green_rooms',
              label: 'Green Rooms',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_makeup_area',
              label: 'Makeup Area',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_boom_microphones',
              label: 'Boom Microphones',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_cyc_wall',
              label: 'Cyc Wall',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_tripods',
              label: 'Tripods',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_backdrops',
              label: 'Backdrops',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_soundproof',
              label: 'Soundproof',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_car_accessible',
              label: 'Car Accessible',
              eventName: ['photo_shoots'],
            },

            {
              key: 'photo_natural_lighting',
              label: 'Natural Lighting',
              eventName: ['photo_shoots'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'weddingamenities',
    label: 'Wedding Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_weddingamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'wedding',
          label: 'Wedding',
          idName: 'weddingamenities',
          amenitiesList: [
            {
              key: 'wedding_chairs',
              label: 'Chairs',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_projectors',
              label: 'Projectors',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_sound_system',
              label: 'Sound System',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_microphone',
              label: 'Microphone',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_podium',
              label: 'Podium',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_stage',
              label: 'Stage',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_lighting_system',
              label: 'Lighting System',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_catering_services',
              label: 'Catering Services',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_tables',
              label: 'Tables',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_table_linens',
              label: 'Table Linens',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_chair_linens',
              label: 'Chair Linens',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_bridal_suite',
              label: 'Bridal Suite',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_groom_suite',
              label: 'Groom suite',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_photobooth',
              label: 'Photobooth',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_dishware',
              label: 'Dishware',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_reception_area',
              label: 'Reception area',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_cocktail_area',
              label: 'Cocktail area',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_valet',
              label: 'Valet',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_dance_floor',
              label: 'Dance floor',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_bar',
              label: 'Bar',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_established_liquor_license',
              label: 'Established Liquor License',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_kitchen_facilities',
              label: 'Kitchen Facilities',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_TV_screens',
              label: 'TV Screens',
              eventName: ['weddings'],
            },

            {
              key: 'wedding_tents',
              label: 'Tents',
              eventName: ['weddings'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'performanceamenities',
    label: 'Performance Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_performanceamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'performance',
          label: 'Performance/Shows',
          idName: 'performanceamenities',
          amenitiesList: [
            {
              key: 'performances_chairs',
              label: 'Chairs',
              eventName: ['performances_shows'],
            },
            {
              key: 'performances_projectors',
              label: 'Projectors',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_sound_system',
              label: 'Sound System',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_microphone',
              label: 'Microphone',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_podium',
              label: 'Podium',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_stage',
              label: 'Stage',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_lighting_system',
              label: 'Lighting System',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_dressing_rooms',
              label: 'Dressing Rooms',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_green_rooms',
              label: 'Green Rooms',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_makeup_area',
              label: 'Makeup Area',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_boom_microphones',
              label: 'Boom Microphones',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_cyc_wall',
              label: 'Cyc Wall',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_tripods',
              label: 'Tripods',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_backdrops',
              label: 'Backdrops',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_soundproof',
              label: 'Soundproof',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_natural_lighting',
              label: 'Natural Lighting',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_auditorium_seating',
              label: 'Auditorium Seating',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_stage_curtain',
              label: 'Stage Curtain',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_car_accessible',
              label: 'Car Accessible',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_DJ_booth',
              label: 'DJ Booth',
              eventName: ['performances_shows'],
            },

            {
              key: 'performances_box_office',
              label: 'Box Office',
              eventName: ['performances_shows'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'cocktailamenities',
    label: 'Cocktail Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_cocktailamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'cocktail',
          label: 'Cocktail Hour',
          idName: 'cocktailamenities',
          amenitiesList: [
            {
              key: 'cocktail_chairs',
              label: 'Chairs',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_projectors',
              label: 'Projectors',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_sound_system',
              label: 'Sound System',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_microphone',
              label: 'Microphone',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_lighting_system',
              label: 'Lighting System',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_tables',
              label: 'Tables',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_reception_area',
              label: 'Reception area',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_valet',
              label: 'Valet',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_dance_floor',
              label: 'Dance floor',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_bar',
              label: 'Bar',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_established_liquor_license',
              label: 'Established Liquor License',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_high_tops',
              label: 'High Tops',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_kitchen_facilities',
              label: 'Kitchen Facilities',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_barware',
              label: 'Barware',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_TV_screens',
              label: 'TV Screens',
              eventName: ['Cocktail_hours'],
            },

            {
              key: 'cocktail_tents',
              label: 'Tents',
              eventName: ['Cocktail_hours'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'workshopamenities',
    label: 'Workshop Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_workshopsamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'workshop',
          label: 'Workshops / Classes',
          idName: 'workshopamenities',
          amenitiesList: [
            {
              key: 'workshop_conference_table',
              label: 'Conference Table',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_chairs',
              label: 'Chairs',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_pantry',
              label: 'Pantry',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_breakout_rooms',
              label: 'Breakout Rooms',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_projectors',
              label: 'Projectors',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_sound_system',
              label: 'Sound System',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_microphone',
              label: 'Microphone',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_podium',
              label: 'Podium',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_stage',
              label: 'Stage',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_lighting_system',
              label: 'Lighting System',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_whiteboards',
              label: 'Whiteboards',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_video_conference_system',
              label: 'Video conference system',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_printers',
              label: 'Printers',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_catering_services',
              label: 'Catering Services',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_tables',
              label: 'Tables',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_individual_power_outlets',
              label: 'Individual Power Outlets',
              eventName: ['workshops_classes'],
            },

            {
              key: 'workshop_television_screens',
              label: 'Television Screens',
              eventName: ['workshops_classes'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'retailamenities',
    label: 'Retail Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_reatailamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'retail_popups',
          label: 'Retail Popups',
          idName: 'retailamenities',
          amenitiesList: [
            {
              key: 'reatil_sound_system',
              label: 'Sound System',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_lighting_system',
              label: 'Lighting System',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_tables',
              label: 'Tables',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_display_cases',
              label: 'Display Cases',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_clothing_racks',
              label: 'Clothing Racks',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_signage_area',
              label: 'Signage Area',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_display_windows_frontage',
              label: 'Display windows/Frontage',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_ADA_accessible',
              label: 'ADA Accessible',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_frontage_lineal',
              label: 'Frontage (20+ lineal feet)',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_storage',
              label: 'Storage',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_fitting_rooms',
              label: 'Fitting rooms',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_mirrors',
              label: 'Mirrors',
              eventName: ['retail_popups'],
            },

            {
              key: 'reatil_shelving',
              label: 'Shelving',
              eventName: ['retail_popups'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'audioamenities',
    label: 'Audio Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_audioamenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          id: 'audio_recordings',
          label: 'Audio Recording',
          idName: 'audioamenities',
          amenitiesList: [
            {
              key: 'audio_soundproof',
              label: 'Soundproof',
              eventName: ['audio_recordings'],
            },

            {
              key: 'audio_sound_booth',
              label: 'Sound Booth',
              eventName: ['audio_recordings'],
            },

            {
              key: 'audio_mixing_equipment',
              label: 'Mixing Equipment',
              eventName: ['audio_recordings'],
            },

            {
              key: 'audio_studio_monitors',
              label: 'Studio Monitors',
              eventName: ['audio_recordings'],
            },

            {
              key: 'audio_microphones',
              label: 'Microphones',
              eventName: ['audio_recordings'],
            },

            {
              key: 'audio_headphones',
              label: 'Headphones',
              eventName: ['audio_recordings'],
            },

            {
              key: 'audio_furnished',
              label: 'furnished',
              eventName: ['audio_recordings'],
            },
          ],
        },
      ],
    },
  },

  {
    id: 'amenities',
    label: 'amenities',
    // type: 'SelectMultipleFilter',
    // group: 'secondary',
    queryParamNames: ['pub_amenities'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'wifi',
          label: 'Wifi',
          eventName: [
            'meetings',
            'birthday_parties',
            'cooking_events',
            'video_shoots',
            'photo_shoots',
            'adult_filming',
            'kids_parties',
            'pet_parties',
          ],
        },
        {
          key: 'breakout_rooms_and_how_many',
          label: 'Breakout rooms and how many',
          eventName: [
            'birthday_parties',
            'video_shoots',
            'photo_shoots',
            'adult_filming',
            'kids_parties',
          ],
        },
        {
          key: 'restrooms',
          label: 'Restrooms',
          eventName: [
            'meetings',
            'birthday_parties',
            'cooking_events',
            'video_shoots',
            'photo_shoots',
            'adult_filming',
            'kids_parties',
            'pet_parties',
          ],
        },
        {
          key: 'parking',
          label: 'Parking',
          eventName: ['meetings', 'birthday_parties', 'kids_parties', 'pet_parties'],
        },
        {
          key: 'kitchen_facilities',
          label: 'Kitchen facilities',
          eventName: ['birthday_parties', 'cooking_events', 'kids_parties', 'pet_parties'],
        },
        {
          key: 'heating_and_air_conditioning',
          label: 'Heating and air conditioning',
          eventName: [
            'meetings',
            'birthday_parties',
            'video_shoots',
            'photo_shoots',
            'adult_filming',
          ],
        },
        {
          key: 'elevator_and_wheelchair_accessibility',
          label: 'Elevator and wheelchair accessibility',
          eventName: [
            'meetings',
            'birthday_parties',
            'video_shoots',
            'photo_shoots',
            'kids_parties',
          ],
        },
        {
          key: 'pool_sauna',
          label: 'Pool/Sauna',
          eventName: ['birthday_parties', 'video_shoots', 'photo_shoots', 'adult_filming'],
        },
        {
          key: 'views',
          label: 'Views',
          eventName: [
            'meetings',
            'birthday_parties',
            'video_shoots',
            'photo_shoots',
            'kids_parties',
            'pet_parties',
          ],
        },
        {
          key: 'lighting_and_sound_system',
          label: 'Lighting and sound system',
          eventName: [
            'meetings',
            'birthday_parties',
            'cooking_events',
            'video_shoots',
            'photo_shoots',
            'adult_filming',
            'kids_parties',
            'pet_parties',
          ],
        },
        {
          key: 'add_Ons_for_a_fee',
          label: 'Add Ons for a fee',
          eventName: ['meetings', 'cooking_events', 'video_shoots', 'photo_shoots'],
          subfield: [
            {
              key: 'seating_tables',
              label: 'Seating and Tables',
              eventName: [
                'meetings',
                'birthday_parties',
                'cooking_events',
                'kids_parties',
                'pet_parties',
              ],
            },
            {
              key: 'audio_visual',
              label: 'Audio-visual equipment and technicians',
              eventName: [
                'meetings',
                'birthday_parties',
                'cooking_events',
                'video_shoots',
                'photo_shoots',
                'adult_filming',
                'kids_parties',
                'pet_parties',
              ],
            },
            {
              key: 'security_personnel',
              label: 'Security Personnel',
              eventName: [
                'meetings',
                'cooking_events',
                'video_shoots',
                'photo_shoots',
                'kids_parties',
                'pet_parties',
              ],
            },
          ],
        },
        {
          key: 'On-site_catering_or_local_catering',
          label: 'On-site catering or local catering',
          eventName: ['birthday_parties', 'cooking_events', 'kids_parties'],
        },
        {
          key: 'florist',
          label: 'Florist',
          eventName: [
            'meetings',
            'birthday_parties',
            'cooking_events',
            'video_shoots',
            'photo_shoots',
            'adult_filming',
            'kids_parties',
            'pet_parties',
          ],
        },
        // { key: 'Insurance_MAYBE_check_the_box', label: 'Insurance MAYBE check the box' },
      ],
    },
  },
  {
    id: 'additionalservice',
    label: 'Additional Service',
    // type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_additionalservice'],
    config: {
      schemaType: 'multi-enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'Catering',
          label: 'Catering',
        },
        {
          key: 'Videographers_Photographers',
          label: 'Videographers/Photographers',
        },
        {
          key: 'Lighting',
          label: 'Lighting',
        },
        {
          key: 'Sound',
          label: 'Sound',
        },
        {
          key: 'Chair_rentals',
          label: 'Chair rentals',
        },
        {
          key: 'Table_rentals',
          label: 'Table rentals',
        },
        {
          key: 'Furniture_rentals',
          label: 'Furniture rentals',
        },
        {
          key: 'Licensed_security',
          label: 'Licensed security',
        },
        {
          key: 'Set_designers',
          label: 'Set designers',
        },
        {
          key: 'Set_contractors',
          label: 'Set contractors',
        },
        {
          key: 'Permit_expeditors',
          label: 'Permit expeditors',
        },
        // {
        //   key: 'none_additional_service',
        //   label: 'None',
        // },

        // { key: 'Insurance_MAYBE_check_the_box', label: 'Insurance MAYBE check the box' },
      ],
    },
  },
  {
    id: 'locationrules',
    label: 'locationrules',
    // type: 'SelectMultipleFilter',
    // group: 'secondary',
    queryParamNames: ['pub_locationrules'],
    config: {
      schemaType: 'enum',
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      // options: [
      //   { key: 'flames', label: 'No open flames' },
      //   { key: 'no_pets', label: 'No pets' },
      //   {
      //     key: 'no_authorized_audio',
      //     label: "No unauthorized use of the venue's audio or visual equipment.",
      //   },
      //   {
      //     key: 'no_outside_vendors',
      //     label: 'No outside vendors or caterers allowed without prior authorization.',
      //   },
      //   {
      //     key: 'no_soliciting',
      //     label: 'No soliciting or handing out flyers without prior authorization.',
      //   },
      //   { key: 'no_loud_noises', label: 'No loud noises' },
      //   { key: 'no_outside_food', label: 'No outside catering/food' },
      //   { key: 'no_smoking', label: 'No smoking' },
      //   { key: 'no_adult_fliming', label: 'No adult filming' },
      //   { key: 'no_alcohol', label: 'No alcohol' },
      //   { key: 'no_drugs', label: 'No drugs' },
      //   { key: 'no_cooking', label: 'No cooking' },
      //   { key: 'no_shoes', label: 'No shoes' },
      //   { key: 'no_food_drinks', label: 'No food and drinks' },
      //   { key: 'no_parking_street', label: 'No parking in the street- Only in the driveway' },
      //   {
      //     key: 'music_restrictions',
      //     label: 'For music videos, please contact the owner as some restrictions apply.',
      //   },
      //   {
      //     key: 'no_bikini_models',
      //     label: 'No bikini models are allowed where it is visible from the street.',
      //   },

      //   {
      //     key: 'no_light_attries',
      //     label:
      //       'No light attires that could be considered as indecent exposure to general public and neighborhood',
      //   },
      //   {
      //     key: 'use_restrooms',
      //     label: 'Use of restroom(s) for a crew of 15 people or less is allowed',
      //   },
      //   { key: 'masks_worn', label: 'Masks must be worn by guests and staff.' },
      //   { key: 'social_distancing', label: 'Must follow social distancing norms if applicable' },
      //   { key: 'temporary_place', label: 'Temporary Place of Assembly Permit required' },
      // ],
      options: [
        { key: 'no_adult_filming', label: 'No adult filming' },
        { key: 'no_parties', label: 'No parties' },
        { key: 'no_pets', label: 'No pets' },
        { key: 'no_alcohol', label: 'No alcohol' },
        { key: 'no_cannabis', label: 'No cannabis' },
        { key: 'no_smoking_vaping', label: 'No smoking/vaping' },
        { key: 'no_cooking', label: 'No cooking' },
        { key: 'no_open_flames', label: 'No open flames' },
        { key: 'no_food', label: 'No food' },
        { key: 'no_outside_vendors', label: 'No outside vendors' },
        { key: 'no_loud_music', label: 'No loud music' },
        { key: 'no_children_under_12', label: 'No children under 12' },
        { key: 'no_21_plus_only', label: '21+ only' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    // { key: 'createdAt', label: 'Newest' },
    // { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
