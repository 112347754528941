import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl, bookingType } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily || bookingType === 'dailyBooking'
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );


  const quantity = unitPurchase
    ? bookingType === 'dailyBooking'
      ? (unitPurchase.quantity).toString()
      : unitPurchase.quantity.toString()
    : null;
  // console.log('unitPurchase', unitPurchase);
  const dailyUnitPurchase =
    unitPurchase && bookingType === 'dailyBooking'
      ? new Money(unitPurchase.unitPrice.amount, unitPurchase.unitPrice.currency)
      : null;
  // const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const unitPrice = dailyUnitPurchase
    ? formatMoney(intl, dailyUnitPurchase)
    : unitPurchase
    ? formatMoney(intl, unitPurchase.unitPrice)
    : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return quantity && total ? (
    <div className={`${css.lineItem} test`}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
