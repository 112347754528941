import React from 'react';
import { FieldTextInput, FieldSelect } from '../../components';
import css from './EditListingDetailsForm.module.css';

const HoursAvailable = props => {
  const {
    intl,
    composeValidators,
    maxLength,
    required,
    HoursOptions,
    MaxHoursOptions,
    minhours,
    maxHoursValueMessage,
  } = props;

  let convertStingtoNumber = minhours ? Number(minhours) : 0;
  const hoursAvailLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.HoursAvalLabel',
  });

  const minHrsLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.minHoursLabel',
  });

  const maxHrsLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.maxHoursLabel',
  });

  const minHrsPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.minHoursPlaceholder',
  });

  const maxHrsPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.maxHoursPlaceholder',
  });

  const minHrsRequired = intl.formatMessage({
    id: 'EditListingDescriptionForm.minHoursRequired',
  });

  const maxHrsRequired = intl.formatMessage({
    id: 'EditListingDescriptionForm.maxHoursRequired',
  });

  const maxHoursList = MaxHoursOptions.map((val, index) => {
    return (
        <option
          disabled={val.key > convertStingtoNumber ? false : true}
          key={val.key}
          value={val.key}
        >
          {val.label}
        </option>
    );
  });
  const { updateDetailsTabPublicData } = props;
  return (
    <div className="hours_wrap">
      <h3 className={css.hourLabel}>{hoursAvailLabel}</h3>
      <div className={`${css.capacityInputField} ${css.hoursInput}`}>
        <div>
          {HoursOptions ? (
            <FieldSelect
              className={css.title}
              name="minhours"
              id="minhours"
              type="number"
              label={minHrsLabel}
              impfield={"true"}
              placeholder={minHrsPlaceholder}
              validate={composeValidators(required(minHrsRequired))}
              onChange={value => updateDetailsTabPublicData({ minhours: value })}
            >
              <option value="">Select minimum hours</option>
              {HoursOptions.map((c, k) =>
                'subcategory' in HoursOptions[k] ? (
                  <optgroup label={c.label} key={c.key}>
                    {c.subcategory.map(subCat => (
                      <option key={subCat.key} value={subCat.key}>
                        {subCat.label}
                      </option>
                    ))}
                  </optgroup>
                ) : (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                )
              )}
            </FieldSelect>
          ) : null}
        </div>

        <div>
          {MaxHoursOptions ? (
            <FieldSelect
              className={css.title}
              name="maxhours"
              id="maxhours"
              type="number"
              impfield={"true"}
              label={maxHrsLabel}
              placeholder={maxHrsPlaceholder}
              validate={composeValidators(required(maxHrsRequired), maxHoursValueMessage)}
              onChange={value => updateDetailsTabPublicData({ maxhours: value })}
            >
              <option value="">Select maximum hours</option>
              {maxHoursList}
            </FieldSelect>
          ) : null}
        </div>
      </div>

      {/* 
      <div>
        <FieldSelect
          className={css.title}
          name="maxhours"
          id="maxhours"
          type="number"
          impfield={true}
          label={maxHrsLabel}
          placeholder={maxHrsPlaceholder}
          validate={composeValidators(required(maxHrsRequired))}
        >
          {HoursOptions.map(num => {
            return <option>{num}</option>;
          })}
        </FieldSelect>
      </div> */}
    </div>
  );
};

export default HoursAvailable;
