import { reverse, sortBy } from 'lodash';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { notifyProviderForCancellationRequest } from '../../util/api';
import { storableError } from '../../util/errors';
import { TRANSITION_ACCEPT, TRANSITION_CONFIRM_PAYMENT } from '../../util/transaction';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_MYORDERS_REQUEST = 'ReqCancellationPage/FETCH_MYORDERS_REQUEST';
export const FETCH_MYORDERS_SUCCESS = 'ReqCancellationPage/FETCH_MYORDERS_SUCCESS';
export const FETCH_MYORDERS_ERROR = 'ReqCancellationPage/FETCH_MYORDERS_ERROR';

export const FORM_SUBMIT_REQUEST = 'ReqCancellationPage/FORM_SUBMIT_REQUEST';
export const FORM_SUBMIT_SUCCESS = 'ReqCancellationPage/FORM_SUBMIT_SUCCESS';
export const FORM_SUBMIT_ERROR = 'ReqCancellationPage/FORM_SUBMIT_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: null,
  fetchInError: null,
  transactionsList: [],
  sendRequestInProgress: null,
  sendRequestInError: null,
  sendRequestResponse: null,
};

// ================ Action creators ================ //

const fetchOrderRequest = () => ({ type: FETCH_MYORDERS_REQUEST });
const fetchOrderSuccess = response => ({ type: FETCH_MYORDERS_SUCCESS, payload: response });
const fetchOrderFailed = e => ({ type: FETCH_MYORDERS_ERROR, error: true, payload: e });

const formSubmitRequest = () => ({ type: FORM_SUBMIT_REQUEST });
const formSubmitSuccess = response => ({ type: FORM_SUBMIT_SUCCESS, payload: response });
const formSubmitFailed = error => ({ type: FORM_SUBMIT_ERROR, error: true, payload: error });

export default function RequestCancellationReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MYORDERS_REQUEST:
      return { ...state, fetchInProgress: true };
    case FETCH_MYORDERS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);

      return {
        ...state,
        fetchInProgress: false,
        transactionsList: entityRefs(transactions),
      };
    }
    case FETCH_MYORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FORM_SUBMIT_REQUEST:
      return {
        ...state,
        sendRequestInProgress: true,
        sendRequestInError: null,
        sendRequestResponse: null,
      };

    case FORM_SUBMIT_SUCCESS:
      return { ...state, sendRequestInProgress: false, sendRequestResponse: payload };

    case FORM_SUBMIT_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, sendRequestInProgress: false, sendRequestInError: payload };

    default:
      return state;
  }
}

// ================ Thunks ================ //

export const notifyProviderForCancellation = payload => dispatch => {
  const { requestedPayload, onAfterResponseReceived } = payload;
  dispatch(formSubmitRequest());
  notifyProviderForCancellationRequest(requestedPayload)
    .then(response => {
      dispatch(formSubmitSuccess(response));
      onAfterResponseReceived(response);
    })
    .catch(err => {
      dispatch(formSubmitFailed(storableError(err)));
    });
};

const fetchMyOrders = () => (dispatch, getState, sdk) => {
  const apiQueryParams = {
    only: 'order',
    lastTransitions: [TRANSITION_ACCEPT, TRANSITION_CONFIRM_PAYMENT], // Requested and Accepted Bookings
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };
  dispatch(fetchOrderRequest());
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrderSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrderFailed(storableError(e)));
      throw e;
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  return dispatch(fetchMyOrders());
};
