import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const routes = useLocation();
  const isHomePage = routes.pathname === '/';

  useEffect(() => {
    setMounted(true);
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
      if(isAuthenticated){
        window.dataLayer.push({
          event: "visitorStatus",
          visitorStatus: "customer",
          user_id: currentUser?.id?.uuid,
        });
      } else {
        window.dataLayer.push({
          event: "visitorStatus",
          visitorStatus: "guest",
          user_id: '',
        });
      }
    }
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      id="keywordsearchdesktop"
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const myAccountsLink = authenticatedOnClientSide ? (
    <NamedLink className={css.inboxLink} name="ProfileSettingsPage">
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.myAccount" />
      </span>
    </NamedLink>
  ) : null;

  const favoritesLink = authenticatedOnClientSide ? (
    <NamedLink className={css.inboxLink} name="MyWishListPage">
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.favoritesLink" />
      </span>
    </NamedLink>
  ) : null;

  const spacesLink = (
    <NamedLink className={css.inboxLink} name="SearchPage">
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.showListing" />
      </span>
    </NamedLink>
  );

  const createListingLink = (
    <NamedLink className={css.createListingLink} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  );

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink topbar={true} />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="EditListingPage">
          <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.yourListingsLink}
          >
            <div>
              <span className={css.menuItemBorder} />
              {currentUserListing ? (
                <FormattedMessage id="TopbarDesktop.editYourListingLink" />
              ) : (
                <FormattedMessage id="TopbarDesktop.addYourListingLink" />
              )}
            </div>
          </OwnListingLink>
        </MenuItem> */}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyWishListPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('MyWishListPage'))}
            name="MyWishListPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myWishListLink" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="RequestCancellationPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('RequestCancellationPage')
            )}
            name="RequestCancellationPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.requestCancellation" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ContactUsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ContactUsPage'))}
            name="ContactUsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="contactUs.title" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.inboxLink}>
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const hostingsLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.inboxLink}>
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.hosting" />
      </span>
    </NamedLink>
  );

  // const listingLink =
  //   authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
  //     <ListingLink
  //       className={css.createListingLink}
  //       listing={currentUserListing}
  //       children={
  //         <span className={css.createListing}>
  //           <FormattedMessage id="TopbarDesktop.viewListing" />
  //         </span>
  //       }
  //     />
  //   ) : null;

  // const createListingLink =
  //   isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
  //     <NamedLink className={css.createListingLink} name="NewListingPage">
  //       <span className={css.createListing}>
  //         <FormattedMessage id="TopbarDesktop.createListing" />
  //       </span>
  //     </NamedLink>
  //   );

  return (
    <nav className={`${classes} ${isHomePage ? css.homePageHeader : ''}`}>
      <NamedLink className={css.topBarLogoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.topBarLogo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {!isHomePage && search}

      {/* {listingLink} */}
      {createListingLink}
      {spacesLink}
      {favoritesLink}
      {inboxLink}
      {profileMenu}
      {loginLink}
      {signupLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
