import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { Form, RangeSlider } from '../../components';
import css from './CapacityFilterForm.module.css';

const DEBOUNCE_WAIT_TIME = 400;

// Helper function to parse value for min handle
// Value needs to be between slider's minimum value and current maximum value
const parseMin = (min, currentMax) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};

// Helper function to parse value for max handle
// Value needs to be between slider's max value and current minimum value
const parseMax = (max, currentMin) => value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

const CapacityFilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('CapacityFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'CapacityFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    formState => {
      if (formState.dirty) {
        const { minCapacity, maxCapacity, ...restValues } = formState.values;
        onChange({
          minCapacity: minCapacity === '' ? rest.min : minCapacity,
          maxCapacity: maxCapacity === '' ? rest.max : maxCapacity,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    const { minCapacity, maxCapacity, ...restValues } = values;
    return onSubmit({
      minCapacity: minCapacity === '' ? rest.min : minCapacity,
      maxCapacity: maxCapacity === '' ? rest.max : maxCapacity,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          contentRef,
          style,
          intl,
          values,
          min,
          max,
          step,
        } = formRenderProps;

        const { minCapacity: minCapacityRaw, maxCapacity: maxCapacityRaw } = values;
        const minCapacity = typeof minCapacityRaw !== 'string' ? minCapacityRaw : min;
        const maxCapacity = typeof maxCapacityRaw !== 'string' ? maxCapacityRaw : max;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'PriceFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ minWidth: '300px', ...style }}
          >
            <div className={css.contentWrapper}>
              <span className={css.label}>
                <FormattedMessage id="CapacityFilterForm.label" />
              </span>
              <div className={css.inputsWrapper}>
                <Field
                  className={css.minCapacity}
                  id={`${id}.minCapacity`}
                  name="minCapacity"
                  component="input"
                  type="number"
                  aria-label="minCapacity"
                  placeholder={min}
                  min={min}
                  max={max}
                  step={step}
                  parse={parseMin(min, maxCapacity)}
                />
                <span className={css.priceSeparator}>-</span>
                <Field
                  className={css.maxCapacity}
                  id={`${id}.maxCapacity`}
                  name="maxCapacity"
                  aria-label="maxCapacity"
                  component="input"
                  type="number"
                  placeholder={max}
                  min={min}
                  max={max}
                  step={step}
                  parse={parseMax(max, minCapacity)}
                />
              </div>
            </div>

            <div className={css.sliderWrapper}>
              <RangeSlider
                min={min}
                max={max}
                step={step}
                handles={[minCapacity, maxCapacity]}
                onChange={handles => {
                  form.change('minCapacity', handles[0]);
                  form.change('maxCapacity', handles[1]);
                }}
              />
            </div>

            {liveEdit ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

CapacityFilterFormComponent.defaultProps = {
    liveEdit: false,
    showAsPopup: false,
    isOpen: false,
    contentRef: null,
    style: null,
    min: 0,
    step: 1,
    onCancel: null,
    onChange: null,
    onClear: null,
    onSubmit: null,
  };
  
  CapacityFilterFormComponent.propTypes = {
    id: string.isRequired,
    liveEdit: bool,
    showAsPopup: bool,
    onCancel: func,
    onChange: func,
    onClear: func,
    onSubmit: func,
    isOpen: bool,
    contentRef: func,
    style: object,
    min: number.isRequired,
    max: number.isRequired,
    step: number,
  
    // form injectIntl
    intl: intlShape.isRequired,
  };
  
  const CapacityFilterForm = injectIntl(CapacityFilterFormComponent);
  
  export default CapacityFilterForm;
