/**
 * This file contains Action types, Action creators, and reducer of global
 * LocationFilter. Global actions can be used in multiple pages.
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */

// Actions
export const UPDATE_PUBLIC_DATA = 'app/EditListingPage/UPDATE_PUBLIC_DATA';

// Reducer
export default function reducer(state = {}, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PUBLIC_DATA: {
      // console.log('UPDATE_PUBLIC_DATA state', state);
      return { ...state, ...payload };
    }
    default:
      return state;
  }
}

// Action types
export const updateDetailsTabPublicData = data => ({ type: UPDATE_PUBLIC_DATA, payload: data });
