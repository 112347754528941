import React from 'react';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const CategorySelectField = props => {
  const { name, id, categoryOptions, intl } = props;
  const categoryLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.categoryLabel',
  });

  return categoryOptions ? (
    <FieldSelect
      className={css.certificate}
      name={name}
      id={id}
      label={categoryLabel}
      impfield={props.impfield}
      validate={props.validate}
    >
      <option className={css.venueTypePlaceholder} value="">
        Choose venue type...
      </option>
      {categoryOptions.map((c, k) =>
        'subcategory' in categoryOptions[k] ? (
          <optgroup key={c.key} label={c.label} className={css.venueTypeOptions}>
            {c.subcategory.map(subCat => (
              <option key={subCat.key} value={subCat.key}>
                {subCat.label}
              </option>
            ))}
          </optgroup>
        ) : (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        )
      )}
    </FieldSelect>
  ) : null;
};

export default CategorySelectField;
