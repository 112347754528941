import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatCurrencyMajorUnit } from '../../util/currency';
import config from '../../config';

import { CapacityFilterForm } from '../../forms';
import css from './CapacityFilterPlain.module.css';

const RADIX = 10;

const getCapacityQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'minimumcapacity';
};

// Parse value, which should look like "0,1000"
const parse = capacityRange => {
  const [minCapacity, maxCapacity] = !!capacityRange
    ? capacityRange.split(',').map(v => Number.parseInt(v, RADIX))
    : [];
  // Note: we compare to null, because 0 as minCapacity is falsy in comparisons.
  return !!capacityRange && minCapacity != null && maxCapacity != null
    ? { minCapacity, maxCapacity }
    : null;
};

// Format value, which should look like { minCapacity, maxCapacity }
const format = (range, queryParamName) => {
  const { minCapacity, maxCapacity } = range || {};
  // Note: we compare to null, because 0 as minCapacity is falsy in comparisons.
  const value = minCapacity != null && maxCapacity != null ? `${minCapacity},${maxCapacity}` : null;
  return { [queryParamName]: value };
};

class CapacityFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, queryParamNames } = this.props;
    const capacityQueryParamName = getCapacityQueryParamName(queryParamNames);
    onSubmit(format(values, capacityQueryParamName));
  }

  handleClear() {
    const { onSubmit, queryParamNames } = this.props;
    const capacityQueryParamName = getCapacityQueryParamName(queryParamNames);
    onSubmit(format(null, capacityQueryParamName));
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      queryParamNames,
      initialValues,
      min,
      max,
      step,
      intl,
      currencyConfig,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const capacityQueryParam = getCapacityQueryParamName(queryParamNames);
    const initialPrice = initialValues ? parse(initialValues[capacityQueryParam]) : {};
    const { minCapacity, maxCapacity } = initialPrice || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minCapacity) && hasValue(maxCapacity);

    const labelClass = hasInitialValues ? css.filterLabelSelected : css.filterLabel;
    const labelText = hasInitialValues
      ? //  intl.formatMessage(
        //     { id: 'CapacityFilter.labelSelectedPlain' },
        //     {
        //       minPrice: minCapacity,
        //       maxPrice: maxCapacity,
        //     }
        //   )

        intl.formatMessage({ id: 'CapacityFilter.label' })
      : label
      ? label
      : intl.formatMessage({ id: 'CapacityFilter.label' });

    return (
      <div className={classes}>
        <div className={labelClass}>
          <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={labelClass}>{labelText}</span>
          </button>
          <button type="button" className={css.clearButton} onClick={this.handleClear}>
            <FormattedMessage id={'PriceFilter.clear'} />
          </button>
        </div>
        <div className={css.formWrapper}>
          <CapacityFilterForm
            id={id}
            initialValues={hasInitialValues ? initialPrice : { minCapacity: min, maxCapacity: max }}
            onChange={this.handleChange}
            intl={intl}
            contentRef={node => {
              this.filterContent = node;
            }}
            min={min}
            max={max}
            step={step}
            liveEdit
            isOpen={this.state.isOpen}
          />
        </div>
      </div>
    );
  }
}

CapacityFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  step: number,
  currencyConfig: config.currencyConfig,
};

CapacityFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    capacity: string,
  }),
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  currencyConfig: propTypes.currencyConfig,

  // form injectIntl
  intl: intlShape.isRequired,
};

const CapacityFilterPlain = injectIntl(CapacityFilterPlainComponent);

export default CapacityFilterPlain;
