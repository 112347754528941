import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput } from '../../components';
import { useHistory } from 'react-router-dom';
import css from './EditListingPricingForm.module.css';
import EditListingDynamicPricingForm from './EditListingDynamicPricingForm';
import arrayMutators from 'final-form-arrays';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        currentId,
        onChange,
        form,
        weekdays,
      } = formRenderProps;

      const {
        mutators: { push, pop, remove },
      } = form;
      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;
      const history = useHistory();

      const { price, dailyPrice } = values;
      // let priceAmount = price ? price.amount + '' : null;
      // let splitDailyPrice = priceAmount ? priceAmount.split('') : null;
      // splitDailyPrice && splitDailyPrice.splice(-2, 2);
      // let hourlyPriceValue = splitDailyPrice ? splitDailyPrice.join('') : null;

      const convertPriceToNum = price => {
        let priceAmount = price ? price.amount + '' : null;
        let splitDailyPrice = priceAmount ? priceAmount.split('') : null;
        splitDailyPrice && splitDailyPrice.splice(-2, 2);
        let originalPriceValue = splitDailyPrice ? splitDailyPrice.join('') : null;
        return originalPriceValue;
      };

      let hourlyPriceValue = convertPriceToNum(price);
      let dailyPriceValue = convertPriceToNum(dailyPrice);

      let allValues = { ...values, ...{ id: currentId } };
      const jsonValues = JSON.stringify(allValues);
      sessionStorage.setItem('pricing', jsonValues);

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      // const cleaningFeePlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingPricingForm.cleaningFeeInputPlaceholderMessage',
      // });

      // const cleaningFeeMessage = intl.formatMessage({
      //   id: 'EditListingPricingForm.cleaningFeeLabel',
      // });

      const dailyPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.dailyPriceInputPlaceholderMessage',
      });

      const dailyPriceMessage = intl.formatMessage({
        id: 'EditListingPricingForm.dailyPriceLabel',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const maxPrice = new Money(config.listingMaximumPriceSubUnits, config.currency);

      const maxValueMessage = intl.formatMessage(
        { id: 'EditListingDestailsForm.maxValueThanMin' },
        { value: hourlyPriceValue ? hourlyPriceValue : 0 }
      );

      const hourlyPriceValidator = validators.checkMaxPriceIsGreatThenMinPrice(
        maxValueMessage,
        hourlyPriceValue
      );

      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );

      const maxPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, maxPrice),
          }
        ),
        config.listingMaximumPriceSubUnits
      );

      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const dailyPriceValidators = config.listingMaximumPriceSubUnits
        ? validators.composeValidators(priceRequired, maxPriceRequired, hourlyPriceValidator)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const previousLabel = intl.formatMessage(
        { id: 'EditListingDetailsForm.previousLabel' },
        { previousValue: 'Policies' }
      );

      const checkboxSnippet = intl.formatMessage({
        id: 'EditListingPricingForm.dynamicPriceSnippet',
      });

      const goBack = () => {
        let { pathname } = history.location;
        let splitPathname = pathname.split('/');
        splitPathname[splitPathname.length - 1] = 'policies';
        history.push(splitPathname.join('/'));
      };

      return (
        <>
          <span className={css.snippetMsg}>{checkboxSnippet}</span>
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <FormSpy onChange={onChange} subscription={{ visited: true }} />
            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              autoFocus
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
              required={true}
              autoComplete="off"
            />
            <FieldCurrencyInput
              id="dailyPrice"
              name="dailyPrice"
              className={css.dailyPriceInput}
              label={dailyPriceMessage}
              placeholder={dailyPricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              // validate={dailyPriceValidators}

              validate={validators.composeValidators(
                priceRequired,
                maxPriceRequired
                // hourlyPriceValidator
              )}
              required={true}
              autoComplete="off"
            />

            {dailyPriceValue && Number(dailyPriceValue) === Number(hourlyPriceValue) ? (
              <p className={css.warningPriceMsg}>
                {' '}
                The price of the ‘hourly’ price is equal to the 'daily’ price. Do you want to
                proceed?{' '}
              </p>
            ) : (
              ''
            )}
            {dailyPriceValue && Number(dailyPriceValue) < Number(hourlyPriceValue) ? (
              <p className={css.warningPriceMsg}>
                {' '}
                The price of the ‘hourly’ price is more than the 'daily’ price. Do you want to
                proceed?{' '}
              </p>
            ) : (
              ''
            )}
            {/* 
          <FieldCurrencyInput
            id="cleaningFee"
            name="cleaningFee"
            className={css.cleaningFeeInput}
            autoFocus
            label={cleaningFeeMessage}
            placeholder={cleaningFeePlaceholderMessage}
            currencyConfig={config.currencyConfig}
          /> */}

            <EditListingDynamicPricingForm
              config={config}
              intl={intl}
              values={values}
              form={form}
              weekdays={weekdays}
              push={push}
              pop={pop}
              remove={remove}
              priceRequired ={priceRequired}
              minPriceRequired = {minPriceRequired}
              priceValidators={priceValidators}
              validators={validators}
            />
            <div className={css.buttonWrapper}>
              <Button className={css.previousButton} type="button" onClick={goBack}>
                {previousLabel}
              </Button>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        </>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
