import React, { useState } from 'react';
import { FieldTextInput } from '../../components';
import { BsPlusCircle } from 'react-icons/bs';
import { SlMinus } from 'react-icons/sl';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import css from './EditListingDetailsForm.module.css';

const EditListingVirtualWalkThrough = props => {
  const { intl, composeValidators, maxLength, required, push } = props;
  const walkThroughLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.virtualWalktTitle',
  });

  const walkThroughPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.virtualWalkPlaceholder',
  });

  const walkThroughRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.virtualWalkRequired',
  });

  const pushField = e => {
    push('walkthroughYT');
  };

  const renderFieldArray = props => {
    const { fields } = props;
    const mapFields = fields.map((val, ind) => {
      return (
        <div key={val}>
          <label htmlFor={walkThroughLabel}>{walkThroughLabel}</label>
          <input type="text" placeholder={walkThroughPlaceholder} name={val} />
          <SlMinus
            className={css.minusIcon}
            onClick={() => {
              fields.remove(ind);
            }}
          />
        </div>
      );
    });
    return mapFields;
  };

  const { updateDetailsTabPublicData, walkthroughYT } = props;

  const onChange = (e, index) => {
    const updatedUrls = [...walkthroughYT];
    updatedUrls[index].ytURl = e.target.value;
    updateDetailsTabPublicData({
      walkthroughYT: updatedUrls,
    });
  };

  const removeField = i => {};

  const addField = () => {};

  return (
    <div className={css.ytDiv}>
      <BsPlusCircle
        className={css.plusIcon}
        size={20}
        onClick={e => {
          const updatedUrls = [...walkthroughYT];
          updatedUrls.push({ ytURl: '' });
          updateDetailsTabPublicData({
            walkthroughYT: updatedUrls,
          });
          pushField({ ...props });
        }}
      />

      <FieldArray name="walkthroughYT">
        {({ fields }) => (
          <div>
            {fields.map((name, index, arr) => (
              <div key={name} className={css.virtualChildWrap}>
                <FieldTextInput
                  className={css.title}
                  label={walkThroughLabel}
                  placeholder={walkThroughPlaceholder}
                  name={`${name}.ytURl`}
                  id={`${name}.ytURl`}
                  type="text"
                  onChange={e => onChange(e, index)}
                />

                {fields.length > 1 ? (
                  <SlMinus
                    className={css.minusIcon}
                    size={20}
                    onClick={() => {
                      fields.remove(index);
                      const updatedUrls = [...walkthroughYT];
                      updatedUrls.splice(index, 1);
                      updateDetailsTabPublicData({
                        walkthroughYT: updatedUrls,
                      });
                    }}
                  />
                ) : null}
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default EditListingVirtualWalkThrough;
