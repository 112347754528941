import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { getOnlyListingIds, getProductIds } from '../../util/productCompareService';

// ================ Action types ================ //

export const FETCH_COMPARE_PRODUCTS_REQUEST = 'app/ListingPage/FETCH_MYWISHLIST_REQUEST';
export const FETCH_COMPARE_PRODUCTS_SUCCESS = 'app/ListingPage/FETCH_MYWISHLIST_SUCCESS';
export const FETCH_COMPARE_PRODUCTS_ERROR = 'app/ListingPage/FETCH_MYWISHLIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  compareListings: [],
  fetchCompareProductsListError: null,
  fetchCompareProductsListProgress: null,
};

const productComparePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COMPARE_PRODUCTS_REQUEST:
      return {
        ...state,
        fetchCompareProductsListProgress: true,
        fetchCompareProductsListError: null,
      };
    case FETCH_COMPARE_PRODUCTS_SUCCESS:
      return {
        ...state,
        fetchCompareProductsListProgress: false,
        compareListings: payload,
        fetchCompareProductsListError: null,
      };
    case FETCH_COMPARE_PRODUCTS_ERROR:
      return {
        ...state,
        fetchCompareProductsListProgress: false,
        fetchCompareProductsListError: payload,
      };

    default:
      return state;
  }
};

export default productComparePageReducer;

// ================ Action creators ================ //

export const fetchCompareProductRequest = () => ({ type: FETCH_COMPARE_PRODUCTS_REQUEST });
export const fetchCompareProductListSuccess = compareListings => ({
  type: FETCH_COMPARE_PRODUCTS_SUCCESS,
  payload: compareListings,
});
export const fetchCompareProductListError = error => ({
  type: FETCH_COMPARE_PRODUCTS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const fetchCompareProductsListings = () => (dispatch, getState, sdk) => {
  const currentCompareProdIds = getOnlyListingIds();
  if (!currentCompareProdIds || currentCompareProdIds.length === 0) {
    return null;
  }
  return sdk.listings
    .query({
      ids: currentCompareProdIds,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      // 'limit.images': 1,
    })
    .then(response => {
      const compareListings = denormalisedResponseEntities(response);
      dispatch(fetchCompareProductListSuccess(compareListings));
    })
    .catch(e => {
      dispatch(fetchCompareProductListError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  dispatch(fetchCompareProductRequest());
  return Promise.all([dispatch(fetchCompareProductsListings())]);
};
