import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingAmenitiesForm } from '../../forms';
import { ListingLink } from '..';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingAmenitiesPanel.module.css';
import { includes } from 'lodash';
import { publishListingError } from '../../containers/EditListingPage/EditListingPage.duck';

const AMENITIES_NAME = 'amenities';

const EditListingAmenitiesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const amenitiesData = publicData && publicData.amenities;
  const additionalServiceData = publicData && publicData.additionalservice;
  const generalServiceData = publicData && publicData.generalServiceData;

  const generalAmenitiesData = publicData && publicData.generalamenities;
  const meetingamenitiesData = publicData && publicData.meetingamenities;
  const corporateamenitiesData = publicData && publicData.corporateamenities;
  const partiesamenitiesData = publicData && publicData.partiesamenities;
  const cookingamenitiesData = publicData && publicData.cookingamenities;
  const videoamenitiesData = publicData && publicData.videoamenities;
  const photoamenitiesData = publicData && publicData.photoamenities;
  const weddingAmenitiesData = publicData && publicData.weddingamenities;
  const performanceAmenitiesData = publicData && publicData.performanceamenities;
  const cocktailAmenitiesData = publicData && publicData.cocktailamenities;
  const workshopsAmenitiesData = publicData && publicData.workshopsamenities;
  const reatailAmenitiesData = publicData && publicData.reatailamenities;
  const audioAmenitiesData = publicData && publicData.audioamenities;
  const additionalAmenitiesData = publicData && publicData.additionalamenities;

  const eventsValue = publicData && publicData.events;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const currentId = currentListing?.id?.uuid;

  const meetingOptions = findOptionsForSelectFilter('meetingamenities', config.custom.filters);
  const coporateOptions = findOptionsForSelectFilter('corporateamenities', config.custom.filters);
  const partiesOptions = findOptionsForSelectFilter('partiesamenities', config.custom.filters);
  const cookingOptions = findOptionsForSelectFilter('cookingamenities', config.custom.filters);
  const videoOptions = findOptionsForSelectFilter('videoamenities', config.custom.filters);
  const photoOptions = findOptionsForSelectFilter('photoamenities', config.custom.filters);
  const weddingOptions = findOptionsForSelectFilter('weddingamenities', config.custom.filters);
  const performanceOptions = findOptionsForSelectFilter(
    'performanceamenities',
    config.custom.filters
  );
  const cocktailOptions = findOptionsForSelectFilter('cocktailamenities', config.custom.filters);
  const workshopOptions = findOptionsForSelectFilter('workshopamenities', config.custom.filters);
  const reatilOptions = findOptionsForSelectFilter('retailamenities', config.custom.filters);
  const audioOptions = findOptionsForSelectFilter('audioamenities', config.custom.filters);
  const additionalOptions = findOptionsForSelectFilter(
    'additionalamenities',
    config.custom.filters
  );
  const allAmenitiesList = [
    ...meetingOptions,
    ...coporateOptions,
    ...partiesOptions,
    ...cookingOptions,
    ...videoOptions,
    ...photoOptions,
    ...weddingOptions,
    ...performanceOptions,
    ...cocktailOptions,
    ...workshopOptions,
    ...reatilOptions,
    ...audioOptions,
  ];

  const getSessionValues = sessionStorage.getItem('amenities');
  let convertJsontoParse = JSON.parse(getSessionValues);
  convertJsontoParse = convertJsontoParse?.id == currentId ? convertJsontoParse : 'null';
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingAmenitiesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingAmenitiesPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingAmenitiesPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingAmenitiesForm
        className={css.form}
        name={AMENITIES_NAME}
        publicData={publicData}
        currentId={currentId}
        initialValues={{
          amenities: amenitiesData,
          generalservice:
            convertJsontoParse && convertJsontoParse.generalservice
              ? convertJsontoParse.generalservice
              : generalServiceData,
          generalamenities:
            convertJsontoParse && convertJsontoParse.generalamenities
              ? convertJsontoParse.generalamenities
              : generalAmenitiesData,
          meetingamenities:
            convertJsontoParse && convertJsontoParse.meetingamenities
              ? convertJsontoParse.meetingamenities
              : meetingamenitiesData,
          corporateamenities:
            convertJsontoParse && convertJsontoParse.corporateamenities
              ? convertJsontoParse.corporateamenities
              : corporateamenitiesData,
          partiesamenities:
            convertJsontoParse && convertJsontoParse.partiesamenities
              ? convertJsontoParse.partiesamenities
              : partiesamenitiesData,
          cookingamenities:
            convertJsontoParse && convertJsontoParse.cookingamenities
              ? convertJsontoParse.cookingamenities
              : cookingamenitiesData,
          videoamenities:
            convertJsontoParse && convertJsontoParse.videoamenities
              ? convertJsontoParse.videoamenities
              : videoamenitiesData,
          photoamenities:
            convertJsontoParse && convertJsontoParse.photoamenities
              ? convertJsontoParse.photoamenities
              : photoamenitiesData,
          weddingamenities:
            convertJsontoParse && convertJsontoParse.weddingamenities
              ? convertJsontoParse.weddingamenities
              : weddingAmenitiesData,
          performanceamenities:
            convertJsontoParse && convertJsontoParse.performanceamenities
              ? convertJsontoParse.performanceamenities
              : performanceAmenitiesData,
          cocktailamenities:
            convertJsontoParse && convertJsontoParse.cocktailamenities
              ? convertJsontoParse.cocktailamenities
              : cocktailAmenitiesData,
          workshopsamenities:
            convertJsontoParse && convertJsontoParse.workshopsamenities
              ? convertJsontoParse.workshopsamenities
              : workshopsAmenitiesData,
          reatailamenities:
            convertJsontoParse && convertJsontoParse.reatailamenities
              ? convertJsontoParse.reatailamenities
              : reatailAmenitiesData,
          audioamenities:
            convertJsontoParse && convertJsontoParse.audioamenities
              ? convertJsontoParse.audioamenities
              : audioAmenitiesData,
          additionalamenities:
            convertJsontoParse && convertJsontoParse.additionalamenities
              ? convertJsontoParse.additionalamenities
              : additionalAmenitiesData,

          additionalservice:
            convertJsontoParse && convertJsontoParse.additionalservice
              ? convertJsontoParse.additionalservice
              : additionalServiceData,
        }}
        onSubmit={values => {
          allAmenitiesList.forEach((val, i) => {
            if (eventsValue.indexOf(val.id) === -1) {
              let idNmae = val.idName;
              values[idNmae] = undefined;
            }
          });

          let {
            amenities,
            generalservice,
            generalamenities,
            meetingamenities,
            corporateamenities,
            partiesamenities,
            cookingamenities,
            videoamenities,
            photoamenities,
            weddingamenities,
            performanceamenities,
            cocktailamenities,
            workshopsamenities,
            reatailamenities,
            audioamenities,
            additionalservice,
            additionalamenities,
          } = values;

          const filterAmenites = additionalamenities
            ? additionalamenities.flatMap((val, i) => {
                const filteroOptions = additionalOptions.filter((filterVal, i) => {
                  return filterVal.key === val;
                });
                return filteroOptions;
              })
            : null;

          let meetingsArray = [],
            corporateArray = [],
            partiesArray = [],
            cookingArray = [],
            videoArray = [],
            photoArray = [],
            weddingArray = [],
            performanceArray = [],
            cocktailArray = [],
            workshopsArray = [],
            reatailArray = [],
            audioArray = [],
            allAmentiesArray = [];

          filterAmenites
            ? eventsValue.map((eventsname, index) => {
                filterAmenites.filter((options, ind) => {
                  if (options.eventName.includes(eventsname)) {
                    switch (eventsname) {
                      case 'meeting':
                        meetingsArray.push(`meetings_${options.key}`);
                        allAmentiesArray.push(`meetings_${options.key}`);
                        break;

                      case 'corporate_events':
                        corporateArray.push(`corporate_${options.key}`);
                        allAmentiesArray.push(`corporate_${options.key}`);
                        break;

                      case 'cooking_food_events':
                        cookingArray.push(`cooking_${options.key}`);
                        allAmentiesArray.push(`cooking_${options.key}`);
                        break;

                      case 'video_shoots':
                        videoArray.push(`video_${options.key}`);
                        allAmentiesArray.push(`video_${options.key}`);
                        break;

                      case 'photo_shoots':
                        photoArray.push(`photo_${options.key}`);
                        allAmentiesArray.push(`photo_${options.key}`);
                        break;

                      case 'parties_celebration':
                        partiesArray.push(`parties_${options.key}`);
                        allAmentiesArray.push(`parties_${options.key}`);
                        break;

                      case 'wedding':
                        weddingArray.push(`wedding_${options.key}`);
                        allAmentiesArray.push(`wedding_${options.key}`);
                        break;

                      case 'performance':
                        performanceArray.push(`performances_${options.key}`);
                        allAmentiesArray.push(`performances_${options.key}`);
                        break;

                      case 'cocktail':
                        cocktailArray.push(`cocktail_${options.key}`);
                        allAmentiesArray.push(`cocktail_${options.key}`);
                        break;

                      case 'workshop':
                        workshopsArray.push(`workshop_${options.key}`);
                        allAmentiesArray.push(`workshop_${options.key}`);
                        break;

                      case 'retail_popups':
                        reatailArray.push(`reatil_${options.key}`);
                        allAmentiesArray.push(`reatil_${options.key}`);
                        break;

                      case 'audio_recordings':
                        audioArray.push(`audio_${options.key}`);
                        allAmentiesArray.push(`audio_${options.key}`);
                        break;

                      default:
                        break;
                    }
                  }
                });
              })
            : null;

          let combineAmenitiesArray = [...meetingsArray, corporateArray, cookingArray];

          const arr = Object.entries(values);
          let combineAllAmenitiesArray = [...allAmentiesArray, ...generalamenities];
          let newValue = [];
          for (const [key, value] of arr) {
            if (key.includes('amenities')) {
              value && newValue.push(...value);
            }
          }

          const removeRepeatedValues = newValue ? [...new Set(newValue)] : [];
          const updatedValues = {
            publicData: {
              amenities: combineAllAmenitiesArray,
              generalservice,
              generalamenities,
              meetingamenities: meetingsArray,
              corporateamenities: corporateArray,
              partiesamenities: partiesArray,
              cookingamenities: cookingArray,
              videoamenities: videoArray,
              photoamenities: photoArray,
              weddingamenities: weddingArray,
              performanceamenities: performanceArray,
              cocktailamenities: cocktailArray,
              workshopsamenities: workshopsArray,
              reatailamenities: reatailArray,
              audioamenities: audioArray,
              additionalservice,
              additionalamenities,
            },
          };

          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingAmenitiesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingAmenitiesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAmenitiesPanel;
