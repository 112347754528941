import React, { Component, useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import config from '../../config';
import useEmblaCarousel from 'embla-carousel-react';
import { useHistory } from 'react-router-dom';

import { BiChevronLeft } from 'react-icons/bi';
import { BiChevronRight } from 'react-icons/bi';


import EditListingWizardTab, {
  DESCRIPTION,
  DETAILS,
  AMENITIES,
  LOCATION,
  LOCATIONRULES,
  POLICIES,
  PRICING,
  AVAILABILITY,
  PHOTOS,
} from './EditListingWizardTab';
import css from './EditListingWizard.module.css';

const availabilityMaybe = config.enableAvailability ? [AVAILABILITY] : [];
let hasScrolledToTab = false;
let tabsValue;

export const TABS = [
  DESCRIPTION,
  DETAILS,
  AMENITIES,
  LOCATION,
  LOCATIONRULES,
  POLICIES,
  PRICING,
  ...availabilityMaybe,
  PHOTOS,
];



const EditlistingWizardMob = props => {
  const {
    intl,
    viewport,
    id,
    tabsStatus,
    isNewListingFlow,
    params,
    listing,
    marketplaceTabs,
    errors,
    NamedLink,
    ...rest
  } = props;

  let  pathname  = location.pathname.split("/");
  let lastField = pathname[pathname.length - 1];
  useEffect(()=>{
    const allCurretnTab = document.querySelectorAll(`.${css.currenttab}`) ? Array.from(document.querySelectorAll(`.${css.currenttab}`)) : null;
    allCurretnTab && allCurretnTab.forEach((val)=>{
      val.classList.remove(`${css.currenttab}`);
    })
    document.querySelector(`#${lastField}`) ? document.querySelector(`#${lastField}`).classList.add(`${css.currenttab}`) : null;
  },[lastField])




  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    slidesToScroll: 3,
    // inViewThreshold: 0.5,
    align: 0
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const tabLink = tab => {
    return { name: 'EditListingPage', params: { ...params, tab } };
  };

  const scrollPrev = useCallback(() => {
    if(emblaApi.canScrollNext()){
      document.querySelector("#previousCarouselBtn").style.display = "none"
    }

    emblaApi && emblaApi.scrollPrev();
  }, [emblaApi]);


  const scrollNext = useCallback(() => {
    if(emblaApi.canScrollNext()){
      document.querySelector("#previousCarouselBtn").style.display = "block"
    }
    emblaApi && emblaApi.scrollNext()}, [emblaApi]);

  return (
    <>
   
      <div className={css.stepWrap}>
        <div className={css.embla}>
          <div className={css.embla__viewport} ref={emblaRef}>
            <div className={css.embla__container}>
              {TABS.map((tab, i) => {
                const disableFlag = isNewListingFlow && !tabsStatus[tab];
                const { name, params } = tabLink(tab);
                return (
                  <p
                    id={`${tab}_${i}`}
                    key={`${tab}_${i}`}
                    className={disableFlag ? `${css.disabled}` : `${css.successedtab}`}
                  >
                    <NamedLink
                      name={name}
                      params={params}
                      disabled={isNewListingFlow && !tabsStatus[tab]}
                      title={tab}
                    >
                      {tab == "VenueRules" ? "Venue Rules" : tab}
                    </NamedLink>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      

      <BiChevronLeft
        className={css.prevBtn}
        size={32}
        onClick={scrollPrev}
        disabled={!prevBtnEnabled}
        id={"previousCarouselBtn"}
      />
      <BiChevronRight
        className={css.nextBtn}
        size={32}
        onClick={scrollNext}
        disabled={!nextBtnEnabled}
        id={"nextCarouselBtn"}
      />

      
    </>
  );
};

export default EditlistingWizardMob;
