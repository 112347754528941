import React from 'react';
import { FieldCheckboxGroup } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';

import css from '../EditListingFeaturesForm/EditListingFeaturesForm.module.css';
import DetailsFieldCheckboxGroup from '../../components/FieldCheckboxGroup/DetailsFieldCheckboxGroup';
import { nonEmptyArray } from "../../util/validators"
const CheckBoxFieldDescription = props => {
  const {
    id,
    name,
    optionName,
    intl,
    filterConfig,
    labelName,
    composeValidators,
    required,
    requiredName,
    handleChild,
    updateDetailsTabPublicData,
    eventsData,
    events,
  } = props;

  // const subCheckBoxOptions = document.querySelectorAll(".subCheckbox li input");
  // const subCheckboxMain = document.querySelector(".subCheckbox")?.previousElementSibling.firstChild;
  // // console.log(subCheckboxMain)

  // subCheckboxMain && subCheckboxMain.addEventListener("click",(e)=>{
  //   if(e.target.checked){
  //     console.log(subCheckBoxOptions)
  //     subCheckBoxOptions.forEach((val)=>{
  //       return val.checked = e.target.checked;

  //     })
  //   }
  // })

  const filteredAmenitesData = props.filterSpecificAmenities ? props.filterSpecificAmenities : null;
  const options = findOptionsForSelectFilter(optionName, filterConfig);
  const checkBoxEventsFlag = nonEmptyArray(requiredName,events);
  // console.log(checkBoxEventsFlag,events)
  return (
    <>
      <DetailsFieldCheckboxGroup
        className={css.features}
        id={id}
        name={name}
        options={filteredAmenitesData ? filteredAmenitesData : options}
        label={labelName}
        // validate={composeValidators(required(requiredName,checkBoxEventsFlag))}
        validate={composeValidators(required(requiredName),checkBoxEventsFlag)}
        impfield={true}
        updateDetailsTabPublicData={updateDetailsTabPublicData}
        eventsData={eventsData}
      />
    </>
  );
};

export default CheckBoxFieldDescription;
