import React, { Component } from 'react';
import { func, object, string, array } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { intlShape } from '../../util/reactIntl';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import {
  getStartHours,
  getEndHours,
  isInRange,
  isSameDate,
  isDayMomentInsideRange,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timestampToDateFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  dateIsAfter,
  findNextBoundary,
  timestampToDate,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  differenceHoursTimeZone,
  endOfMonth,
  isSameDay,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput, FieldSelect, IconSpinner } from '../../components';
import { IoCloseOutline, IoCheckmarkOutline } from 'react-icons/io5';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import css from './FieldDateAndTimeInput.module.css';

// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

const TODAY = new Date();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const weekDaysList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }

  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);
  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);
    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;
    const hours = getStartHours(intl, timeZone, startLimit, endLimit);
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

const customGetAvailableStartTimes = (
  intl,
  timeZone,
  timeSlotsOnSelectedDate,
  bookingStart,
  customTimeSlots,
  customAvalEntries
) => {
  if (
    timeSlotsOnSelectedDate.length === 0 ||
    !timeSlotsOnSelectedDate[0] ||
    !bookingStart ||
    !customTimeSlots
  ) {
    return [];
  }
  let customStartTime, customEndtime;
  const duplicateBookingDate = resetToStartOfDay(new Date(bookingStart), timeZone);
  const getWeekBookingStart = weekDaysList[bookingStart.getDay()];

  // To filter the specific day from the checkavail and customAvil
  const getStartEndtimeOftheDay = customAvalEntries.filter((val, i) => {
    const duplicateVal = { ...val };

    duplicateVal.dayOfWeek =
      duplicateVal.dayOfWeek.charAt(0).toUpperCase() + duplicateVal.dayOfWeek.slice(1);
    if (duplicateVal.dayOfWeek == getWeekBookingStart) {
      return duplicateVal;
    }
  });
  const differnceHorus = differenceHoursTimeZone(timeZone);
  // To get CustomAvailHours List
  const customAllHours = getStartEndtimeOftheDay.reduce((customAvailHours, t, i) => {
    customStartTime = new Date(
      duplicateBookingDate.setHours(Number(t.startTime.split(':')[0]) + differnceHorus)
    );
    // To get full endTime onlist we have added 1 to the below customendtime

    if (t.endTime.split(':')[0] == '00') {
      customEndtime = resetToStartOfDay(duplicateBookingDate, timeZone, 1);
    } else {
      customEndtime = new Date(
        duplicateBookingDate.setHours(Number(t.endTime.split(':')[0]) + differnceHorus + 1)
      );
    }
    const customHours = getStartHours(intl, timeZone, customStartTime, customEndtime);

    return customAvailHours.concat(customHours);
  }, []);
  return customAllHours;
};

const convert24hrto12hr = function(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
    time[0] = time[0] <= 9 ? '0' + time[0] : time[0];
  }
  return time.join(''); // return adjusted time or original string
};

const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDateFromLocalToTimeZone(bookingStartTime, timeZone);

  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);

  let startLimit;
  let endLimit;

  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, timeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }

  return getEndHours(intl, timeZone, startLimit, endLimit);
};

const getTimeSlots = (timeSlots, date, timeZone, bookingType = null) => {
  let slotsList =
    timeSlots && timeSlots[0]
      ? timeSlots.filter(t =>
          isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone)
        )
      : [];
  if (bookingType && bookingType === 'dailyBooking' && slotsList.length === 0) {
    slotsList =
      timeSlots && timeSlots[0]
        ? timeSlots.filter(t =>
            isInRange(t.attributes.start, t.attributes.start, t.attributes.end, 'day', timeZone)
          )
        : [];
  }
  return slotsList;
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlots,
  startDate,
  selectedStartTime,
  selectedEndDate,
  bookingType = null
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlots, startDate, timeZone)
      );

  // Value selectedStartTime is a string when user has selected it through the form.
  // That's why we need to convert also the timestamp we use as a default
  // value to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp.toString()
    : null;

  const startTimeAsDate = startTime
    ? timestampToDateFromLocalToTimeZone(startTime, timeZone)
    : null;
  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  let selectedTimeSlot = timeSlots.find(t =>
    isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
  );

  if (!selectedTimeSlot && bookingType && bookingType === 'dailyBooking') {
    selectedTimeSlot = timeSlots.find(t =>
      isInRange(t.attributes.start, t.attributes.start, t.attributes.end)
    );
  }

  const endTimes = getAvailableEndTimes(intl, timeZone, startTime, endDate, selectedTimeSlot);

  // We need to convert the timestamp we use as a default value
  // for endTime to string for consistency. This is expected later when we
  // want to compare the sartTime and endTime.
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp
      ? endTimes[0].timestamp.toString()
      : null;
  return { startTime, endDate, endTimes, endTime, selectedTimeSlot };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
      bookingTypeofForm: props.values.bookingType,
      nextMonthSlotFlag: true,
      isOutside: false,
      monthLastDateList: [],
      currentMonthName: '',
      fetchCalendarEndDateProgess: false,
      fetchCalendarStartDateProgress: false,
      nextMonth1: '',
      nextMonth2: '',
      nextMonth3: '',
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.onBookingEndTimeChange = this.onBookingEndTimeChange.bind(this);
    this.onBookingEndDateChange = this.onBookingEndDateChange.bind(this);
    this.confirmHours = this.confirmHours.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.updatedCustomValueFn = this.updatedCustomValueFn.bind(this);
    this.lastDateOfMonth = this.lastDateOfMonth.bind(this);
    this.timeSlotSFilterfn = this.timeSlotSFilterfn.bind(this);
  }

  confirmHours = function(flag) {
    const {
      lineItems,
      unitType,
      form,
      values,
      currentListing,
      intl,
      monthlyTimeSlots,
      timeZone,
    } = this.props;
    const bookingEndTimeVal = values.bookingEndTime;
    const bookingStartTimeVal = values.bookingStartTime;
    const minHoursVal = Number(currentListing.minhours);

    if (flag) {
      let changeEndTime = new Date(Number(bookingStartTimeVal));
      let hoursCalculate = changeEndTime.getHours() + minHoursVal;
      document.querySelector(`.${css.popUpwrap}`).style.display = 'block';
      form.batch(() => {
        form.change('bookingEndTime', changeEndTime.setHours(hoursCalculate));
        form.change('minHoursValFlag', flag);
        form.change('bookingChange', null);
      });
    } else {
      document.querySelector(`.${css.popUpwrap}`).style.display = 'none';
      form.batch(() => {
        form.change('bookingEndTime', null);
        form.change('minHoursValFlag', flag);
        form.change('bookingChange', null);
      });
    }
  };

  lastDateOfMonth(date, timeZone) {
    let monthOfDate = date.getMonth();
    let yearOfDate = date.getFullYear();
    let getLastDateOfMonth = endOfMonth(yearOfDate, monthOfDate);
    getLastDateOfMonth = resetToStartOfDay(new Date(getLastDateOfMonth), timeZone);
    return getLastDateOfMonth;
  }

  updatedCustomValueFn(entries) {
    const entriesUpdatedVal = entries.map((val, i) => {
      const duplicateObj = { ...val };
      duplicateObj.dayOfWeek =
        duplicateObj.dayOfWeek.charAt(0).toUpperCase() + duplicateObj.dayOfWeek.slice(1);
      duplicateObj.endTime = convert24hrto12hr(duplicateObj.endTime);
      duplicateObj.startTime = convert24hrto12hr(duplicateObj.startTime);
      return duplicateObj;
    });
    return entriesUpdatedVal;
  }

  fetchMonthData(date, endCalendarMonth) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);
    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn, endCalendarMonth = null) {
    const { onMonthChanged, timeZone, bookingType } = this.props;
    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.

        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone), endCalendarMonth);

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, endCalendarMonth);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = value => {
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      customAvailabilityPlan,
      currentListing,
      bookingType,
    } = this.props;
    let customCheckAvailInner = [...customAvailabilityPlan.entries];
    let customUpdatedEntreis = this.updatedCustomValueFn(customCheckAvailInner);
    let customAutoStartEndDate = [];
    const { minhours, maxhours } = currentListing;

    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingStartTime', null);
        form.change('bookingEndDate', { date: null });
        form.change('bookingEndTime', null);
        form.change('DefaultBookinStartTime', null);
        form.change('customBookinStartTime', null);
        form.change('DefaultBookingEndTime', null);
        form.change('customBookingEndTime', null);
        form.change('currentBookingStartTime', null);
        form.change('currentBookingEndTime', null);
        form.change('isSlotLessThenMinhours', null);
        form.change('isSlotLessThenMaxhours', null);
        form.change('bookingChange', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, timeZone) });

      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone, bookingType);
    const { startTime, endDate, endTime, endTimes } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate
    );

    let customTimeStampTime = customGetAvailableStartTimes(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      customUpdatedEntreis,
      customAvailabilityPlan ? customAvailabilityPlan.entries : []
    );

    let customAvailableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      startDate,
      timeSlotsOnSelectedDate
    );

    for (let i = 0; i < customAvailableStartTimes.length; i++) {
      for (let j = 0; j < customTimeStampTime.length; j++) {
        if (customAvailableStartTimes[i].timestamp == customTimeStampTime[j].timestamp) {
          customAutoStartEndDate.push(customTimeStampTime[j]);
        }
      }
    }

    let newStartDate = new Date(startDate);
    let getMonth = newStartDate.getMonth();
    let getYear = newStartDate.getFullYear();
    let lastDateofMonth = endOfMonth(getYear, getMonth);
    lastDateofMonth = resetToStartOfDay(new Date(lastDateofMonth), timeZone);
    lastDateofMonth = new Date(lastDateofMonth);

    let nextMonthName1 = new Date(lastDateofMonth).setMonth(getMonth + 1);
    nextMonthName1 = timestampToDateFromLocalToTimeZone(nextMonthName1, timeZone);
    nextMonthName1 = this.lastDateOfMonth(nextMonthName1, timeZone, timeZone);
    let nextMonthName2 = new Date(lastDateofMonth).setMonth(getMonth + 2);
    nextMonthName2 = timestampToDateFromLocalToTimeZone(nextMonthName2, timeZone);
    nextMonthName2 = this.lastDateOfMonth(nextMonthName2, timeZone, timeZone);
    let nextMonthName3 = new Date(lastDateofMonth).setMonth(getMonth + 3);
    nextMonthName3 = timestampToDateFromLocalToTimeZone(nextMonthName3, timeZone);
    nextMonthName3 = this.lastDateOfMonth(nextMonthName3, timeZone, timeZone);

    this.setState({ currentMonthName: lastDateofMonth });
    this.setState({ nextMonth1: nextMonthName1 });
    this.setState({ nextMonth2: nextMonthName2 });
    this.setState({ nextMonth3: nextMonthName3 });

    this.setState({
      monthLastDateList: [lastDateofMonth, nextMonthName1, nextMonthName2, nextMonthName3],
    });

    const indexOfEndTimeSlot = customAutoStartEndDate.findIndex(val => {
      if (customAutoStartEndDate[minhours]) {
        return customAutoStartEndDate[minhours].timestamp == val.timestamp;
      } else {
        return -1;
      }
    });

    let endTimeSlotLength = [...customAutoStartEndDate];
    endTimeSlotLength = endTimeSlotLength.slice(indexOfEndTimeSlot + 1)
      ? endTimeSlotLength.slice(indexOfEndTimeSlot + 1).length
      : null;
    const isRemainingSlotisLesserThenMaxHours = endTimeSlotLength
      ? minhours > endTimeSlotLength
        ? customAutoStartEndDate[customAutoStartEndDate.length - 1].timestamp
        : null
      : null;

    form.batch(() => {
      form.change('bookingStartTime', customAutoStartEndDate[0].timestamp);
      form.change('bookingEndDate', { date: endDate });
      form.change('bookingChange', null);
      form.change(
        'bookingEndTime',
        bookingType == 'dailyBooking'
          ? customAutoStartEndDate[customAutoStartEndDate.length - 1].timestamp
          : null
      );
      form.change('DefaultBookinStartTime', customAvailableStartTimes[0].timestamp);
      form.change('customBookinStartTime', customTimeStampTime[0].timestamp);
      form.change('DefaultBookingEndTime', endTimes[endTimes.length - 1].timestamp);
      form.change(
        'customBookingEndTime',
        customTimeStampTime[customTimeStampTime.length - 1].timestamp
      );
      form.change('currentBookingStartTime', customAutoStartEndDate[0].timestamp);
      form.change('isSlotLessThenMinhours', null);
      form.change('isSlotLessThenMaxhours', isRemainingSlotisLesserThenMaxHours);
      form.change(
        'currentBookingEndTime',
        bookingType == 'dailyBooking'
          ? customAutoStartEndDate[customAutoStartEndDate.length - 1].timestamp
          : null
      );
    });
  };

  onBookingStartTimeChange = value => {
    let customAutoStartEndDate = [];
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      values,
      currentListing,
      customAvailabilityPlan,
    } = this.props;

    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const startDate = values.bookingStartDate.date;
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);
    let customCheckAvailInner = [...customAvailabilityPlan.entries];
    let customUpdatedEntreis = this.updatedCustomValueFn(customCheckAvailInner);
    const { minhours } = currentListing;

    const { startTime, endDate, endTime, endTimes } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      value
    );

    let customTimeStampTime = customGetAvailableStartTimes(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      customUpdatedEntreis,
      customAvailabilityPlan ? customAvailabilityPlan.entries : []
    );

    let customAvailableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      startDate,
      timeSlotsOnSelectedDate
    );

    for (let i = 0; i < customAvailableStartTimes.length; i++) {
      for (let j = 0; j < customTimeStampTime.length; j++) {
        if (customAvailableStartTimes[i].timestamp == customTimeStampTime[j].timestamp) {
          customAutoStartEndDate.push(customTimeStampTime[j]);
        }
      }
    }

    const findIndexOfstartTime = customAutoStartEndDate.findIndex(val => {
      if (startTime) {
        return startTime == val.timestamp;
      } else {
        return -1;
      }
    });

    const isRemainingSlotisLesserThenMinHours =
      minhours >= findIndexOfstartTime ? customAutoStartEndDate[0].timestamp : null;

    const indexOfEndTimeSlot = customAutoStartEndDate.findIndex(val => {
      if (endTimes[minhours - 1]) {
        return endTimes[minhours - 1].timestamp == val.timestamp;
      } else {
        return -1;
      }
    });

    let endTimeSlotLength = [...customAutoStartEndDate];
    endTimeSlotLength = endTimeSlotLength.slice(indexOfEndTimeSlot + 1)
      ? endTimeSlotLength.slice(indexOfEndTimeSlot + 1).length
      : null;
    const isRemainingSlotisLesserThenMaxHours = endTimeSlotLength
      ? minhours > endTimeSlotLength
        ? customAutoStartEndDate[customAutoStartEndDate.length - 1].timestamp
        : null
      : null;

    form.batch(() => {
      form.change('bookingEndDate', { date: endDate });
      form.change('bookingEndTime', null);
      form.change('CustombookingEndTime', null);
      form.change('currentBookingStartTime', startTime);
      form.change('currentBookingEndTime', null);
      form.change('isSlotLessThenMinhours', isRemainingSlotisLesserThenMinHours);
      form.change('isSlotLessThenMaxhours', isRemainingSlotisLesserThenMaxHours);
      form.change('bookingChange', null);
    });
  };

  onBookingEndTimeChange = value => {
    let customAutoStartEndDate = [];
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      values,
      currentListing,
      customAvailabilityPlan,
    } = this.props;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const startDate = values.bookingStartDate.date;
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);
    let customCheckAvailInner = [...customAvailabilityPlan.entries];
    let customUpdatedEntreis = this.updatedCustomValueFn(customCheckAvailInner);
    const { minhours } = currentListing;

    const { startTime, endDate, endTime, endTimes } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      value
    );

    let customTimeStampTime = customGetAvailableStartTimes(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      customUpdatedEntreis,
      customAvailabilityPlan ? customAvailabilityPlan.entries : []
    );

    let customAvailableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      startDate,
      timeSlotsOnSelectedDate
    );

    for (let i = 0; i < customAvailableStartTimes.length; i++) {
      for (let j = 0; j < customTimeStampTime.length; j++) {
        if (customAvailableStartTimes[i].timestamp == customTimeStampTime[j].timestamp) {
          customAutoStartEndDate.push(customTimeStampTime[j]);
        }
      }
    }

    const indexOfEndTimeSlot = customAutoStartEndDate.findIndex(val => {
      if (value) {
        return value == val.timestamp;
      }
    });

    let endTimeSlotLength = [...customAutoStartEndDate];
    endTimeSlotLength = endTimeSlotLength.slice(indexOfEndTimeSlot + 1)
      ? endTimeSlotLength.slice(indexOfEndTimeSlot + 1).length
      : null;
    let isRemainingSlotisLesserThenMaxHours = endTimeSlotLength
      ? minhours > endTimeSlotLength
        ? customAutoStartEndDate[customAutoStartEndDate.length - 1].timestamp
        : null
      : null;
    isRemainingSlotisLesserThenMaxHours =
      !isRemainingSlotisLesserThenMaxHours && endTimes.length === 1
        ? endTimes[endTimes.length - 1].timestamp
        : isRemainingSlotisLesserThenMaxHours;
    form.batch(() => {
      form.change('currentBookingEndTime', value);
      form.change('bookingEndTime', value);
      form.change('CustombookingEndTime', value);

      form.change('isSlotLessThenMaxhours', isRemainingSlotisLesserThenMaxHours);
      form.change('bookingChange', null);
    });
  };

  onBookingEndDateChange = value => {
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      values,
      customAvailabilityPlan,
      bookingType,
    } = this.props;

    let customCheckAvailInner = [...customAvailabilityPlan.entries];
    let customUpdatedEntreis = this.updatedCustomValueFn(customCheckAvailInner);
    let customAutoStartEndDate = [];

    if (!value || !value.date) {
      form.change('bookingEndTime', null);
      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const endDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);

    const { bookingStartDate, bookingStartTime, bookingEndTime } = values;
    const startDate = bookingStartDate.date;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone, bookingType);

    // const { endTime, endTimes } = getAllTimeValues(
    //   intl,
    //   timeZone,
    //   timeSlotsOnSelectedDate,
    //   startDate,
    //   bookingStartTime,
    //   endDate
    // );

    const { endTime, endTimes } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      bookingEndTime,
      endDate,
      bookingType
    );

    let customTimeStampTime = customGetAvailableStartTimes(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      endDate,
      customUpdatedEntreis,
      customAvailabilityPlan ? customAvailabilityPlan.entries : []
    );

    let customAvailableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      startDate,
      timeSlotsOnSelectedDate
    );

    for (let i = 0; i < endTimes.length; i++) {
      for (let j = 0; j < customTimeStampTime.length; j++) {
        if (endTimes[i].timestamp == customTimeStampTime[j].timestamp) {
          customAutoStartEndDate.push(endTimes[j]);
        }
      }
    }

    form.batch(() => {
      form.change(
        'bookingEndTime',
        bookingType == 'dailyBooking'
          ? customTimeStampTime[customTimeStampTime.length - 1].timestamp
          : customTimeStampTime[1].timestamp
      );
      form.change(
        'currentBookingEndTime',
        customTimeStampTime[customTimeStampTime.length - 1].timestamp
      );
      form.change('dailyEndTime', endTimes[endTimes.length - 1].timestamp);
      form.change('bookingChange', null);
    });
  };

  timeSlotSFilterfn(
    localizedPreviouDate,
    slotList,
    timeZone,
    startDate,
    selectedStartTime,
    bookingStartDate
  ) {
    const { intl, bookingType } = this.props;
    let slotsFlag;

    const startTimes = selectedStartTime
      ? []
      : getAvailableStartTimes(
          intl,
          timeZone,
          bookingStartDate,
          getTimeSlots(slotList, bookingStartDate, timeZone)
        );

    const startTime = selectedStartTime
      ? selectedStartTime
      : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
      ? startTimes[0].timestamp.toString()
      : null;

    const startTimeAsDate = startTime
      ? timestampToDateFromLocalToTimeZone(startTime, timeZone)
      : null;

    let selectedTimeSlots = slotList.find(t =>
      isInRange(startTimeAsDate, t.attributes.start, t.attributes.end, 'day')
    );
    selectedTimeSlots =
      !selectedTimeSlots &&
      slotList.find(t => isSameDay(startTimeAsDate, t.attributes.end, timeZone));
    if (!selectedTimeSlots && bookingType && bookingType === 'dailyBooking') {
      selectedTimeSlots = slotList.find(t =>
        isInRange(t.attributes.start, t.attributes.start, t.attributes.end, 'day')
      );
    }

    const slotStart = selectedTimeSlots
      ? new Date(selectedTimeSlots.attributes.start.getTime() - 1)
      : null;
    const preStartDate = slotStart ? resetToStartOfDay(slotStart, timeZone, 1) : null;
    const isStartDatecomesOnmonth = dateIsAfter(preStartDate, startDate);
    const arraySlot = [selectedTimeSlots];

    const slots = arraySlot.map((val, index) => {
      const PrevInclusiveEnd = val ? new Date(val.attributes.end.getTime() - 1) : null;
      // Given day (endDate) should be before the "next" day of selected timeSlots end.
      const prevEndDate = PrevInclusiveEnd
        ? resetToStartOfDay(PrevInclusiveEnd, timeZone, 1)
        : null;
      let flagValue = prevEndDate
        ? !(
            dateIsAfter(localizedPreviouDate, startDate) &&
            dateIsAfter(prevEndDate, localizedPreviouDate)
          )
        : null;
      return flagValue;
    });
    slotsFlag = slots.includes(true);
    return slotsFlag;
  }

  isOutsideRange(
    day,
    bookingStartDate,
    selectedTimeSlot,
    timeZone,
    bookingEndDate,
    monthlyTimeSlots,
    firstAvailableStartTime
  ) {
    let monthslotLastFlag = false;
    if (!selectedTimeSlot) {
      return true;
    }

    let getMonthFromBokingStartDate = new Date(bookingStartDate).getMonth();
    let getMonthFromBookingStartDateFlag = getMonthFromBokingStartDate >= 9 ? true : false;
    let getYearFromBokingStartDate = new Date(bookingStartDate).getFullYear();
    let BokingStartDateYearMonth = getMonthFromBookingStartDateFlag
      ? `${getYearFromBokingStartDate}-${getMonthFromBokingStartDate + 1}`
      : `${getYearFromBokingStartDate}-0${getMonthFromBokingStartDate + 1}`;

    let currentMonthDate = this.state.currentMonth;
    let getMonth = new Date(currentMonthDate).getMonth();
    let getMonthFlag = getMonth >= 9 ? true : false;
    let getFullyear = new Date(currentMonthDate).getFullYear();
    let currentYearMonth = getMonthFlag
      ? `${getFullyear}-${getMonth + 1}`
      : `${getFullyear}-0${getMonth + 1}`;

    let montthlyList = Object.keys(monthlyTimeSlots);
    let findIndexOfstartDate = montthlyList.indexOf(BokingStartDateYearMonth);
    montthlyList = montthlyList.slice(findIndexOfstartDate);

    let findIndexOfCurrentMonthonList = montthlyList.indexOf(currentYearMonth);
    montthlyList = montthlyList.reduce((acc, val, index) => {
      if (index < findIndexOfCurrentMonthonList) {
        acc.push(monthlyTimeSlots[val]);
      }
      return acc;
    }, []);
    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    const resetBookingDate = resetToStartOfDay(bookingEndDate, timeZone);
    let bookingEndNewDate = new Date(this.state.currentMonth);

    let getMonthFromBookingEndDate = bookingEndNewDate.getMonth();
    let getYearFromBookingEndDate = bookingEndNewDate.getFullYear();
    let lastDateofMonth = endOfMonth(getYearFromBookingEndDate, getMonthFromBookingEndDate);
    lastDateofMonth = resetToStartOfDay(new Date(lastDateofMonth), timeZone);
    lastDateofMonth = new Date(lastDateofMonth);

    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    let monthListFlag = montthlyList.map((val, index) => {
      let localizedPreviouDate = this.state.monthLastDateList[index];
      // let timeSlotsList = val.timeSlots[0];
      let timeSlotsList = this.timeSlotSFilterfn(
        localizedPreviouDate,
        val.timeSlots,
        timeZone,
        startDate,
        firstAvailableStartTime,
        bookingStartDate
      );
      // const PrevInclusiveEnd = timeSlotsList
      //   ? new Date(timeSlotsList.attributes.end.getTime() - 1)
      //   : null;
      // // Given day (endDate) should be before the "next" day of selected timeSlots end.
      // const prevEndDate = PrevInclusiveEnd
      //   ? resetToStartOfDay(PrevInclusiveEnd, timeZone, 1)
      //   : null;
      // let flagValue = prevEndDate
      //   ? !(
      //       dateIsAfter(localizedPreviouDate, startDate) &&
      //       dateIsAfter(prevEndDate, localizedPreviouDate)
      //     )
      //   : null;
      return timeSlotsList;
    });
    let wrapMonthListFlag = monthListFlag.includes(true);
    if (wrapMonthListFlag) {
      return true;
    }
    return !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay));
  }

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      values,
      monthlyTimeSlots,
      timeZone,
      intl,
      customAvailabilityPlan,
      bookingType,
      lineItems,
      unitType,
      currentListing,
      bookingTypeHourlyFlag,
    } = this.props;

    const bookingChange = values.bookingChange;
    const customAvalEntries = customAvailabilityPlan ? [...customAvailabilityPlan.entries] : [];
    const updatedCustomValue = this.updatedCustomValueFn(customAvalEntries);
    const classes = classNames(rootClassName || css.root, className);
    const estimatedQuantity = lineItems
      ? lineItems.find(item => {
          return item.code === unitType;
        })
      : null;
    const quantityVal = estimatedQuantity ? estimatedQuantity.quantity.toString() : 0;
    const minHoursVal = Number(currentListing.minhours);
    const maxHoursVal = Number(currentListing.maxhours);

    const bookingEndDateClassName =
      bookingType === 'dailyBooking'
        ? classNames(css.field)
        : classNames(css.field, css.endDateHidden);

    const bookingTimeClassName =
      bookingType !== 'dailyBooking'
        ? classNames(css.field)
        : classNames(css.field, css.endDateHidden);
    const bookingStartDate =
      values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;
    const bookingStartTime = values.bookingStartTime ? values.bookingStartTime : null;
    const bookingEndDate =
      values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;
    const bookingEndTimes = values.bookingEndTime ? values.bookingEndTime : null;
    const startTimeDisabled = !bookingStartDate;
    const endDateDisabled = !bookingStartDate || !bookingStartTime;
    const endTimeDisabled = !bookingStartDate || !bookingStartTime || !bookingEndDate;

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );

    const timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonth,
      bookingStartDate,
      timeZone,
      bookingType
    );

    const availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedDate
    );

    const customAvailtimesSlot = customGetAvailableStartTimes(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      updatedCustomValue,
      customAvailabilityPlan ? customAvailabilityPlan.entries : []
    );

    const isDayAndTimeEquals = (values, isStartTime) => {
      const duplicatetoListShowStart = [...customAvailtimesSlot];
      const lastMinHoursList = minHoursVal;
      duplicatetoListShowStart.splice(`-${lastMinHoursList}`);
      const listToshowStart = isStartTime ? duplicatetoListShowStart : customAvailtimesSlot;
      const checkIsEqual = listToshowStart.some(val => {
        return val.timestamp == values.timestamp;
      });
      return checkIsEqual;
    };

    const firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    const { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      bookingStartTime || firstAvailableStartTime,
      bookingEndDate || bookingStartDate,
      bookingType
    );

    const availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime || startTime,
      bookingEndDate || endDate,
      selectedTimeSlot
    );

    const isDayBlocked = timeSlotsOnSelectedMonth
      ? day => {
          const { timeZone, intl, customAvailabilityPlan } = this.props;
          const startOfDay = moment.tz(day.toArray().slice(0, 3), timeZone);
          const customStartDate = resetToStartOfDay(startOfDay, timeZone);
          if (bookingType == 'dailyBooking') {
            let customAutoStartEndDate = [];
            const timeSlotsOnSelectedDate = getTimeSlots(
              timeSlotsOnSelectedMonth,
              customStartDate,
              timeZone
            );
            let customCheckAvailInner = [...customAvailabilityPlan.entries];
            let customUpdatedEntreis = this.updatedCustomValueFn(customCheckAvailInner);

            let customTimeStampTime = customGetAvailableStartTimes(
              intl,
              timeZone,
              timeSlotsOnSelectedDate,
              customStartDate,
              customUpdatedEntreis,
              customAvailabilityPlan ? customAvailabilityPlan.entries : []
            );

            let customAvailableStartTimes = getAvailableStartTimes(
              intl,
              timeZone,
              customStartDate,
              timeSlotsOnSelectedDate
            );
            // Create a set of timestamps from customTimeStampTime
            const timeStampSet = new Set(_.map(customTimeStampTime, 'timestamp'));

            // Iterate through customAvailableStartTimes and push matching items
            _.forEach(customAvailableStartTimes, start => {
              if (timeStampSet.has(start.timestamp)) {
                const matchingItem = _.find(customTimeStampTime, { timestamp: start.timestamp });

                if (matchingItem) {
                  customAutoStartEndDate.push(matchingItem);
                }
              }
            });
            if (customAutoStartEndDate.length !== customTimeStampTime.length) {
              return true;
            } else {
              return !timeSlotsOnSelectedMonth.find(timeSlot =>
                isDayMomentInsideRange(
                  day,
                  timeSlot.attributes.start,
                  timeSlot.attributes.end,
                  timeZone
                )
              );
            }
          } else {
            return !timeSlotsOnSelectedMonth.find(timeSlot =>
              isDayMomentInsideRange(
                day,
                timeSlot.attributes.start,
                timeSlot.attributes.end,
                timeZone
              )
            );
          }
        }
      : () => false;

    const placeholderTime = localizeAndFormatTime(
      intl,
      timeZone,
      findNextBoundary(timeZone, TODAY)
    );

    const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.startTime' });
    const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.endTime' });
    /**
     * NOTE: In this template the field for the end date is hidden by default.
     * If you want to enable longer booking periods, showing the end date in the form requires some code changes:
     * 1. Move the bookingStartTime field to the same formRow with the bookingStartDate field
     * 2. Remove the div containing the line between dates
     * 3. Remove the css related to hiding the booking end date from the bottom of the FieldDateAndTimeInput.css field
     */

    return (
      <React.Fragment>
        <div
          className={
            bookingType == 'dailyBooking' ? `${classes} ${css.DailyBookingWrap}` : `${classes}`
          }
        >
          {/* <div className={css.formRow}> */}
          <div className={classNames(css.field, css.startDate)}>
            <FieldDateInput
              className={css.fieldDateInput}
              name="bookingStartDate"
              id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
              label={startDateInputProps.label}
              placeholderText={startDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked}
              onChange={this.onBookingStartDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              onClose={event =>
                this.setState({
                  currentMonth: getMonthStartInTimeZone(event?.date ?? TODAY, this.props.timeZone),
                })
              }
            />
          </div>

          <div className={bookingTimeClassName}>
            <FieldSelect
              name="bookingStartTime"
              id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
              className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
              selectClassName={bookingStartDate ? css.select : css.selectDisabled}
              label={startTimeLabel}
              disabled={startTimeDisabled}
              onChange={this.onBookingStartTimeChange}
            >
              <option disabled value="">
                Select Time
              </option>
              {bookingStartDate ? (
                availableStartTimes.map(p => (
                  <React.Fragment>
                    {isDayAndTimeEquals(p, true) ? (
                      <option key={p.timeOfDay} value={p.timestamp}>
                        {p.timeOfDay}
                      </option>
                    ) : (
                      <option key={p.timeOfDay} disabled="disable" value={p.timestamp}>
                        {p.timeOfDay}
                      </option>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <option>{placeholderTime}</option>
              )}
            </FieldSelect>
          </div>
          {/* </div> */}

          {/* <div className={css.formRow}> */}
          <div className={`${bookingEndDateClassName} ${css.endDate}`}>
            <FieldDateInput
              {...endDateInputProps}
              name="bookingEndDate"
              id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
              className={css.endDateCalenderMob}
              label={endDateInputProps.label}
              placeholderText={endDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked}
              onChange={this.onBookingEndDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn, 'endCalendarMonth')}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn, 'endCalendarMonth')}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              isOutsideRange={
                day =>
                  // setTimeout(() => {
                  this.isOutsideRange(
                    day,
                    bookingStartDate,
                    selectedTimeSlot,
                    timeZone,
                    bookingEndDate,
                    monthlyTimeSlots,
                    firstAvailableStartTime,
                    bookingStartDate
                  )

                // }, 0)
              }
              // onMonthChange = {(day)=>this.handleMonthChange(day)}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              disabled={endDateDisabled}
              showLabelAsDisabled={endDateDisabled}
            />
          </div>

          {/* <div className={bookingStartDate ? css.lineBetween : css.lineBetweenDisabled}>-</div> */}

          <div className={bookingTimeClassName}>
            <FieldSelect
              name="bookingEndTime"
              id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
              className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
              selectClassName={bookingStartDate ? css.select : css.selectDisabled}
              label={endTimeLabel}
              disabled={endTimeDisabled}
              onChange={this.onBookingEndTimeChange}
            >
              <option disabled value="">
                Select Time
              </option>
              {bookingStartDate && (bookingStartTime || startTime) ? (
                availableEndTimes.map(p => (
                  <React.Fragment>
                    {isDayAndTimeEquals(p, false) ? (
                      <option
                        key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                        value={p.timestamp}
                      >
                        {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                      </option>
                    ) : (
                      <option
                        disabled="disable"
                        key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                        value={p.timestamp}
                      >
                        {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                      </option>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <option>{placeholderTime}</option>
              )}
            </FieldSelect>
          </div>

          {/* </div> */}
        </div>
        <React.Fragment>
          {bookingEndTimes &&
          quantityVal > 0 &&
          quantityVal < minHoursVal &&
          bookingType === 'hourlyBooking' ? (
            <div className={css.popUpwrap}>
              <div className={css.backDrop}></div>
              <div className={css.hoursWarningWrap}>
                <div className={css.hoursWarningMsg}>
                  The hours should be longer than the minimum hours. We'll be charging for&nbsp;
                  {minHoursVal} hours. Would you like to continue ?
                </div>
                <p
                  className={css.yesWrap}
                  onClick={() => {
                    this.confirmHours(true);
                  }}
                >
                  <IoCheckmarkOutline size={24} className={css.YesNoIcon} /> Yes
                </p>
                <p
                  className={css.NoWrap}
                  onClick={() => {
                    this.confirmHours(false);
                  }}
                >
                  <IoCloseOutline size={24} className={css.YesNoIcon} /> No
                </p>
              </div>
            </div>
          ) : (
            ''
          )}

          {bookingChange === null &&
          quantityVal > 0 &&
          quantityVal > maxHoursVal &&
          quantityVal <= 23 &&
          bookingType === 'hourlyBooking' ? (
            <p className={css.hoursWarningMaxMsg}>The duration should be less than maximum hours</p>
          ) : (
            ''
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
  customAvailabilityPlan: null,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,
  customAvailabilityPlan: object,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;
