import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const FETCH_MYWISHLIST_REQUEST = 'app/ListingPage/FETCH_MYWISHLIST_REQUEST';
export const FETCH_MYWISHLIST_SUCCESS = 'app/ListingPage/FETCH_MYWISHLIST_SUCCESS';
export const FETCH_MYWISHLIST_ERROR = 'app/ListingPage/FETCH_MYWISHLIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  myListings: [],
  fetchMyWishListError: null,
  fetchMyWishListInProgress: false,
};

const myWishListPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MYWISHLIST_REQUEST:
      return { ...state, fetchMyWishListInProgress: true, fetchMyWishListError: null };
    case FETCH_MYWISHLIST_SUCCESS:
      return { ...state, fetchMyWishListInProgress: false, myListings: payload };
    case FETCH_MYWISHLIST_ERROR:
      return { ...state, fetchMyWishListInProgress: false, fetchMyWishListError: payload };

    default:
      return state;
  }
};

export default myWishListPageReducer;

// ================ Action creators ================ //

export const fetchMyWishListRequest = () => ({ type: FETCH_MYWISHLIST_REQUEST });
export const fetchMyWishListSuccess = myListings => ({
  type: FETCH_MYWISHLIST_SUCCESS,
  payload: myListings,
});
export const fetchMyWishListError = error => ({
  type: FETCH_MYWISHLIST_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const fetchMyListings = () => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const currentLikes = currentUser?.attributes?.profile?.privateData?.likedListings;
  if (!currentLikes || currentLikes.length === 0) {
    return null;
  }
  return sdk.listings
    .query({
      ids: currentLikes,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      // 'limit.images': 1,
    })
    .then(response => {
      const myListings = denormalisedResponseEntities(response);
      dispatch(fetchMyWishListSuccess(myListings));
    })
    .catch(e => {
      dispatch(fetchMyWishListError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  dispatch(fetchMyWishListRequest());
  return Promise.all([dispatch(fetchMyListings())]);
};
