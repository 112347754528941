import React, { useEffect, useRef, useState } from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { GetToKnowUsForm } from '../../forms';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './GetToKnowUs.module.css';
import config from '../../config';
import { notifyGetToKnowUsForm, recaptchaValidation } from '../../util/api';

export const GetToKnowUsComponent = props => {
  const { currentUser, intl } = props;
  const captachaRef = useRef('');
  const [progressFlag, setProgressFlag] = useState(false);
  const [readyFlag, setReadyFlag] = useState(false);
  const [message, setMessage] = useState('');
  const [captchaError, setcaptchaError] = useState('');
  const user = ensureCurrentUser(currentUser);
  const GetKonwIamOptions = findOptionsForSelectFilter('GetKonwIamOptions', config.custom.filters);
  const { attributes } = user;
  const { email: userEmail } = attributes;
  const initialFormState = {
    userName:'',
    userEmail: '',
    userPhone:'',
    messageField: '',
    listItemforSubject: '',
  };


  useEffect(() => {
    if (message && message.length > 0) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 10000);

      return () => clearTimeout(timer);
    }

    if (captchaError && captchaError.length > 0) {
      const timer = setTimeout(() => {
        setcaptchaError('');
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [message, captchaError]);


  const onafterResponseReceived = function(res,form) {
    const { message, success } = res;
    if(success){
      setMessage(message);
      form.restart(initialFormState);
    }
  };

  const handleSubmit = async (values,form) => {
    const { userEmail, listItemforSubject, messageField, userName, userPhone } = values;
    const filterSubject = GetKonwIamOptions.filter(val => {
      return val.key === listItemforSubject;
    });

    const updatedData = {
      fromMail: userEmail,
      userName,
      userPhone: userPhone.length !== 0 && userPhone,
      subject: filterSubject[0].label,
      message: messageField.length !== 0 && messageField,
      baseUri: window.location.origin,
    };

    const captachaToken = captachaRef.current.getValue();
    const captachVerifyFlag = await recaptchaValidation({ token: captachaToken }).then(res => {
      return res.response.success;
    });

    if (!captachVerifyFlag) {
      setcaptchaError(`Please re-verify that you're not a robot below`);
      return;
    }
    setProgressFlag(true);
    return notifyGetToKnowUsForm({
      data: updatedData,
    })
      .then(res => {
        setProgressFlag(false);
        setReadyFlag(true);
        onafterResponseReceived(res,form);
      })
      .catch(error => {
        console.error(error);
      });
  };


  return (
          <div className={css.container}>
            <div className={css.content}>
              {message && (
                <div className={css.message}>
                  <p>{message}</p>
                </div>
              )}

              {captchaError &&(
                <div className={css.captchamessage}>
                <p>{captchaError}</p>
              </div>
              )}

              <GetToKnowUsForm
                initialFormState={initialFormState}
                GetKonwIamOptions={GetKonwIamOptions}
                onSubmit={handleSubmit}
                intl={intl}
                progressFlag={progressFlag}
                readyFlag={readyFlag}
                captachaRef={captachaRef}
              />
            </div>
          </div>
  );
};

GetToKnowUsComponent.defaultProps = {
  currentUser: null,
};

GetToKnowUsComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  onChange: func.isRequired,
  onSubmitContactUsDetails: func.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, currentUserListing } = state.user;
  return {
    currentUser,
    currentUserListing,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(saveContactUsDetailsClear()),
  onSubmitContactUsDetails: values => dispatch(saveContactUsDetails(values)),
});

const GetToKnowUs = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(GetToKnowUsComponent);

export default GetToKnowUs;
