import React, { useRef, useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import css from './CustomFileUploadField.module.css';
import { TiDeleteOutline } from 'react-icons/ti';
import { LiaEdit } from 'react-icons/lia';

const CustomFileUploadField = ({
  name,
  label,
  className,
  labelClassName,
  inProgress,
  fileUplodErr,
  quoteHeading,
  isProvider,
}) => {
  const fileInputRef = useRef(null);
  const attachmentWrapRef = useRef(null);
  const fileNameRef = useRef(null);
  const [file, setFile] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);

  useEffect(() => {
    if (!inProgress) {
      fileInputRef.current.value = '';
      fileNameRef.current.textContent = '';
      attachmentWrapRef.current.classList.remove(`${css.activeAttach}`);
      setFile(false);
      const timer = setTimeout(() => {
        setFileSizeError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [inProgress, fileSizeError]);
  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    const convertBytestoMb = file.size / 1024 ** 2;
    if (convertBytestoMb > 20) {
      setFileSizeError(true);
      return false;
    }
    setFile(true);
    input.onChange(file);
    fileNameRef.current.textContent = file.name;
    attachmentWrapRef.current.classList.add(`${css.activeAttach}`);
  };

  const handleClearFile = input => {
    fileInputRef.current.value = '';
    fileNameRef.current.textContent = '';
    attachmentWrapRef.current.classList.remove(`${css.activeAttach}`);
    setFile(false);
    input.onChange(null);
  };

  return (
    <>
      {fileSizeError && <div className={css.fileErMsg}>{fileUplodErr}</div>}
      <div className={css.quoteWrap}>
        {isProvider && (
          <>
            <p>{quoteHeading}</p>
            <LiaEdit size={30} />
          </>
        )}
      </div>
      <div className={css.attactmentBtnWrap} ref={attachmentWrapRef}>
        <Field name={name}>
          {({ input }) => (
            <>
              <p
                className={
                  file ? `${css.fileNameCloseWrap} ${css.fileNameActive}` : css.fileNameCloseWrap
                }
              >
                {' '}
                <span ref={fileNameRef}></span>
                <button
                  type="button"
                  onClick={() => handleClearFile(input)}
                  className={css.uploadClearBtn}
                >
                  <TiDeleteOutline size={24} />
                </button>
              </p>

              <div className={css.attachmentWrap}>
                <label htmlFor={name} className={labelClassName}>
                  <p>{label}</p>
                  <img src="/static/icons/attachment_icon.svg" alt={label} title={label}></img>
                </label>
                <input
                  ref={fileInputRef}
                  type="file"
                  id={name}
                  onChange={event => handleFileChange(event, input)}
                  className={className}
                  accept="image/*, application/pdf, .xlsx, .xls, .csv, .doc, .docx, .txt"
                />
              </div>
            </>
          )}
        </Field>
      </div>
    </>
  );
};

export default CustomFileUploadField;
