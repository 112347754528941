import React, { Component } from 'react';
import { array, bool, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney, removingDecimalFromAmount } from '../../util/currency';
import { ensureListing, ensureOwnListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ListingCard.module.css';
import SectionLikes from '../../containers/ListingPage/SectionLikes';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateLikes } from '../../containers/ListingPage/ListingPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { priceDisplay } from '../../util/dynamicPricingHelpers';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const { Money } = sdkTypes;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const dailyPriceData = (dailyPriceAsMoney, intl) => {
  if (dailyPriceAsMoney && dailyPriceAsMoney.currency == config.currency) {
    const formattedDailyPrice = formatMoney(intl, dailyPriceAsMoney);
    return { formattedDailyPrice, dailyPrice: formattedDailyPrice };
  } else if (dailyPriceAsMoney) {
    return {
      formattedDailyPrice: `(${dailyPriceAsMoney.currency})`,
      dailyPrice: `Unsupported currency (${dailyPriceAsMoney.currency})`,
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    getListing,
    getOwnListing,
    params: rawParams,
    setActiveListing,
    onUpdateLikes,
    updateLikesInProgress,
    currentUser,
    rest,
    wishListClass = '',
  } = props;

  const hasImages = listing.images && listing.images.length > 0;

  const { displayName } = listing?.author?.attributes?.profile;
  const fullName = displayName ? `Hosted by ${displayName}` : '';

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;
  const listingId = new UUID(currentListing.id.uuid);
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  // const firstImage =
  //   currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const bannerImages =
    listing &&
    listing.attributes &&
    listing.attributes.publicData &&
    listing.attributes.publicData.bannerImagesData &&
    listing.attributes.publicData.bannerImagesData;

  const fisrtImageFlag = listing.images.filter((val, i) => {
    if (bannerImages && bannerImages.length > 0) {
      return val.id.uuid === bannerImages[0];
    }
  });

  const firstImage = hasImages
    ? fisrtImageFlag.length > 0
      ? fisrtImageFlag[0]
      : listing.images[0]
    : null;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const checkURL = typeof window !== 'undefined' ? window.location.href : null;

  const dailyPriceReceived = publicData && publicData.dailyPrice ? publicData.dailyPrice : null;
  const isDynamicPricingFlag =
    publicData && publicData.isDynamicPricing ? publicData.isDynamicPricing : false;
  const dynamicPriceList =
    publicData && publicData.dynamicPriceList ? publicData.dynamicPriceList : null;
  const dailyPriceAsMoney = dailyPriceReceived
    ? new Money(dailyPriceReceived.amount, dailyPriceReceived.currency)
    : null;

  const { formattedDailyPrice, dailyPrice } = dailyPriceData(dailyPriceAsMoney, intl);

  const sendLayerData = function(currentListing, e) {
    const { attributes, id, author } = currentListing;
    const { attributes: userAttributes } = author;
    const { publicData } = attributes;
    const { formattedDailyPrice, dailyPrice } = dailyPriceData(attributes.price, intl);
  };

  return (
    <div
      className={css.listingCardDetails}
      onClick={e => {
        sendLayerData(currentListing, e);
      }}
    >
      <div className={css.listingCardInfo}>
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <div
            className={css.threeToTwoWrapper}
            onMouseEnter={() => setActiveListing(currentListing.id)}
            onMouseLeave={() => setActiveListing(null)}
          >
            <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
          </div>
          <div className={css.info}>
            <div className={css.price}>
              {checkURL && checkURL.includes('dailyprices') ? (
                <div className={css.priceValue} title={priceTitle}>
                  <span className={css.startKey}>From &nbsp;&nbsp;</span>
                  {priceDisplay("daily", price, dailyPriceAsMoney, publicData, intl)}
                </div>
              ) : (
                <div className={css.priceValue} title={priceTitle}>
                   <span className={css.startKey}>From &nbsp;&nbsp;</span>
                  {priceDisplay("hourly", price, dailyPriceAsMoney, publicData, intl)}
                </div>
              )}
              {/* <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div> */}
              {/* {checkURL && checkURL.includes('dailyprices') ? (
                <div className={css.perUnit}>
                  <FormattedMessage id="ListingCard.perDay" />
                </div>
              ) : (
                <div className={css.perUnit}>
                  <FormattedMessage id={unitTranslationKey} />
                </div>
              )} */}
            </div>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.perUnit}>
                <span className={css.userNameTitle}>{fullName}</span>
              </div>

              <div className={css.certificateInfo}>
                {certificate && !certificate.hideFromListingInfo ? (
                  <span>{certificate.label}</span>
                ) : null}
              </div>
            </div>
          </div>
        </NamedLink>
      </div>

      {currentUser ? (
        <div className={wishListClass ? `${wishListClass} ${css.favOnPlp}` : `${css.favOnPlp}`}>
          <SectionLikes
            {...rest}
            // publicData={publicData}
            onUpdateLikes={onUpdateLikes}
            updateLikesInProgress={updateLikesInProgress}
            listingId={listingId.uuid}
            currentUser={currentUser}
            currentListing={currentListing}
            intl={intl}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    getListing,
    getOwnListing,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateLikes: (listingId, currentListing, priceTitle) =>
    dispatch(updateLikes(listingId, currentListing, priceTitle)),
});

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  updateLikesInProgress: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  currentUser: propTypes.currentUser,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  updateLikesInProgress: bool.isRequired,
};

const ListingCardPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingCardComponent);

export default ListingCardPage;
